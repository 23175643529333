/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum FeatureFlagType {
  ACTIVITIES_CATEGORY_IMAGES_SUPPORT = "ACTIVITIES_CATEGORY_IMAGES_SUPPORT",
  ACTIVITY_CHAT = "ACTIVITY_CHAT",
  BULK_MAP_UPLOADS = "BULK_MAP_UPLOADS",
  CUSTOM_VISIT_TYPES = "CUSTOM_VISIT_TYPES",
  DASHBOARD_APPS_CREATE_ORGANIZATION = "DASHBOARD_APPS_CREATE_ORGANIZATION",
  DASHBOARD_APPS_ONBOARDING = "DASHBOARD_APPS_ONBOARDING",
  DASHBOARD_APPS_SCHEDULE_TAB = "DASHBOARD_APPS_SCHEDULE_TAB",
  DESK_ISSUE_REPORTING = "DESK_ISSUE_REPORTING",
  DOCUMENT_AGREEMENTS = "DOCUMENT_AGREEMENTS",
  EDIT_DESK_RESERVATIONS = "EDIT_DESK_RESERVATIONS",
  EMPLOYEE_VISITS = "EMPLOYEE_VISITS",
  EXTERNAL_OCCASION_GENIUS_EVENT_SYNC = "EXTERNAL_OCCASION_GENIUS_EVENT_SYNC",
  HYBRID_INDEX = "HYBRID_INDEX",
  INSIGHTS_UI_REDESIGN = "INSIGHTS_UI_REDESIGN",
  ISSUE_REPORTING_V2 = "ISSUE_REPORTING_V2",
  LANGUAGE_SELECTION_ENABLED = "LANGUAGE_SELECTION_ENABLED",
  MOBILE_HOME_TAB_WEEK_VIEW = "MOBILE_HOME_TAB_WEEK_VIEW",
  OFFICE_ACTIVITIES = "OFFICE_ACTIVITIES",
  OFFICE_ACTIVITIES_MOBILE = "OFFICE_ACTIVITIES_MOBILE",
  ONBOARDING_MAP_UPLOAD = "ONBOARDING_MAP_UPLOAD",
  PASSIVE_CHECK_IN = "PASSIVE_CHECK_IN",
  PEOPLE_INSIGHTS = "PEOPLE_INSIGHTS",
  PEOPLE_TAB_DASHBOARD_APPS = "PEOPLE_TAB_DASHBOARD_APPS",
  REAL_ESTATE_OPTIMIZATION_INSIGHTS = "REAL_ESTATE_OPTIMIZATION_INSIGHTS",
  SCHEDULE_DESK_BOOKING_ENABLED = "SCHEDULE_DESK_BOOKING_ENABLED",
  SHARPTOOTH_DEVELOPMENT = "SHARPTOOTH_DEVELOPMENT",
  VISITOR_CSV_EXPORT = "VISITOR_CSV_EXPORT",
  VISITOR_EXPORT_DATE_RANGE = "VISITOR_EXPORT_DATE_RANGE",
  WEEKVIEW_WHOSIN_SIDEBAR_ENABLED = "WEEKVIEW_WHOSIN_SIDEBAR_ENABLED",
  WORK_AREAS = "WORK_AREAS",
  WORK_AREAS_MOBILE = "WORK_AREAS_MOBILE",
  WORK_AREA_INSIGHTS = "WORK_AREA_INSIGHTS",
  WORK_WEEK_HOMEPAGE = "WORK_WEEK_HOMEPAGE",
}

export enum MapUploadStatus {
  AWAITING_UPLOAD = "AWAITING_UPLOAD",
  UNKNOWN = "UNKNOWN",
  UPLOADED = "UPLOADED",
}

//==============================================================
// END Enums and Input Objects
//==============================================================
