import { FC } from 'react';
import styled from '@emotion/styled';
import { Colors } from '@robinpowered/design-system';
import { ChevronDown } from '../Icons';

const Icon = styled(ChevronDown)<{ open: boolean }>`
  transform: rotate(${(props) => (props.open ? '180deg' : '0deg')});
  margin-left: 8px;
  width: 8px;
  height: 8px;

  path {
    stroke: ${Colors.Gray80};
    stroke-width: 8px;
  }
`;

export const DropdownIcon: FC<{
  open: boolean;
}> = ({ open }) => <Icon open={open} />;
