import { gql, useQuery } from '@apollo/client';
import { useSelectedHierarchy } from './useSelectedHierarchy';
import {
  GetLocationTimezoneQuery,
  GetLocationTimezoneQueryVariables,
} from 'generated';

const LOCATION_TIMEZONE_QUERY = gql`
  query GetLocationTimezone($locationId: ID!) {
    getLocationById(id: $locationId) {
      id
      timezone
    }
  }
`;

export function useSelectedLocationTimezone() {
  const { selectedLocationId } = useSelectedHierarchy();
  return useQuery<GetLocationTimezoneQuery, GetLocationTimezoneQueryVariables>(
    LOCATION_TIMEZONE_QUERY,
    {
      skip: !selectedLocationId,
      variables: {
        locationId: selectedLocationId ?? '-1',
      },
      nextFetchPolicy: 'cache-only',
    }
  );
}
