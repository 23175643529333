import styled from '@emotion/styled';
import { Avatar, Body, Colors } from '@robinpowered/design-system';
import moment from 'moment';
import { FC, useMemo } from 'react';
import { removeMarkdownAnnotations } from 'utilities/Markdown';

type AnnouncementProp = {
  id: string;
  name: string;
  content: string;
  createdAt: string;
  viewedAt?: string | null | undefined;
  author: {
    id: string | null | undefined;
    name?: string | null | undefined;
    avatar?: string | null | undefined;
  };
};

export const AnnouncementListCard: FC<{
  announcement: AnnouncementProp;
  unreadBadgeText: string;
  onClick: () => void;
}> = ({ announcement, unreadBadgeText, onClick }) => {
  const { name, content, author, createdAt, viewedAt } = announcement;

  const creationDate = useMemo(() => {
    return moment(createdAt).format('MMM D');
  }, [createdAt]);

  return (
    <Container onClick={onClick}>
      <Header>
        <Author>
          <Avatar
            name={author.name || ''}
            src={author.avatar || ''}
            size={28}
          />
          <AuthorName>{author.name}</AuthorName>
        </Author>
        <Body.Small>{creationDate}</Body.Small>
      </Header>

      <TitleSection>
        {!viewedAt && (
          <UnreadBadge>
            <Body.XSmall>{unreadBadgeText}</Body.XSmall>
          </UnreadBadge>
        )}
        <Title>{name}</Title>
      </TitleSection>

      <Content>
        <Body.Regular>{removeMarkdownAnnotations(content)}</Body.Regular>
      </Content>
    </Container>
  );
};

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 8px;
`;

const Author = styled.div`
  display: flex;
  align-items: center;
`;

const AuthorName = styled(Body.Small)`
  padding-left: 8px;
  font-weight: 500;
`;

const TitleSection = styled.div`
  display: flex;
`;

const Title = styled(Body.Regular)`
  display: block;
  font-weight: 500;
`;

const Content = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-top: 4px;
`;

const UnreadBadge = styled.div`
  display: inline-block;
  padding: 2px 4px;
  margin-right: 4px;
  border-radius: 4px;
  color: ${Colors.White0};
  background-color: ${Colors.Maroon100};

  > span {
    font-weight: 500;
  }
`;

const Container = styled.button`
  text-align: left;
  color: ${Colors.Gray80};
  display: block;
  width: 100%;
  border: 1px solid ${Colors.Tan30};
  border-radius: 16px;

  box-shadow: #00000014 0px 1px 3px;
  padding: 16px;
  background-color: ${Colors.White0};
  cursor: pointer;
  margin: 16px 0;

  &:first-of-type {
    margin-top: 0;
  }
`;
