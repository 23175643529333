import React from 'react';
import { QuoteBubblePopup } from '../components';
import { FC, ReactNode } from 'react';

/**
 * Container for seat popup
 */

export const SeatPopupContainer: FC<{
  latitude: number;
  longitude: number;
  children: ReactNode;
}> = ({ children, latitude, longitude }) => {
  return (
    <QuoteBubblePopup
      closeOnClick={false}
      latitude={latitude}
      longitude={longitude}
      anchor="bottom-left"
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      offset={[-15, -10] as any}
    >
      {children}
    </QuoteBubblePopup>
  );
};
