import styled from '@emotion/styled';
import { Colors } from '@robinpowered/design-system';
import { Checkmark, MenuItem } from '../../components';
import { Organization } from '../../contexts/HeaderContext';

const Avatar = styled.img`
  display: inline-block;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  margin-right: 10px;
`;

const OrgMenuItem = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-items: center;

  div {
    text-align: start;
  }

  img {
    vertical-align: middle;
  }

  path {
    stroke-width: 4px;
    stroke: ${Colors.Maroon100};
  }

  :hover path {
    stroke-width: 4px;
    stroke: ${Colors.White0};
  }
`;

const OrgName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 182px;
  display: inline-block;
  vertical-align: middle;
`;

export function buildUserOrgMenu(
  orgs: Organization[],
  path: string,
  activeOrg?: null | Organization
): MenuItem[] {
  return orgs.map((org) => ({
    label: org.name ?? '',
    href: `/${org.slug}${path}`,
    renderElement: (
      <OrgMenuItem>
        <div>
          {org.avatar && <Avatar src={org.avatar} alt="" />}
          <OrgName>{org.name}</OrgName>
        </div>
        {activeOrg?.id === org.id && <Checkmark width={11} height={11} />}
      </OrgMenuItem>
    ),
  }));
}
