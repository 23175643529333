import React, { FC, useEffect, useMemo } from 'react';
import styled from '@emotion/styled';
import { keyframes, Global, css } from '@emotion/react';
import {
  Colors,
  Button,
  Row,
  Heading02,
  Heading05,
  Body06,
  UtilityButton,
} from '@robinpowered/design-system';
import { Close } from '@robinpowered/icons';
import { AddSpaceCard } from './MarketingCards/AddSpaceCard';
import { useTranslation } from 'react-i18next';
import { AmplitudeEvents } from '../../constants/amplitudeEvents';
import { useAmplitude } from '../../contexts';

/* eslint-disable @typescript-eslint/no-explicit-any */

const IOS = 'https://apps.apple.com/us/app/robin-powered/id948856282';
const ANDROID =
  'https://play.google.com/store/apps/details?id=com.robinpowered.compass';

type DownloadMobileAppModalProps = {
  setShowDownloadRobinAppModal: (tag: boolean) => void;
};

export const DownloadMobileAppModal: FC<DownloadMobileAppModalProps> = ({
  setShowDownloadRobinAppModal,
}) => {
  const { t } = useTranslation('downloadMobileAppModal');

  const { trackEvent } = useAmplitude();

  useEffect(() => {
    trackEvent(AmplitudeEvents.SHOW_MOBILE_WEB_TAKEOVER);
  }, [trackEvent]);

  const mobileAppLink = useMemo(() => {
    if (/Android/i.test(navigator.userAgent)) {
      return ANDROID;
    }

    if (/(iPhone|iPod)/i.test(navigator.userAgent)) {
      return IOS;
    }

    return 'https://robin.app/go/mobile';
  }, []);

  const handleDownloadAppAction = (): void => {
    window.location.href = mobileAppLink;
    trackEvent(AmplitudeEvents.SELECT_DOWNLOAD_MOBILE_WEB_TAKEOVER);
    setShowDownloadRobinAppModal(false);
  };

  const handleDismissModal = (): void => {
    setShowDownloadRobinAppModal(false);
    trackEvent(AmplitudeEvents.CLOSE_MOBILE_WEB_TAKEOVER);
  };

  return (
    <>
      <Global styles={globalStyle} />
      <Overlay>
        <ModalScreen>
          <ModalHeader>
            <UtilityButton
              onClick={handleDismissModal}
              style={{ marginLeft: 'auto', marginRight: 0 }}
              icon={Close as any}
            ></UtilityButton>
          </ModalHeader>
          <ContentContainer>
            <CardsContainer>
              <AddSpaceCard />
            </CardsContainer>
            <Heading02>{t('title')}</Heading02>
            <ListContainer>
              <List>
                <ListItem>
                  <Body06>{t('list_bullet_1')}</Body06>
                </ListItem>
                <ListItem>
                  <Body06>{t('list_bullet_2')}</Body06>
                </ListItem>
                <ListItem>
                  <Body06>{t('list_bullet_3')}</Body06>
                </ListItem>
              </List>
            </ListContainer>
          </ContentContainer>
          <ModalFooter>
            <Heading05>{t('button_heading')}</Heading05>
            <Button
              variant="primary"
              onClick={handleDownloadAppAction}
              style={{ height: 44 }}
            >
              {t('button_label')}
            </Button>
          </ModalFooter>
        </ModalScreen>
      </Overlay>
    </>
  );
};

/**
 * Is removed when the component unmounts
 */
const globalStyle = css`
  html,
  body {
    overflow: hidden;
  }
`;

const fadeIn = keyframes`
    from {
       opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

const Overlay = styled.div`
  animation: ${fadeIn} 0.3s ease-out;
  background-color: rgba(0, 0, 0, 0.4);
  bottom: 0;
  display: block;
  height: auto;
  left: 0;
  min-height: 100%;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  width: 100vw;
  z-index: 1050;
`;

const slideUp = keyframes`
    0% {
        transform: translateY(100%);
        visibility: visible;
    }
    100% {
        transform: translateY(0%);
        visibility: visible;
    }
`;

const ModalScreen = styled.div`
  visibility: hidden;
  background-color: ${Colors.Tan10};
  width: 100vw;
  height: 100vh;
  position: fixed;
  animation: ${slideUp} 0.2s ease-out forwards;
  animation-delay: 0.5s;
  text-align: center;
`;

const ModalHeader = styled(Row)`
  flex-grow: 0;
  padding: 16px;
`;

const ContentContainer = styled.div`
  padding: 0px 16px;
`;

const ModalFooter = styled(Row)`
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  padding: 16px;
`;

const ListContainer = styled.div`
  padding: 16px 16px 0px;
  margin-left: 8px;
`;

const List = styled.ul`
  list-style: disc;
  color: ${Colors.Gray100};
`;

const ListItem = styled.li``;

const CardsContainer = styled.div`
  margin: 10px auto 22px;
  max-width: fit-content;
  padding: 24px;
`;
