import { FC, Fragment, useMemo } from 'react';
import styled from '@emotion/styled';
import { FONT_SIZES } from 'shared/theme/fontSizes';
import { Colors } from '@robinpowered/design-system';
import { useHeaderContext } from '../../contexts';
import { useActiveOrganization, useFeatureFlag } from '../../hooks';
import { useTranslation } from 'react-i18next';
import {
  FeatureFlagType,
  MapUploadStatus,
} from '../../__generated__/globalTypes';

// Match /org-slug/setup at the start of the pathname
// Allow for the optional trailing slash or no trailing slash.
const MATCH_SETUP_TAB = /^\/[^/]*?\/setu(p$|p\/)/;

const HeaderMenuWrapper = styled.div`
  align-items: center;
  display: flex;
  margin-right: auto;
  margin-left: 10px;
`;

type PropsType = { isActive?: boolean };

const MenuLink = styled.a<PropsType>`
  align-items: center;
  color: ${(props) => (props.isActive ? Colors.Maroon100 : Colors.Gray80)};
  display: flex;
  font-size: ${FONT_SIZES.SMALL};
  font-weight: 500;
  justify-content: center;
  border: 2px solid transparent;
  border-radius: 12px;
  padding: 6px 8px;
  margin: 0;
  text-decoration: none;
  white-space: pre;

  &:hover {
    color: ${Colors.Maroon100};
  }

  &:focus-visible {
    border-color: ${Colors.Maroon100};
  }
`;

export const HeaderMenu: FC = () => {
  const { t } = useTranslation('headerMenu');

  const { activeOrg, userPermissions, isLoading, isWorkWeekHomepageEnabled } =
    useHeaderContext();
  // For performance reasons, it's quicker to get a single org than all orgs from getCurrentUserAndOrgs
  const { data: currentOrgData } = useActiveOrganization();
  const orgLocations = currentOrgData?.getOrganizationById?.locations;
  const orgSlug = activeOrg?.slug;
  // For the "Finish setup" tab, find the first associated floor
  const firstLocation = orgLocations?.[0];
  const firstLevel = firstLocation?.levels[0];

  const { data: peopleTabFlag, loading: isFeatureFlagLoading } = useFeatureFlag(
    FeatureFlagType.PEOPLE_TAB_DASHBOARD_APPS
  );

  const {
    canListUsers,
    canManageAmenities,
    canViewAnalytics,
    canViewBilling,
    canViewCheckpoints,
    canViewDevices,
    canViewOfficeAccess,
    canManageGroups,
    canManagePlaces,
    canManageRoles,
    canManageUsers,
    canManageVisitors,
    canManageWorkplaceServices,
    canViewWorkweek,
    arePersonalCalendarsEnabled,
  } = userPermissions;

  // @TODO we should also be checking if the user has an admin role,
  // but since an admin is likely to have at least one of these
  // permissions, this will cover most cases, for now.
  const showManageTab =
    canManageAmenities ||
    canViewBilling ||
    canViewCheckpoints ||
    canViewDevices ||
    canViewOfficeAccess ||
    canManageGroups ||
    canManagePlaces ||
    canManageRoles ||
    canManageUsers ||
    canManageWorkplaceServices;

  const showScheduleTab = arePersonalCalendarsEnabled;
  const showPeopleTab = orgLocations && orgLocations.length > 0 && canListUsers;
  const showInsightsTab =
    orgLocations && orgLocations.length > 0 && canViewAnalytics;
  const showVisitsTab = canManageVisitors;

  const settingsPageDefault = canManagePlaces
    ? 'offices'
    : canViewOfficeAccess
    ? 'access'
    : canViewCheckpoints
    ? 'integrations'
    : canViewBilling
    ? 'billing'
    : canViewDevices
    ? 'devices'
    : canManageUsers
    ? 'users'
    : canManageGroups
    ? 'groups'
    : canManageRoles
    ? 'roles'
    : canManageWorkplaceServices
    ? 'services'
    : canManageAmenities
    ? 'amenities'
    : 'customization';

  const isMapUploadOnboardingComplete = useMemo(() => {
    const currentOrg = currentOrgData?.getOrganizationById;
    if (!currentOrg) {
      return true; // Default value while loading: don't show onboarding.
    }

    // Iterate through every level to see if at least one has an uploaded map.
    const hasUploadedMaps = currentOrg.locations.some((location) => {
      return location.levels.some(
        (level) => level.mapUploadStatus === MapUploadStatus.UPLOADED
      );
    });
    return hasUploadedMaps;
  }, [currentOrgData?.getOrganizationById]);

  const isFinishSetupFocused = MATCH_SETUP_TAB.test(window.location.pathname);
  const finishSetupLink = firstLevel
    ? `${process.env.REACT_APP_DASHBOARD_URL}/${orgSlug}/setup/upload-map?locationId=${firstLocation?.id}&levelId=${firstLevel?.id}`
    : `${process.env.REACT_APP_DASHBOARD_URL}/${orgSlug}/setup/add-floor?locationId=${firstLocation?.id}`;
  const showFinishSetupTab =
    isFinishSetupFocused || (!isMapUploadOnboardingComplete && canManagePlaces);

  return (
    <HeaderMenuWrapper>
      {(!isLoading || !isFeatureFlagLoading) && (
        <Fragment>
          {showFinishSetupTab && (
            <>
              <MenuLink
                href={
                  isFinishSetupFocused ? window.location.href : finishSetupLink
                }
                isActive={isFinishSetupFocused}
              >
                {t('setup')}
              </MenuLink>
              <RedDot />
            </>
          )}
          {isWorkWeekHomepageEnabled ? (
            <>
              {showScheduleTab && (
                <MenuLink
                  href={`${
                    process.env.REACT_APP_DASHBOARD_URL
                  }/${orgSlug}/schedule/${canViewWorkweek ? 'week' : 'user'}`}
                  isActive={window.location.pathname.includes('/schedule')}
                >
                  {t('schedule')}
                </MenuLink>
              )}
              <MenuLink href={`/${orgSlug}/office`}>{t('office')}</MenuLink>
            </>
          ) : (
            <>
              <MenuLink href={`/${orgSlug}/office`}>{t('office')}</MenuLink>
              {showScheduleTab && (
                <MenuLink
                  href={`${process.env.REACT_APP_DASHBOARD_URL}/${orgSlug}/schedule/week`}
                  isActive={window.location.pathname.includes('/schedule')}
                >
                  {t('schedule')}
                </MenuLink>
              )}
            </>
          )}

          {showPeopleTab &&
            (peopleTabFlag?.isFeatureFlagEnabledForOrgOrMe ? (
              <>
                <MenuLink
                  href={`${process.env.REACT_APP_DASHBOARD_URL}/${orgSlug}/people/`}
                  isActive={window.location.pathname.includes('/people')}
                >
                  {t('people')}
                </MenuLink>
              </>
            ) : (
              <MenuLink href={`/${orgSlug}/users`}>{t('people')}</MenuLink>
            ))}
          {showInsightsTab && (
            <MenuLink
              href={`${process.env.REACT_APP_DASHBOARD_URL}/${orgSlug}/insights`}
              isActive={window.location.pathname.includes('/insights')}
            >
              {t('analytics')}
            </MenuLink>
          )}
          {showVisitsTab && (
            <MenuLink
              href={`${process.env.REACT_APP_DASHBOARD_URL}/${orgSlug}/visits/`}
              isActive={window.location.pathname.includes('/visits')}
            >
              {t('visits')}
            </MenuLink>
          )}
          {showManageTab && (
            <>
              <MenuLink
                href={`${process.env.REACT_APP_DASHBOARD_URL}/${orgSlug}/settings/${settingsPageDefault}`}
              >
                {t('manage')}
              </MenuLink>
            </>
          )}
        </Fragment>
      )}
    </HeaderMenuWrapper>
  );
};

const RedDot = styled.div`
  margin: -8px 10px 0 -7px;
  height: 7px;
  width: 7px;
  border-radius: 50%;
  background: ${Colors.RedProduct};
  flex-shrink: 0; /* Don't allow it to get squished */
`;
