import styled from '@emotion/styled';
import { Card } from '../Card';

export const PeoplePillContent = styled(Card)`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 12px;
  border-radius: 32px;
`;
