import { FC } from 'react';
import styled from '@emotion/styled';

const ChevronRight: FC = () => (
  <svg
    className="ChevronRight"
    viewBox="0 0 7 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.8125 7.875L5.1875 4.5L1.8125 1.125"
      stroke="#4E6275"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
  </svg>
);

const StyledButton = styled.button`
  background: none;
  line-height: 16px;
  padding: 12px 10px 12px 10px;
  position: absolute;
  text-align: center;
  top: 0px;
  left: 45px;
  cursor: pointer;
  margin: 0px;
  box-sizing: border-box;
  vertical-align: middle;
  border: none;
  .ChevronRight {
    display: inline-flex;
    align-self: center;
    position: relative;
    width: 7px;
    height: 9px;
  }
`;

export const CalendarNavRight: FC = () => {
  return (
    <StyledButton>
      <ChevronRight />
    </StyledButton>
  );
};
