import { createContext } from 'react';

export const MapIdContext = createContext<string>('');

type ContextualMapInfoType = {
  tenantId: string | null;
  levelId: string | null;
  orgSlug: string | null;
  userId: string | null;
  authToken: string | null;
};
export const MapInfoContext = createContext<ContextualMapInfoType>({
  tenantId: null,
  levelId: null,
  orgSlug: null,
  userId: null,
  authToken: null,
});
