import styled from '@emotion/styled';
import { Skeleton } from '@robinpowered/design-system';

export const SpaceDetailsLoading = () => {
  return (
    <>
      <SkeletonImage height={200} />
      <Skeleton height={24} width={'70%'} />
    </>
  );
};

const SkeletonImage = styled(Skeleton)`
  margin-bottom: 16px;
`;
