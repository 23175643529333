import {
  GuidelineDefault,
  GuidelineSuccess,
  InnerCircle,
  MainSpark,
  OuterCircle,
  SecondarySpark,
} from './AnimatedGuidelineIconStyles';
import React from 'react';

interface Props {
  size?: number;
  variant?: 'default' | 'success';
  shouldShowAnimation?: boolean;
}

export const AnimatedGuidelineIcon = ({
  size = 20,
  variant = 'default',
  shouldShowAnimation,
}: Props): JSX.Element => {
  const SVG_PATH_1 =
    // eslint-disable-next-line max-len
    'M31.6667 1.66667H8.33333C6.5 1.66667 5.01667 3.16667 5.01667 5L5 26.55C5 27.7 5.58333 28.7167 6.46667 29.3167L20 38.3333L33.5167 29.3167C34.4 28.7167 34.9833 27.7 34.9833 26.55L35 5C35 3.16667 33.5 1.66667 31.6667 1.66667ZM20 34.3333L8.33333 26.5667V5H31.6667V26.55L20 34.3333Z';
  const SVG_PATH_2 =
    // eslint-disable-next-line max-len
    'M20 9.35796L22.0769 15.9199L28.6388 17.9968L22.0769 20.0737L20 26.6356L17.9231 20.0737L11.3611 17.9968L17.9231 15.9199L20 9.35796Z';
  const SUCCESS_SVG_PATH =
    // eslint-disable-next-line max-len
    'M20 38.3333L7.62499 29.0417C7.29166 28.7917 7.0486 28.4722 6.89582 28.0833C6.74305 27.6944 6.66666 27.2917 6.66666 26.875V5.83333C6.66666 5.16666 6.91666 4.58333 7.41666 4.08333C7.91666 3.58333 8.49999 3.33333 9.16666 3.33333H30.8333C31.5 3.33333 32.0833 3.58333 32.5833 4.08333C33.0833 4.58333 33.3333 5.16666 33.3333 5.83333V26.875C33.3333 27.2917 33.2569 27.6944 33.1042 28.0833C32.9514 28.4722 32.7083 28.7917 32.375 29.0417L20 38.3333ZM18.25 25L27.6667 15.5833L25.875 13.8333L18.2083 21.5L14.0833 17.375L12.3333 19.125L18.25 25Z';

  // Default icon should be visible while the animation runs
  const shouldShowDefaultIcon =
    variant === 'default' || (variant === 'success' && shouldShowAnimation);

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {shouldShowDefaultIcon && (
        <>
          <GuidelineDefault
            shouldShowAnimation={shouldShowAnimation}
            d={SVG_PATH_1}
          />
          <GuidelineDefault
            shouldShowAnimation={shouldShowAnimation}
            d={SVG_PATH_2}
          />
        </>
      )}

      {shouldShowAnimation && (
        <>
          <OuterCircle cx="50%" cy="50%" r="20" />
          <InnerCircle cx="50%" cy="50%" r="5" />

          <MainSpark x="18" y="0" rx="2" position="top" />
          <MainSpark x="18" y="36" rx="2" position="bottom" />
          <MainSpark x="0" y="18" rx="2" position="left" />
          <MainSpark x="36" y="18" rx="2" position="right" />

          <SecondarySpark cx="6" cy="8" r="2" position="top-left" />
          <SecondarySpark cx="34" cy="8" r="2" position="top-right" />
          <SecondarySpark cx="6" cy="32" r="2" position="bottom-left" />
          <SecondarySpark cx="34" cy="32" r="2" position="bottom-right" />
        </>
      )}

      {variant === 'success' && (
        <GuidelineSuccess
          shouldShowAnimation={shouldShowAnimation}
          d={SUCCESS_SVG_PATH}
        />
      )}
    </svg>
  );
};
