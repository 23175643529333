import { CSSProperties, FC, ReactNode, useRef } from 'react';
import React from 'react';
import maplibregl from 'maplibre-gl';
import { Map, MapRef } from 'react-map-gl';
import { useImageMissingHandler } from './useImageMissingHandler';
import { useRecoilState } from 'recoil';
import {
  MapIdContext,
  MapInfoContext,
  useMapLoadCompleteAtom,
  useMapMetaDataAtom,
} from './state';

type MapProperties = {
  /* Identity of the map presented */
  id: string;
  levelId: string | null | undefined;
  style?: CSSProperties;
  defaultCursor?: string;
  children?: ReactNode;
  tenantId: string | null;
  authorization: string;
  orgSlug: string | null;
  userId: string | null;
};

/**
 * Root map component. It shows you a map.
 *
 * This implementation of map requires that you mount the Recoil state provider
 * above this component.
 * It's recommended to mount recoil near the top of the react component tree.
 *
 * More on recoil state here: https://recoiljs.org/
 *
 * @param id Map ID. Most useful when displaying multiple maps on the screen.
 * @param levelId Robin location level ID
 * @param style Optional CSS styling selections for the map
 * @param defaultCursor Optional default cursor style for map
 * @param tenantId Organization ID for the current context
 * @param authorization String representing the content of the authorization header forwarded to the map service.
 * @returns react component
 */
export const MapComponent: FC<MapProperties> = ({
  id,
  levelId,
  children,
  style,
  defaultCursor,
  tenantId,
  authorization,
  orgSlug,
  userId,
}) => {
  const composedId = `${id}-${levelId}`;
  const mapRef = useRef<MapRef>(null);
  const [metadata, setMetadata] = useRecoilState(
    useMapMetaDataAtom(composedId)
  );
  const [mapLoaded, setMapLoadedState] = useRecoilState(
    useMapLoadCompleteAtom(composedId)
  );

  useImageMissingHandler(composedId);

  return (
    <MapIdContext.Provider value={composedId}>
      <MapInfoContext.Provider
        value={{
          tenantId,
          levelId: levelId ?? null,
          orgSlug,
          userId,
          authToken: authorization,
        }}
      >
        <Map
          id={composedId}
          key={levelId}
          ref={mapRef}
          cursor={defaultCursor}
          mapLib={maplibregl as unknown}
          onLoad={() => {
            const style = mapRef.current?.getStyle();
            setMetadata(style?.metadata);
            setMapLoadedState(true);
          }}
          onRemove={() => {
            setMapLoadedState(false);
            setMetadata({});
          }}
          mapStyle={`${process.env.REACT_APP_ATLAS_ROOT_URL}/v1.1/styles/levels/${levelId}/light/style.json`}
          transformRequest={(url: string) => {
            return {
              url,
              headers: {
                // Add Auth headers.
                Authorization: authorization,
              },
            };
          }}
          style={
            style ?? {
              width: '100%',
              height: '100%',
            }
          }
          maxZoom={
            !!metadata && !!metadata['robin:max-zoom']
              ? metadata['robin:max-zoom']
              : undefined
          }
          minZoom={
            !!metadata && !!metadata['robin:min-zoom']
              ? metadata['robin:min-zoom']
              : undefined
          }
          maxBounds={
            !!metadata && !!metadata['robin:max-bounds']
              ? metadata['robin:max-bounds']
              : undefined
          }
        >
          {mapLoaded ? children : null}
        </Map>
      </MapInfoContext.Provider>
    </MapIdContext.Provider>
  );
};
