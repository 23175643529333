import styled from '@emotion/styled';
import { FC } from 'react';

const ArrowLeftImg: FC = () => (
  <svg
    width="7"
    height="9"
    viewBox="0 0 7 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.1875 1.125L1.8125 4.5L5.1875 7.875"
      stroke="#4E6275"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const ArrowLeftIconWrapper = styled.span`
  svg {
    width: 7px;
    height: 9px;
  }
`;

export const ArrowLeftIcon: FC = () => (
  <ArrowLeftIconWrapper>
    <ArrowLeftImg />
  </ArrowLeftIconWrapper>
);
