import styled from '@emotion/styled';
import { Colors, Heading } from '@robinpowered/design-system';
import { FC } from 'react';
import TeamSolid from '@robinpowered/icons/TeamSolid';
import YouAreHereSolid from '@robinpowered/icons/YouAreHereSolid';
import Accessible from '@robinpowered/icons/HandicapAccessibleSolid';

export const SpaceDetailsBasic: FC<{
  location: string | null;
  level: string | null;
  capacity: number;
  type?: string | null;
  accessibleText?: string | null;
}> = ({ location, level, capacity, type, accessibleText }) => {
  return (
    <>
      <Container>
        <YouAreHereSolid size={15} color={Colors.Gray60} />
        <Info>
          {location}, {level}
        </Info>
      </Container>

      {(capacity || type) && (
        <Container>
          <TeamSolid size={15} color={Colors.Gray60} />
          {capacity && <Info>{capacity}</Info>}
          {type && <Info showDot={!!capacity}>{type}</Info>}
        </Container>
      )}

      {accessibleText && (
        <Container>
          <Accessible size={15} color={Colors.Gray60} />
          <Info>{accessibleText}</Info>
        </Container>
      )}
    </>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 4px;
`;

const Info = styled(Heading.Small)<{ showDot?: boolean }>`
  &:before {
    ${({ showDot }) => (showDot ? "content: ' · '" : '')};
  }
`;
