import React from 'react';
import { Colors } from '@robinpowered/design-system';
import styled from '@emotion/styled';
import { SerializedStyles, css } from '@emotion/react';

type GuidelineProgressBarProps = {
  guidelineFrequency: number;
  daysFulfilled?: number;
};

const CounterContainer = styled.div(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  gap: '4px',
  padding: '0 8px',
}));

type CounterBarProps = {
  daysFulfilled: number;
  currentBar: number;
  guidelineFrequency: number;
};

const CounterBar = styled.div<CounterBarProps>`
  height: 4px;
  width: 100%;
  border-radius: 4px;

  ${({ daysFulfilled, guidelineFrequency, currentBar }): SerializedStyles => {
    return css`
      background: ${Colors.Maroon20};
      background-size: 0 100%;
      background-repeat: no-repeat;

      ${daysFulfilled >= guidelineFrequency
        ? // If goal is achieved
          css`
            background-image: linear-gradient(
              ${Colors.GreenProduct},
              ${Colors.GreenProduct}
            );
            transition: background-size 0.3s linear;
            background-size: 100% 100%;
          `
        : // Regular filling of the progress bar
          css`
            background-image: linear-gradient(
              ${Colors.Magenta100},
              ${Colors.Magenta100}
            );

            // Backward transition
            transition: background-size 0.3s
              ${0.3 * (daysFulfilled - currentBar)}s linear;

            // Forward transition
            ${currentBar < daysFulfilled &&
            css`
              background-size: 100% 100%;
              transition: background-size 0.3s ${0.3 * currentBar}s linear;
            `};
          `};
    `;
  }}
`;

export const GuidelineProgressBar = ({
  guidelineFrequency,
  daysFulfilled = 0,
}: GuidelineProgressBarProps): JSX.Element => {
  const columns = [...Array(guidelineFrequency)];

  return (
    <CounterContainer>
      {columns.map((_col, index) => (
        <CounterBar
          daysFulfilled={daysFulfilled}
          currentBar={index}
          key={index}
          guidelineFrequency={guidelineFrequency}
        ></CounterBar>
      ))}
    </CounterContainer>
  );
};
