import styled from '@emotion/styled';
import { Card } from './shared/Card';
import { Body, TextInput } from '@robinpowered/design-system';
import { FC, useCallback, useMemo, useState } from 'react';
import {
  useLocationLevels,
  usePeopleWithReservations,
  useSelectedDateTime,
  useSelectedHierarchy,
} from 'hooks';
import moment from 'moment';
import SearchOutline from '@robinpowered/icons/SearchOutline';
import { FlyToResourceComponent } from 'map';
import {
  PeopleSearchContent,
  PeopleSearchHeader,
  PeopleSearchResult,
  PeopleSearchResultLoading,
  PeopleSearchResults,
} from './shared/PeopleSearchCard';
import { useTranslation } from 'react-i18next';

export const PeopleSearchCard: FC<{ onClose: () => void }> = ({ onClose }) => {
  const { t } = useTranslation('people');

  const { selectedLevelId, selectedLocationId } = useSelectedHierarchy();
  const { data: locationData } = useLocationLevels(selectedLocationId);
  const { selectedStartDateTime } = useSelectedDateTime();

  const [searchText, setSearchText] = useState<string>('');
  const [selectedSeatId, setSelectedSeatId] = useState<string | undefined>(
    undefined
  );

  const levelName = useMemo(() => {
    return locationData?.getLocationById?.levels.find(
      (l) => l.id === selectedLevelId
    )?.name;
  }, []);

  const { loading, peopleWithReservations } = usePeopleWithReservations({
    levelId: selectedLevelId,
    startTime: selectedStartDateTime,
    endTime: moment(selectedStartDateTime)
      .endOf('day')
      .toISOString()
      .replace(/\.\d+/, ''),
  });

  const filteredPeople = useMemo(() => {
    if (!searchText) {
      return peopleWithReservations;
    }

    return peopleWithReservations.filter((p) => {
      if (p.name) {
        return p.name
          .toLocaleLowerCase()
          .startsWith(searchText.toLocaleLowerCase());
      }
    });
  }, [searchText, peopleWithReservations]);

  const handleChange = useCallback(
    (value: string) => {
      setSearchText(value);
    },
    [setSearchText]
  );

  return (
    <FloatingCard>
      <PeopleSearchHeader title={t('people')} onClose={onClose} />

      <PeopleSearchContent>
        <TextInput
          icon={SearchOutline}
          size="small"
          placeholder={t('search_by_name') || undefined}
          name="people-search"
          value={searchText}
          onChange={handleChange}
        />
        <Body.Small>
          {levelName} {!loading && `(${peopleWithReservations.length})`}
        </Body.Small>

        <PeopleSearchResults>
          {loading ? (
            <>
              <PeopleSearchResultLoading />
              <PeopleSearchResultLoading />
            </>
          ) : (
            <>
              {peopleWithReservations.length === 0 && (
                <Body.Small>{t('no_one_on_floor')}</Body.Small>
              )}

              {peopleWithReservations.length > 0 &&
                filteredPeople.length === 0 && (
                  <Body.Small>{t('no_search_results')}</Body.Small>
                )}

              {filteredPeople.map(({ name, avatarSrc, seatId }, i) => (
                <PeopleSearchResult
                  key={i}
                  name={name}
                  src={avatarSrc || undefined}
                  onClick={() => {
                    setSelectedSeatId(seatId);
                  }}
                />
              ))}
            </>
          )}
        </PeopleSearchResults>
      </PeopleSearchContent>

      <FlyToResourceComponent
        resourceLayer="seats"
        resourceId={selectedSeatId}
        zoomTo={12}
        onFlyCompleteOrInterrupted={() => {
          setSelectedSeatId(undefined);
        }}
      />
    </FloatingCard>
  );
};

const FloatingCard = styled(Card)`
  position: absolute;
  bottom: 60px;
  left: 16px;
  display: flex;
  flex-direction: column;
  height: 75%;
  width: 250px;
  padding: 0;
`;
