import styled from '@emotion/styled';
import {
  Colors,
  Button,
  CoreFontSize,
  BrownFontStack,
} from '@robinpowered/design-system';

export const DropdownButton = styled(Button)<{
  buttonIcon?: boolean;
  iconOnly?: boolean;
  disabled?: boolean;
}>`
  align-items: center;
  border-radius: 4px;
  border: 2px solid ${({ disabled }) => (disabled ? Colors.Tan30 : Colors.Tan5)};
  color: ${Colors.Gray80};
  display: flex;
  font-family: ${BrownFontStack};
  font-size: ${CoreFontSize.s14};
  font-weight: 500;
  height: 36px;
  line-height: normal;
  min-width: 0;
  padding: 0 8px;
  background-color: ${Colors.Tan5};

  ${({ buttonIcon, iconOnly }) =>
    buttonIcon
      ? `
    .SVGInline {
      line-height: 0;
    }
    svg {
      margin-right: ${iconOnly ? 0 : '4px'}
    }
  `
      : ''}

  &:hover:not([disabled]) {
    background-color: ${Colors.Tan30} !important;
    border: 2px solid ${Colors.Tan30} !important;
  }

  &:focus-visible {
    border: 2px solid ${Colors.Maroon100};
  }
`;

// The sole purpose of this wrapper is to vertically center the text within
// the button due to the baseline of Larsseit being off.
export const DropdownButtonText = styled.span`
  line-height: 1.5;
  top: 0;
`;
