import PubSub from 'pubsub-js';
import { MapIdContext } from '../state';
import { FC, useContext, useEffect } from 'react';
import { useMap } from 'react-map-gl';
import { OnMapClickEvent } from './on-map-click-event-type';

type SeatMouseClickComponentProperties = {
  /* set of source identities to change the cursor state when clicked */
  mouseClickSourceIdentity?: Set<string>;
  /**
   *
   * @param e Map click event
   * @returns void
   */
  onClick?: ((e: OnMapClickEvent) => void) | undefined;
};

/**
 * Map mouse click handler. Click events are published to an event topic using pubsub-js.
 * @param onClick Optional click handler invoked when user clicks on the map
 * @param mouseClickSourceIdentity Optional source layer whitelist. If present, clicks on source layers not in list will be filtered out.
 * @returns React component
 */
export const MouseClickComponent: FC<SeatMouseClickComponentProperties> = ({
  mouseClickSourceIdentity,
  onClick,
}) => {
  const id = useContext(MapIdContext);
  // outer layer waits for map load event and gets all seat IDs from the map style spec
  const map = useMap()[id];

  useEffect(() => {
    if (!map) {
      return;
    }
    const innerMap = map.getMap();
    innerMap.on('click', (e) => {
      const features = innerMap.queryRenderedFeatures(e.point);
      const match = features.filter((s) => {
        return mouseClickSourceIdentity?.has(s.source) ?? true;
      });

      const clickData: OnMapClickEvent = {
        sourceTypes: Array.from(new Set(match.map((m) => m.source))),
        targetDetails: match.map((f) => {
          return {
            lat: e.lngLat.lat,
            lng: e.lngLat.lng,
            geometry:
              (f.geometry as unknown as { coordinates: [number, number][] })
                .coordinates ?? undefined,
            geometryType: f.geometry.type,
            type: f.source,
            mapId: f.id ?? '',
            targetId: f.properties?.ownerId ?? '',
          };
        }),
      };

      if (onClick) {
        onClick(clickData);
      }

      // because the map only exposes a single click handle,
      // we create a pub-sub dynamic to allow for multiple subscribers
      PubSub.publish(`${id}_onClick`, clickData);
    });
  }, [id, map, mouseClickSourceIdentity]);
  return null;
};
