import styled from '@emotion/styled';

export const PeopleSearchContent = styled.div`
  padding: 16px 16px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex-grow: 1;
  overflow: hidden;
`;
