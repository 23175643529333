import React from 'react';
import { MapIdContext, useMouseOverItemAtom } from '../state';
import { FC, ReactNode, useContext, useEffect } from 'react';
import { useMap } from 'react-map-gl';
import { useRecoilState } from 'recoil';

type SeatMouseOverComponentProperties = {
  /* set of source identities to change the cursor state when over */
  mouseOverSourceIdentity: Set<string>;
  children?: ReactNode;
};

/**
 * Map component that handles the centralized mousemove event within the map.
 * This component updates maintains a list of robin components like seats and spaces that are under the mouse cursor.
 * This component will unmount all children if there are no specified component types under the mouse cursor.
 *
 * @param mouseOverSourceIdentity Whitelist of source layers that will trigger 'mouse-over' state updates
 * @param children React components which will only mount when the mouse cursor is direclty over a matching component type
 * @returns react component
 */
export const MouseOverComponent: FC<SeatMouseOverComponentProperties> = ({
  mouseOverSourceIdentity,
  children,
}) => {
  const id = useContext(MapIdContext);
  // outer layer waits for map load event and gets all seat IDs from the map style spec
  const map = useMap()[id];
  const [pointerState, setPointerState] = useRecoilState(
    useMouseOverItemAtom(id)
  );

  useEffect(() => {
    if (!map) {
      return;
    }
    const innerMap = map.getMap();
    innerMap.on('mousemove', (e) => {
      const features = innerMap.queryRenderedFeatures(e.point);
      const match = features.filter((s) => {
        return mouseOverSourceIdentity.has(s.source);
      });

      setPointerState({
        sourceTypes: Array.from(new Set(match.map((m) => m.source))),
        targetDetails: match.map((f) => {
          return {
            type: f.source,
            mapId: f.id ?? '',
            ownerId: f.properties?.ownerId ?? '',
          };
        }),
      });
    });
  }, [map, mouseOverSourceIdentity]);

  return pointerState.sourceTypes.length > 0 && map && children ? (
    <>{children}</>
  ) : null;
};
