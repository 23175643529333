import { useSpaceStateQuery } from '../hooks';
import { FC, useEffect } from 'react';
import { useMap } from 'react-map-gl';
import { SpaceDataType } from '../types';
import { mapSpaceStateToMapType } from '../dataMaps';

type GroupingSpaceStateComponentProgressiveLoadProperties = {
  id: string;
  spaceData: SpaceDataType;
  availabilityTimestamp: string;
};

/**
 * Map component that queries GraphQL for a group of space state, and updates the map with the results.
 * @param id Identity of map
 * @param seatData Map of robin space IDs to map feature element IDs assoicated with that space
 * @param availabilityTimestamp ISO timestamp indicating time of availability
 * @returns React component
 */
export const GroupingSpaceStateComponentProgressiveLoad: FC<
  GroupingSpaceStateComponentProgressiveLoadProperties
> = ({ spaceData, id, availabilityTimestamp }) => {
  const map = useMap()[id];
  const spaceIds = Object.keys(spaceData);
  const spaceState = useSpaceStateQuery(spaceIds, availabilityTimestamp);

  useEffect(() => {
    if (
      !map ||
      !map.isStyleLoaded() ||
      spaceState.loading ||
      spaceState.error
    ) {
      return;
    }

    spaceState.data?.getSpacesByIds.forEach((sd) => {
      const newState = Object.assign({}, mapSpaceStateToMapType(sd));

      const spaceFeatureIds: Array<string | number> = spaceData[sd.id];

      spaceFeatureIds.forEach((sfid) => {
        const existingState =
          map.getFeatureState({
            source: 'spaces',
            id: sfid,
          }) ?? {};
        return map.setFeatureState(
          { source: 'spaces', id: sfid },
          Object.assign({}, { ...existingState }, newState)
        );
      });
    });
  }, [map, spaceState, spaceData]);

  return null;
};
