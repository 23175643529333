import { UseSeatDetailsQuery } from 'generated';
import moment from 'moment-timezone';

// This source file contains direct ports of dashboard angular business logic.
// This should be purged in favor of centralized assertions of state contained within backend systems.

type SeatType = UseSeatDetailsQuery['getDeskById'];
type SeatReservationType = Exclude<
  SeatType,
  null | undefined
>['state']['reservations'][0];

export const hasReservationStarted = (
  reservation: SeatReservationType,
  timeZone: string
) => {
  return moment()
    .tz(timeZone)
    .isSameOrAfter(moment.tz(reservation.startTime, timeZone));
};
