import moment from 'moment-timezone';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSelectedDate } from './useSelectedDate';
import { useLocationContext } from 'contexts/LocationContext';

type UseSelectedTimeResponseType = {
  selectedStartHours: number;
  selectedStartMinutes: number;
  selectedEndHours: number;
  selectedEndMinutes: number;
  setSelectedStartTime: (hours: number, minutes: number) => void;
  setSelectedEndTime: (hours: number, minutes: number) => void;
  selectedStartTimeString: string | null;
  selectedEndTimeString: string | null;
};

const calculateTimes = (
  timeString: string | null,
  timezone: string,
  pushDefaultEod: boolean
): { hr: number; mn: number } => {
  if (timeString) {
    const [hrString, minString] = timeString.split(':');
    try {
      const hrs = Number.parseInt(hrString);
      const mins = Number.parseInt(minString);
      return { hr: hrs, mn: mins };
    } catch (e) {
      // do nothing. bad inputs. fall back to defaults.
    }
  }

  if (pushDefaultEod) {
    return {
      hr: moment.tz(timezone).endOf('day').hours(),
      mn: moment.tz(timezone).endOf('day').minutes(),
    };
  }

  return {
    hr: moment.tz(timezone).hours(),
    mn: moment.tz(timezone).minutes(),
  };
};

export function useSelectedTime(): UseSelectedTimeResponseType {
  const [searchParams, setSearchParams] = useSearchParams();
  const { safeSelectedDate } = useSelectedDate();
  const { timezone } = useLocationContext();

  const selectedStartTimeString = searchParams.get('st');
  const selectedEndTimeString = searchParams.get('et');

  const {
    selectedStartHours,
    selectedStartMinutes,
    selectedEndHours,
    selectedEndMinutes,
  } = useMemo(() => {
    const start = calculateTimes(selectedStartTimeString, timezone, false);
    const end = calculateTimes(selectedEndTimeString, timezone, true);
    return {
      selectedStartHours: start.hr,
      selectedStartMinutes: start.mn,
      selectedEndHours: end.hr,
      selectedEndMinutes: end.mn,
    };
  }, [
    safeSelectedDate,
    selectedStartTimeString,
    selectedEndTimeString,
    timezone,
  ]);

  const setSelectedStartTime = useMemo(() => {
    return (hours: number, minutes: number) => {
      const time = `${hours}:${minutes}`;
      const defaultEndTime = `${selectedEndHours}:${selectedEndMinutes}`;
      const forcedEndMinutes = (minutes + 15) % 60;
      const forcedEndHours = hours + (minutes + 15) / 60;
      const useForcedTimeAdjust =
        hours > selectedEndHours ||
        (hours === selectedEndHours && minutes >= selectedEndMinutes);
      const selectedEndTime = useForcedTimeAdjust
        ? `${forcedEndHours}:${forcedEndMinutes}`
        : defaultEndTime;
      setSearchParams((prev) => {
        prev.set('st', time);
        prev.set('et', selectedEndTime);
        return prev;
      });
    };
  }, [
    setSearchParams,
    searchParams,
    timezone,
    selectedEndMinutes,
    selectedEndHours,
  ]);

  const setSelectedEndTime = useMemo(() => {
    return (hours: number, minutes: number) => {
      const time = `${hours}:${minutes}`;
      setSearchParams((prev) => {
        prev.set('et', time);
        return prev;
      });
    };
  }, [setSearchParams, searchParams, timezone]);

  return {
    selectedStartHours,
    selectedStartMinutes,
    setSelectedStartTime,
    setSelectedEndTime,
    selectedStartTimeString,
    selectedEndTimeString,
    selectedEndMinutes,
    selectedEndHours,
  };
}
