import { SeatToAvatarType } from '../types';

export const getIconImageCondition = (
  prefix: string,
  seatsToAvatars: SeatToAvatarType
) => [
  'match',
  ['get', 'ownerId'],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ...Object.entries(seatsToAvatars).reduce((carry: any[], [seatId, value]) => {
    carry.push(parseInt(seatId), [
      'concat',
      prefix,
      [
        'string',
        `{"image": ${JSON.stringify(value.image)}, "fallback": ${JSON.stringify(
          value.fallback
        )}}`,
      ],
    ]);

    return carry;
  }, []),
  '',
];
