import moment, { Moment } from 'moment';

import { UseSeatDetailsQuery } from 'generated';

// This source file contains direct ports of dashboard angular business logic.
// This should be purged in favor of centralized assertions of state contained within backend systems.

type SeatType = UseSeatDetailsQuery['getDeskById'];
type SeatReservationType = Exclude<
  SeatType,
  null | undefined
>['state']['reservations'][0];
type DateRangeType = { start: string; end: string };
type SeatExclusionType = Exclude<
  SeatType,
  null | undefined
>['state']['exclusions'][0];
type SeatSettingsType = Exclude<
  SeatType,
  null | undefined
>['reservationPolicies'];

export const isSeatReservableForDateRanges = (
  seat: SeatType,
  dateRanges: DateRangeType[],
  time = moment()
) => {
  if (!seat?.isReservable) {
    // Non-reservable seats aren't reservable, no matter the seat type.
    return false;
  }

  const reservableAsHotDesk = !violatesHotDeskBookingPolicy(seat, dateRanges);

  // If this reservation overlaps another desk reservation, check that we're able to
  // "reverse hotel" to create an overlapping reservation over the conflicting
  // reservation's exclusion period.
  const isEncapsulated = dateRanges.every((dateRange) => {
    const { start: dateStart, end: dateEnd } = dateRange;
    return canBookOverlappingReservation(
      dateStart,
      dateEnd,
      seat.state.reservations,
      seat
    );
  });

  seat.reservationPolicies?.seatReservationTypesAllowed;
  // If the desk is hoteled, or reverse hoteled
  if (
    seat.reservationPolicies?.seatReservationTypesAllowed === 'hoteled' ||
    isEncapsulated
  ) {
    // Check the permission to reserve seats.
    if (!seat.permissions.some((p) => p.name === 'seats:reserve' && p.value)) {
      return false;
    }

    // Check the permission to bypass booking policies.
    if (
      seat.permissions.some(
        (p) => p.name === 'seats:bypass_booking_policies' && p.value
      )
    ) {
      return true;
    }

    if (
      !isReservationPolicyCompliant(
        seat.reservationPolicies,
        isEncapsulated,
        dateRanges[0].start,
        dateRanges[dateRanges.length - 1].end,
        time
      )
    ) {
      return false;
    }

    return true;
  } else if (
    seat.reservationPolicies?.seatReservationTypesAllowed === 'assigned'
  ) {
    // Check the permission to assign seats.
    return seat.permissions.some((p) => p.name === 'seats:assign' && p.value);
  }
  return reservableAsHotDesk || false;
};

const violatesHotDeskBookingPolicy = (
  seat: SeatType,
  ranges: DateRangeType[]
) => {
  if (
    seat?.reservationPolicies?.seatReservationTypesAllowed === 'assigned' &&
    seat.isReservable
  ) {
    if (!seat.permissions.some((p) => p.name === 'seats:reserve' && p.value)) {
      return true;
    }
    const time = moment();
    const { end } = ranges[ranges.length - 1];
    // the reservation ends by the end of the day
    return moment(end).isAfter(time.clone().add(1, 'day').startOf('day'));
  }
};

const canBookOverlappingReservation = (
  start: string,
  end: string,
  reservations: SeatReservationType[],
  seat: SeatType
) => {
  if (!Array.isArray(reservations) || reservations.length === 0) return false;

  // Specifically needs start/end format for the function `doesOneBlockFullyOverlapAnother`
  const bookingBlock = {
    start,
    end,
  };

  /**
   * Returns all reverse hotel desk reservations from an array of reservations.
   */
  const reverseHotelReservations = (reservations: SeatReservationType[]) =>
    reservations.filter((reservation) => reservation.type === 'reverse_hotel');

  const overlappingReverseHotelReservations = reverseHotelReservations(
    reservations
  ).filter((reservation) => doBlocksOverlap(bookingBlock, reservation));
  if (overlappingReverseHotelReservations.length > 0) {
    // The booking window already contains a reverse hotel reservation and is unavailable.
    // You can't overlap two reverse hotel reservations.
    return false;
  }

  // Look for any reservation that we can book over. You can book over it if your booking
  // period fits inside an exclusion period of another reservation.
  const filter = createFilterReservationsWithOverlappingExclusion(start, end);
  const reservationsWithOverlappingExclusion = filter(seat);
  return reservationsWithOverlappingExclusion === undefined
    ? false
    : reservationsWithOverlappingExclusion.length > 0;
};

const isReservationPolicyCompliant = (
  settings: SeatSettingsType,
  exclusion: boolean,
  reservationStart: string,
  reservationEnd: string,
  time: Moment
) => {
  if (shouldEnforcePolicies(settings, exclusion)) {
    const maxDuration = settings?.seatReservationMaxLength ?? null;
    const maxBookingThreshold =
      settings?.seatReservationAdvancedBookingThreshold;
    const intendedDuration = moment.duration(
      moment(reservationEnd).diff(moment(reservationStart))
    );

    // Check to see if the booking policies are exceeded.
    const exceedsDuration =
      maxDuration &&
      intendedDuration.as('minutes') >
        moment.duration(maxDuration).as('minutes');
    const exceedsMaxBookingThreshold =
      maxBookingThreshold &&
      moment(reservationStart).diff(time) >
        moment.duration(maxBookingThreshold).as('milliseconds');

    // If either policy is exceeded, the reservation is not policy compliant.
    if (exceedsDuration || exceedsMaxBookingThreshold) {
      return false;
    }
  }

  return true;
};

const shouldEnforcePolicies = (
  settings: SeatSettingsType,
  exclusion: boolean
) => {
  const reservationTypesAllowed = settings?.seatReservationTypesAllowed;

  if (
    Array.isArray(reservationTypesAllowed) &&
    // If it's a hoteled desk.
    (reservationTypesAllowed.includes('hoteled') ||
      // or its a reverse hoteled desk.
      (reservationTypesAllowed.includes('assigned') && exclusion))
  ) {
    return true;
  }

  return false;
};

const doBlocksOverlap = (
  blockA: DateRangeType,
  blockB: SeatReservationType,
  thresholdMinutes = 0
) => {
  const startA = moment(blockA.start);
  const endA = moment(blockA.end);
  const startB = moment(blockB.startTime);
  const endB = moment(blockB.endTime);

  return (
    startA.isBefore(endB.add(thresholdMinutes)) &&
    endA.isAfter(startB.subtract(thresholdMinutes))
  );
};

const createFilterReservationsWithOverlappingExclusion = (
  start: string,
  end: string
) => {
  const blockToEncompass = {
    start,
    end,
  };

  return (seat: SeatType) =>
    (seat?.state.exclusions
      ? sortAndMergeExclusions(seat.state.exclusions).filter(
          (exclusion: SeatExclusionType) =>
            // For each reservation, see if there are any exclusion periods that
            // can fit the start-end block.
            doesOneBlockFullyOverlapAnother(blockToEncompass, exclusion)
        )
      : []) ?? [];
};

const sortAndMergeExclusions = (
  exclusions: SeatExclusionType[] | null | undefined
) => {
  if (!exclusions) {
    return [];
  }

  return exclusions
    .sort(sortByStartTime)
    .reduce(
      (mergedExclusions: SeatExclusionType[], exclusion: SeatExclusionType) => {
        const lastExclusion = mergedExclusions[mergedExclusions.length - 1];
        // Merge two exclusion periods that are exactly adjacent.
        if (
          lastExclusion &&
          moment(lastExclusion.endTime).isSame(moment(exclusion.startTime))
        ) {
          mergedExclusions.pop();
          mergedExclusions.push({
            ...lastExclusion,
            endTime: exclusion.endTime,
          });
        } else {
          mergedExclusions.push(exclusion);
        }

        return mergedExclusions;
      },
      []
    );
};

const doesOneBlockFullyOverlapAnother = (
  smallerBlock: DateRangeType,
  largerBlock: SeatExclusionType
) => {
  const smallerStart = moment(smallerBlock.start);
  const smallerEnd = moment(smallerBlock.end);
  const largerStart = moment(largerBlock.startTime);
  const largerEnd = moment(largerBlock.endTime);

  return (
    smallerStart.isSameOrAfter(largerStart) &&
    smallerEnd.isSameOrBefore(largerEnd)
  );
};

const sortByStartTime = (a: SeatExclusionType, b: SeatExclusionType) => {
  if (typeof a !== 'object' || typeof b !== 'object') {
    throw new Error(
      'Error: Function sortByStartTime must take objects as parameters.'
    );
  }
  let startTimeA: string;
  let startTimeB: string;
  if (a?.startTime && b.startTime) {
    startTimeA = a.startTime;
    startTimeB = b.startTime;
  } else {
    // eslint-disable-next-line no-console
    console.warn(
      'Warning: Attempted to sort objects by start, and at least one of them was not given a start property.'
    );
    return 0;
  }
  return sortTimes(startTimeA, startTimeB);
};

const sortTimes = (a: string, b: string) => {
  if (moment.isMoment(a)) {
    a = a.format();
  }
  if (moment.isMoment(b)) {
    b = b.format();
  }
  // We can use our alphabetic sorting to compare dates since they're
  // in a standard yyyy-mm-ddThh:mm:ss format.
  return sortAlphabetically(a, b) ?? 0;
};

const sortAlphabetically = (
  a: string,
  b: string,
  getString = (item: string) => item
) => {
  const stringA = getString(a);
  const stringB = getString(b);
  // Natural sort order for strings

  if (!stringA || !stringB) {
    return;
  }

  return stringA.localeCompare(stringB, undefined, { numeric: true });
};
