import { unitFormat } from './utils';

export type ThemeBreakpoints = {
  XS: string;
  SM: string;
  MD: string;
  LG: string;
};

export const BREAKPOINTS: ThemeBreakpoints = {
  XS: unitFormat(0),
  SM: unitFormat(768),
  MD: unitFormat(1024),
  LG: unitFormat(1440),
};
