import { useSelectedLocationTimezone } from 'hooks';
import React, { FC, ReactNode, useContext } from 'react';

export type LocalizationContextType = {
  timezone: string;
};

const Context = React.createContext<LocalizationContextType>({
  timezone: '',
});

export const LocationTimezoneProvider: FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const timezoneData = useSelectedLocationTimezone();

  const timezone = timezoneData.data?.getLocationById?.timezone ?? '';

  return <Context.Provider value={{ timezone }}>{children}</Context.Provider>;
};

export function useLocationContext(): LocalizationContextType {
  return useContext(Context);
}
