import React, { useState, useRef, useCallback, FC } from 'react';
import styled from '@emotion/styled';
import { useClickAway } from 'react-use';
import {
  BrownFontStack,
  Colors,
  CoreFontSize,
} from '@robinpowered/design-system';
import { DropdownButton, DropdownButtonText } from './DropdownButton';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Dropdown: FC<any> = ({
  buttonText,
  buttonTextClass,
  buttonTextStyle,
  buttonIcon,
  buttonStyle,
  buttonComponent,
  menuStyle,
  scrollToOffset = 0,
  shouldCloseMenu,
  onMouseDown,
  children,
  style,
  disabled = false,
  ...props
}) => {
  const ref = useRef(null);
  const [isMenuOpen, setMenuOpen] = useState(false);

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const menuRef = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (node: any) => {
      if (node && scrollToOffset) {
        node.scrollTop =
          scrollToOffset - node.getBoundingClientRect().height / 2;
      }
    },
    [scrollToOffset]
  );

  useClickAway(ref, () => closeMenu());

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onMenuClick = (event: any) => {
    if (!shouldCloseMenu || shouldCloseMenu(event)) {
      closeMenu();
    }
  };

  const onButtonClick = () => {
    setMenuOpen((open) => !open);
  };

  return (
    // Sometimes we need to pass in an onMouseDown event so the dropdown component can
    // behave with other mouse events.
    <div
      {...props}
      ref={ref}
      style={style}
      onMouseDown={onMouseDown}
      onKeyUp={(e) => (e.keyCode === 27 ? closeMenu() : null)}
    >
      {buttonComponent ? (
        buttonComponent({ onClick: onButtonClick, 'aria-expanded': isMenuOpen })
      ) : (
        <DropdownButton
          small
          variant="secondary"
          style={buttonStyle}
          buttonIcon={buttonIcon}
          iconOnly={buttonIcon && !buttonText}
          disabled={disabled}
          onClick={onButtonClick}
        >
          {buttonIcon}
          <DropdownButtonText
            className={buttonTextClass}
            style={buttonTextStyle}
          >
            {buttonText}
          </DropdownButtonText>
        </DropdownButton>
      )}

      {isMenuOpen && (
        <DropdownMenu ref={menuRef} style={menuStyle} onClick={onMenuClick}>
          {typeof children === 'function' ? children({ closeMenu }) : children}
        </DropdownMenu>
      )}
    </div>
  );
};

export const DropdownMenu = styled.div`
  background-color: ${Colors.Tan5};
  border-radius: 6px;
  border: 1px solid ${Colors.Tan70};
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  font-family: ${BrownFontStack};
  font-size: ${CoreFontSize.s14};
  margin-top: 4px;
  max-height: 200px;
  overflow-y: auto;
  padding: 8px 0;
  position: absolute;
  min-width: 224px; // IE11 fallback
  min-width: max-content; // everything except IE11
  z-index: 3;
  right: 0;
`;
