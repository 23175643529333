import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { GroupingSpaceStateComponentProgressiveLoad } from './GroupingSpaceStateComponentProgressiveLoad';
import { useMap } from 'react-map-gl';
import { useRecoilValue } from 'recoil';
import { MapIdContext, useMapLoadCompleteAtom } from '../state';
import { SpaceDataType } from '../types';
import { mapSpaceStateToMapType } from '../dataMaps';
import React from 'react';

type SpaceStateComponentProgressiveLoadProperties = {
  spacePerLoadCycle?: number;
  availabilityTimestamp: string;
};

/**
 * Map component that loads space availability state and updates the visual status of spaces
 * @param seatPerLoadCycle Optional: Number of spaces loaded per-iteration within the progressive loading scheme
 * @param availabilityTimestamp ISO timestamp indicating time of availability
 * @returns React component
 */
export const SpaceStateComponentProgressiveLoad: FC<
  SpaceStateComponentProgressiveLoadProperties
> = ({ availabilityTimestamp, spacePerLoadCycle = 10 }) => {
  const id = useContext(MapIdContext);
  const map = useMap()[id];
  const mapLoadState = useRecoilValue(useMapLoadCompleteAtom(id));
  const [spaceSetState, setSpaceSetState] = useState<SpaceDataType[]>([]);
  useEffect(() => {
    if (!map || !mapLoadState) {
      return;
    }
    const spaceLayerData =
      map?.querySourceFeatures('spaces', {
        sourceLayer: 'spaces',
      }) ?? [];

    const spaceMerge: SpaceDataType = {};
    const spaceSet: SpaceDataType[] = [{}];
    let groupingIndex = 0;
    spaceLayerData
      .sort((a, b) => {
        // sort by space ID, so everything loads in the same order each time
        const AID = a.id ?? 0;
        const BID = b.id ?? 0;
        return AID < BID ? 1 : -1;
      })
      .forEach((sl) => {
        if (
          sl.properties?.ownerType === 'spaces' &&
          sl.properties.ownerId &&
          sl.id
        ) {
          spaceMerge[sl.properties.ownerId] = [
            sl.id,
            ...(spaceMerge[sl.properties.ownerId] ?? []),
          ];
        }
      });
    if (Object.keys(spaceMerge).length > 0) {
      Object.keys(spaceMerge).forEach((sl) => {
        if (
          Object.keys(spaceSet[groupingIndex] ?? {}).length >= spacePerLoadCycle
        ) {
          groupingIndex += 1;
          spaceSet[groupingIndex] = {};
        }
        spaceSet[groupingIndex][sl] = spaceMerge[sl];
      });
      setSpaceSetState(spaceSet);
    }
  }, [map, setSpaceSetState, mapLoadState, spacePerLoadCycle]);

  useEffect(() => {
    return () => {
      if (!map || !map.isStyleLoaded()) {
        return;
      }

      const spaces =
        map?.querySourceFeatures('spaces', {
          sourceLayer: 'spaces',
        }) ?? [];

      spaces.forEach((sp) => {
        map.setFeatureState(
          { source: 'spaces', id: sp.id },
          mapSpaceStateToMapType(null)
        );
      });
    };
  }, []);

  return useMemo(() => {
    const components = spaceSetState.map((spaceGroupData) => {
      const spaceIds = Object.keys(spaceGroupData);
      if (spaceIds.length < 1) {
        return null;
      }
      const kid = spaceIds[0];
      return (
        <GroupingSpaceStateComponentProgressiveLoad
          id={id}
          key={kid}
          spaceData={spaceGroupData}
          availabilityTimestamp={availabilityTimestamp}
        />
      );
    });
    return <>{components}</>;
  }, [spaceSetState, id, availabilityTimestamp]);
};
