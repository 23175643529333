import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

type SelectedDateType = {
  selectedDates: string[];
};

export function useMultiDates(): SelectedDateType {
  const [searchParams] = useSearchParams();
  const searchTermsMultiDate = searchParams.get('mdt');

  const selectedDates = useMemo(() => {
    if (searchTermsMultiDate) {
      try {
        return JSON.parse(searchTermsMultiDate);
      } catch {
        return [];
      }
    }
    return [];
  }, [searchTermsMultiDate]);

  return { selectedDates };
}
