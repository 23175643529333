import { FC } from 'react';
import styled from '@emotion/styled';
import { Colors } from '@robinpowered/design-system';
import { Notifications } from '../../components';
import { useHeaderContext } from '../../contexts';

const NotificationWrapper = styled.a({
  position: 'relative',
  display: 'flex',
  padding: '3px',
  marginRight: '8px',
  borderRadius: '50%',
  border: '2px solid transparent',
  '&:hover': {
    backgroundColor: Colors.Tan5,
  },
  '&:focus-visible': {
    borderColor: Colors.Maroon100,
  },
});

const Badge = styled('span')<{ hasNotifications?: number }>(
  {
    position: 'absolute',
    padding: '3px',
    borderRadius: '50%',
    top: '7px',
    right: '7px',
    backgroundColor: Colors.RedProduct,
    border: `1px solid ${Colors.White0}`,
    '&:hover': {
      border: `1px solid ${Colors.Tan5}`,
      background: Colors.Tan5,
    },
  },
  (props) => ({
    display: props.hasNotifications ? 'inline' : 'none',
  })
);

export const NotificationMenuItem: FC = () => {
  const { activeOrg, userPermissions } = useHeaderContext();

  const { canApproveSpaceRequests, totalPendingNotifications } =
    userPermissions || {};

  if (!canApproveSpaceRequests || !activeOrg) {
    return null;
  }

  return (
    <NotificationWrapper href={`/${activeOrg?.slug}/notifications`}>
      <Notifications height="27px" width="27px" />
      <Badge hasNotifications={totalPendingNotifications} />
    </NotificationWrapper>
  );
};
