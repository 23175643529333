import React from 'react';
import { useMySeatReservation } from '../hooks';
import { MapIdContext, useMapLoadCompleteAtom } from '../state';
import { FC, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MapRef, useMap } from 'react-map-gl';
import { useRecoilValue } from 'recoil';

const SEAT_MARKER_LAYER_ID = 'seat_markers';

export function createMarkerRules(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  t: any,
  map: MapRef,
  seatIds: (string | number)[]
) {
  const seatLayerData =
    map?.querySourceFeatures(SEAT_MARKER_LAYER_ID, {
      sourceLayer: SEAT_MARKER_LAYER_ID,
    }) ?? [];
  const seatSet = new Set(seatIds);

  const seatMatches = seatLayerData.filter(
    (sl) =>
      seatSet.has(`${sl.properties?.ownerId ?? '-1'}`) &&
      sl.properties?.ownerType === 'seats'
  );
  if (seatMatches.length < 1) {
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const iconImage: any[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const textField: any[] = [];
  seatMatches.forEach((mapSeat) => {
    const formattedText = ['format', t('your_desk'), {}];
    iconImage.push(
      ['==', ['get', 'ownerId'], mapSeat.properties?.ownerId],
      'seat_marker'
    );
    textField.push(
      ['==', ['get', 'ownerId'], mapSeat.properties?.ownerId],
      formattedText
    );
  });

  return { iconImage, textField };
}

type YourDeskMarkerPropType = {
  userId: string | null | undefined;
  startTime: string;
  endTime: string;
};

export const YourDeskMarker: FC<YourDeskMarkerPropType> = ({
  startTime,
  endTime,
  userId,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { t } = useTranslation<any>('map');
  const id = useContext(MapIdContext);
  const map = useMap()[id];
  const mapLoadState = useRecoilValue(useMapLoadCompleteAtom(id));
  const { data, error, loading } = useMySeatReservation(
    userId,
    startTime,
    endTime,
    false
  );

  const seatIds = useMemo(() => {
    if (!data || error || loading) {
      return [];
    }
    return data.getDeskReservationsByUserId.reservations.map((r) => r.seat.id);
  }, [data, error, loading]);

  const rules = useMemo(() => {
    if (map && mapLoadState) {
      const rules = createMarkerRules(t, map, seatIds);
      return rules;
    }
    return null;
  }, [map, mapLoadState, seatIds]);

  return rules && map && mapLoadState ? (
    <RulesRenderer rules={rules} map={map} />
  ) : null;
};

const RulesRenderer: FC<{
  map: MapRef;
  rules:
    | {
        iconImage: (string | (string | number | string[] | undefined)[])[];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        textField: any[][];
      }
    | {
        iconImage: string;
        textField: string;
      };
}> = ({ rules, map }) => {
  useEffect(() => {
    const mapApi = map.getMap();
    const { textField, iconImage } = rules;

    if (textField.length > 0) {
      mapApi.setLayoutProperty(SEAT_MARKER_LAYER_ID, 'text-field', [
        'case',
        ...textField,
        '',
      ]);
    }

    if (iconImage.length > 0)
      mapApi.setLayoutProperty(SEAT_MARKER_LAYER_ID, 'icon-image', [
        'case',
        ...iconImage,
        '',
      ]);
  }, []);
  useEffect(() => {
    return () => {
      if (map.loaded()) {
        const mapApi = map.getMap();
        mapApi.setLayoutProperty(SEAT_MARKER_LAYER_ID, 'text-field', '');
        mapApi.setLayoutProperty(SEAT_MARKER_LAYER_ID, 'icon-image', '');
      }
    };
  }, []);

  return null;
};
