import { BookingPolicyType } from 'generated';
import { SeatStateTypeOnlyReservationType } from '../types';

/**
 * Function maps seat booking policy to map state type
 * @param bookingPolicy Seat booking policy
 * @returns Map seat state type
 */
export function mapSeatTypeToMapType(
  bookingPolicy: BookingPolicyType | undefined
): SeatStateTypeOnlyReservationType {
  switch (bookingPolicy) {
    default:
    case BookingPolicyType.Assigned:
      return {
        reservationTypes: 'assigned',
      };
    case BookingPolicyType.Hot:
      return {
        reservationTypes: 'hot',
      };
    case BookingPolicyType.Hoteled:
      return {
        reservationTypes: 'hoteled',
      };
    case BookingPolicyType.ReverseHotel:
      return {
        reservationTypes: 'reverse_hotel',
      };
  }
}
