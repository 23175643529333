import { QueryResult, useApolloClient } from '@apollo/client';
import {
  BookingPolicyType,
  UseSeatDetailsQuery,
  UseSeatDetailsQueryVariables,
} from 'generated';
import {
  useDeskAllowsIssueReports,
  useOrganizationSeatMapFeatures,
  usePriorityReservation,
  useSeatDetailsQuery,
} from '../../hooks';
import { OnMapClickEvent, OnMapClickEventTargetDetails } from '../../pointer';
import React, {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import moment from 'moment-timezone';
import { isSeatReservableForDateRanges } from './is-seat-reservable-for-date-ranges';
import { isReservationConfirmedNo } from './is-reservation-confirmed-no';
import { MapInfoContext } from '../../state';
import { cancelReservation } from './cancel-reservation';
import {
  BookingPolicyViolationsType,
  usePolicyViolationMessages,
} from './usePolicyViolationMessages';

export enum DeskReservationVisibility {
  JUST_ME = 'JUST_ME',
  EVERYONE = 'EVERYONE',
}

type ReservationType =
  | Exclude<
      Exclude<UseSeatDetailsQuery['getDeskById'], null | undefined>['state'],
      null | undefined
    >['reservations'][0]
  | null;

type ContextType = {
  hasAllConfirmedNoReservations: boolean;
  bookingPolicyViolations: BookingPolicyViolationsType;
  invitee: null | any;
  sendNotification: boolean;
  setInvitee: (invitee: any | null) => void;
  shouldShowActionBar: boolean;
  isReverseHotelReservation: boolean;
  isSeatBooked: boolean;
  loading: boolean;
  seatId: string | null;
  currentUserId: string | null;
  deskState?:
    | QueryResult<UseSeatDetailsQuery, UseSeatDetailsQueryVariables>
    | undefined;
  userCanDelegateSeat: boolean;
  isReservationVisibilityEnabled: boolean;
  seatData?: OnMapClickEventTargetDetails | null | undefined;
  mapId?: string | undefined;
  startTime?: string | null | undefined;
  endTime?: string | null | undefined;
  hasReverseHotelAccess: boolean;
  assignedReservation: ReservationType;
  reservation: ReservationType;
  isCurrentUsersAssignedReservation: boolean;
  isCurrentUsersReservation: boolean;
  canReverseHotel: boolean;
  canManageSeat: boolean;
  isSeatReservable: boolean;
  hasEncapsulatingExclusion: boolean;
  isAtCapacity: boolean;
  isConfirmedNoReservation: boolean;
  deskAllowsIssueReports: boolean;
  timezone?: string | null;
  setRequestToAssign: React.Dispatch<React.SetStateAction<boolean>>;
  didRequestToAssign: boolean;
  openLayout: () => void;
  onUnassignReservation: () => void;
  onCancelReservation: () => void;
  openReverseHotelModal: () => void;
  openDeskIssueReportingForm: () => void;
  onCreateReservation: () => void;
  setSendNotification: (value: boolean) => void;
  localVisibility: DeskReservationVisibility;
  setSeatData?: React.Dispatch<
    React.SetStateAction<OnMapClickEventTargetDetails | null>
  >;
  setLocalVisibility?: (vis: DeskReservationVisibility) => void;
  setLoading: (loading: boolean) => void;
};

const deskPopupContext = createContext<ContextType>({
  hasAllConfirmedNoReservations: false,
  bookingPolicyViolations: { policies: [], permissions: [] },
  sendNotification: true,
  invitee: null,
  shouldShowActionBar: false,
  isReverseHotelReservation: false,
  isSeatBooked: false,
  loading: false,
  userCanDelegateSeat: false,
  currentUserId: null,
  isReservationVisibilityEnabled: false,
  hasReverseHotelAccess: false,
  assignedReservation: null,
  reservation: null,
  isCurrentUsersAssignedReservation: false,
  isCurrentUsersReservation: false,
  canReverseHotel: false,
  canManageSeat: false,
  hasEncapsulatingExclusion: false,
  isSeatReservable: false,
  isAtCapacity: false,
  isConfirmedNoReservation: false,
  deskAllowsIssueReports: false,
  didRequestToAssign: false,
  setLoading: () => {
    console.warn('uninitialized handler hit');
  },
  setRequestToAssign: () => {
    console.warn('uninitialized handler hit');
  },
  openLayout: () => {
    console.warn('uninitialized handler hit');
  },
  onUnassignReservation: () => {
    console.warn('uninitialized handler hit');
  },
  onCancelReservation: () => {
    console.warn('uninitialized handler hit');
  },
  openReverseHotelModal: () => {
    console.warn('uninitialized handler hit');
  },
  openDeskIssueReportingForm: () => {
    console.warn('uninitialized handler hit');
  },
  onCreateReservation: () => {
    console.warn('uninitialized handler hit');
  },
  setInvitee: () => {
    console.warn('uninitialized handler hit');
  },
  setSendNotification: () => {
    console.warn('uninitialized handler hit');
  },
  localVisibility: DeskReservationVisibility.EVERYONE,
  seatId: null,
});

export const useDeskPopupContext = () => {
  return useContext(deskPopupContext);
};

// This entire context needs to be discarded and replaced with direct gql queries.
// There is entirely wayyyy too much business logic here.

export const DeskPopupContextProvider: FC<{
  mapId: string;
  startTime: string | null;
  endTime: string | null;
  children: React.ReactNode;
  currentUserId: string | null;
}> = ({ mapId, startTime, endTime, children, currentUserId }) => {
  const [localVisibility, setLocalVisibility] = useState(
    DeskReservationVisibility.EVERYONE
  );
  const [didRequestToAssign, setRequestToAssign] = useState(false);
  const [loading, setLoading] = useState(false);
  const [seatData, setSeatData] = useState<null | OnMapClickEventTargetDetails>(
    null
  );
  const [sendNotification, setSendNotification] = useState(true);

  const [invitee, setInvitee] = useState<null | any>(null);

  /*
  const [editSeatReservation, setEditSeatReservation] =
    useState<null | ReservationType>(null);
    */

  const apolloClient = useApolloClient();

  const { data: orgSeatMapFeatures } = useOrganizationSeatMapFeatures();

  const { orgSlug, levelId } = useContext(MapInfoContext);

  const seatId = useMemo(
    () => (seatData?.targetId ? `${seatData?.targetId}` : null),
    [seatData]
  );

  const deskState = useSeatDetailsQuery(
    currentUserId,
    seatId,
    startTime,
    endTime
  );

  const hasReverseHotelAccess = useMemo(() => {
    return (
      orgSeatMapFeatures?.getOrganizationById?.features?.reverseHoteling ??
      false
    );
  }, [orgSeatMapFeatures]);

  const assignedReservation = useMemo(() => {
    if (!deskState?.data?.getDeskById?.instantAvailabilityAt.reservation) {
      return null;
    }

    if (
      deskState.data.getDeskById.instantAvailabilityAt.reservation.type ===
      'assigned'
    ) {
      return deskState.data.getDeskById.instantAvailabilityAt.reservation;
    }
    return null;
  }, [deskState]);

  const isCurrentUsersAssignedReservation = useMemo(
    () =>
      currentUserId !== null &&
      assignedReservation?.accountReservee?.user?.id === currentUserId,
    [assignedReservation, currentUserId]
  );

  const reservation = usePriorityReservation(
    deskState.data?.getDeskById?.state.reservations
  );

  const isCurrentUsersReservation = useMemo(
    () =>
      currentUserId !== null &&
      reservation?.accountReservee?.user?.id === currentUserId,
    [reservation, currentUserId]
  );

  useEffect(() => {
    const token = PubSub.subscribe(
      `${mapId}_onClick`,
      (_: string, eventContents: OnMapClickEvent) => {
        if (
          eventContents.targetDetails.length < 1 ||
          !eventContents.targetDetails.some((td) => td.type === 'seats')
        ) {
          setSeatData(null);
        } else {
          setSeatData(
            eventContents.targetDetails.find((td) => td.type === 'seats') ??
              null
          );
        }
      }
    );
    return () => {
      PubSub.unsubscribe(token);
      setSeatData(null);
    };
  }, []);

  const userCanDelegateSeat = useMemo(() => {
    const perms = deskState.data?.getDeskById?.permissions ?? [];
    return perms.some((p) => p.name === 'seats:delegate' && p.value);
  }, [deskState]);

  const canReverseHotel = useMemo(
    () =>
      reservation?.type === 'assigned' &&
      deskState.data?.getDeskById?.reservationPolicies?.allowExclusions ===
        true,
    [reservation]
  );

  const combinedExclusions = useMemo(() => {
    const exclusions = deskState.data?.getDeskById?.state?.exclusions;
    if (!exclusions || exclusions.length < 1) {
      return [];
    }

    if (exclusions.length === 1) {
      return exclusions;
    }

    const sortedExclusions = exclusions.sort((a, b) =>
      moment(a.startTime).diff(moment(b.startTime))
    );

    const results = [sortedExclusions.shift()];

    sortedExclusions.forEach((ex) => {
      const lastExcl = results.pop();
      if (
        lastExcl?.endTime &&
        moment(lastExcl.endTime).isSameOrAfter(moment(ex.startTime))
      ) {
        lastExcl.endTime = ex.endTime;
        results.push(lastExcl);
      } else {
        results.push(lastExcl);
        results.push(ex);
      }
    });
    return results;
  }, [deskState]);

  const hasEncapsulatingExclusion = useMemo(
    () => combinedExclusions.length > 0,
    [combinedExclusions]
  );

  const canManageSeat = useMemo(() => {
    return (
      deskState.data?.getDeskById?.permissions.some(
        (p) => p.name === 'places:manage' && p.value
      ) ?? false
    );
  }, [deskState]);

  const isSeatReservable = useMemo(() => {
    if (!deskState || !deskState.data || !startTime || !endTime) {
      return false;
    }
    return isSeatReservableForDateRanges(deskState.data.getDeskById, [
      { start: startTime, end: endTime },
    ]);
  }, [deskState.data, startTime, endTime]);

  const isAtCapacity = useMemo(() => {
    if (
      !deskState.data ||
      (deskState.data.getDeskById?.type === BookingPolicyType.Assigned &&
        !deskState.data.getDeskById.reservationPolicies?.allowExclusions)
    ) {
      return false;
    }
    const freeCap = deskState.data?.getDeskById?.level?.state.freeCapacity ?? 0;
    return freeCap < 1;
  }, [deskState]);

  const isConfirmedNoReservation = useMemo(
    () => isReservationConfirmedNo(reservation),
    [reservation]
  );

  const deskAllowsIssueReports =
    useDeskAllowsIssueReports(seatData?.targetId, true).data
      ?.deskAllowsIssueReports.allowed ?? false;

  // Redirects the user to view details about the zone for the seat.
  const openLayout = useCallback(() => {
    if (!orgSlug || !levelId) {
      return;
    }
    window.location.href = `${process.env.REACT_APP_DASHBOARD_URL}/${orgSlug}/manage-map/${levelId}`;
  }, [orgSlug, levelId]);

  // Resets request to assign to false and set currently selected invitee to null
  const resetAssignState = useCallback(() => {
    setRequestToAssign(false);
    //setInvitee(null);
  }, [setRequestToAssign]);

  const onUnassignReservation = useCallback(() => {
    console.log('unassignres');

    setLoading(true);
    resetAssignState();

    return cancelReservation(
      deskState.data?.getDeskById ?? null,
      assignedReservation ?? null
    )
      ?.catch((error) => {
        console.error(error);
        // TBD
      })
      .finally(() => {
        setLoading(false);
        apolloClient.refetchQueries({ include: 'active' });
      });
  }, [deskState, assignedReservation, apolloClient]);

  const onCancelReservation = useCallback(() => {
    console.log('onCancelReservation');
  }, []);

  // Opens the reverse hotel modal.
  const openReverseHotelModal = () => {
    /*

    ReverseHotelUtils.openReverseHotelModalWithSeat(
      seat,
      assignedReservation.series_id || assignedReservation.id,
      assignedReservation.start.time_zone,
      true
    )
      .then(() => onReverseHotelModalClose())
      .catch(ModalActionError, () => {});
      */
  };

  const openDeskIssueReportingForm = useCallback(() => {
    /*
    showDeskIssueReportForm();
    */
  }, []);

  const isReservationVisibilityEnabled = useMemo(() => {
    return (
      deskState.data?.getDeskById?.reservationPolicies
        ?.seatReservationVisibilityEnabled ?? false
    );
  }, [reservation]);

  const timezone = useMemo(() => {
    return deskState.data?.getDeskById?.level?.timezone ?? null;
  }, [deskState]);

  // Handle reserving a seat
  const onReserveSeat = (
    seatType: BookingPolicyType,
    visibility: DeskReservationVisibility
  ) => {
    console.log('on reserve seat', seatType, visibility);
    //setLoading(true);
    /*
    reserveSeat(seatType, visibility)
      .then((result) => {
        // const reservations = Array.isArray(result) ? result.flat() : [result];
        // Reset invitee form
        resetAssignState();
        //setReservation(reservations[0]);
        //updateSeatOnAssign(seat, reservations);
        //trackDeskBooked(seat, reservations);
        //onReservationCreated(reservations[0].id);
      })
      .catch((error) => Alert.apiError(error, 'SEATS.RESERVATIONS.HOT.FAILED'))
      .finally(() => {
        setLoading(false);
        apolloClient.refetchQueries({ include: 'active' });
        setEditSeatReservation(null);
        // deselect seat
      });
      */
  };

  // Handle assigning a seat
  const onAssignSeat = (visibility: DeskReservationVisibility) => {
    console.log('on assign seat', visibility);
    //setLoading(true);

    /*
    return assignSeat(visibility)
      .then((returnedReservation) => {
        // Reset invitee form
        resetAssignState();
        setReservation(returnedReservation);
        updateSeatOnAssign(seat, [returnedReservation]);
        trackDeskBooked(seat, [returnedReservation]);
      })
      .catch(() => {
        Alert.error(
          $translate.instant('SEATS.RESERVATIONS.ASSIGNMENT.ASSIGN_FAILURE')
        );
      })
      .finally(() => {
        officeState.refetch();
        setEditSeatReservation(null);
        setLoading(false);
        setSelectedSeat(null);
      });
      */
  };

  // Maps to the correct create function depending on the seat type.
  const onCreateReservation = useCallback(() => {
    const seatReservationType = deskState.data?.getDeskById?.type;
    if (!seatReservationType) {
      return null;
    }

    // TBD: Fix me

    switch (seatReservationType) {
      case BookingPolicyType.Hot:
      case BookingPolicyType.Hoteled:
        return onReserveSeat(seatReservationType, localVisibility);
      case BookingPolicyType.Assigned:
        if (hasEncapsulatingExclusion) {
          return onReserveSeat(BookingPolicyType.ReverseHotel, localVisibility);
        } else {
          return onAssignSeat(localVisibility);
        }
      default:
        return null;
    }
  }, [localVisibility, hasEncapsulatingExclusion, deskState]);

  const bookingPolicyViolations = usePolicyViolationMessages();

  const shouldShowActionBar = useMemo(
    () =>
      bookingPolicyViolations.permissions.length === 0 ||
      (bookingPolicyViolations.permissions.length === 1 && isAtCapacity),
    [bookingPolicyViolations]
  );

  // Checks if the reservation type is reverse hotel
  const isReverseHotelReservation = useMemo(
    () => (reservation ? reservation.type === 'reverse_hotel' : false),
    [reservation]
  );

  // Checks if the reservation type is assigned
  const isAssignedReservation = useMemo(
    () => (reservation ? reservation.type === 'assigned' : false),
    [reservation]
  );

  const isConfirmedNoHoteledReservation =
    !isAssignedReservation && isConfirmedNoReservation;

  const isSeatBooked = useMemo(
    () =>
      (reservation &&
        !hasEncapsulatingExclusion &&
        !isConfirmedNoHoteledReservation) ||
      isReverseHotelReservation,
    [
      reservation,
      hasEncapsulatingExclusion,
      isConfirmedNoHoteledReservation,
      isReverseHotelReservation,
    ]
  );

  const hasAllConfirmedNoReservations =
    (deskState.data?.getDeskById?.state.reservations ?? []).length > 0 &&
    (deskState.data?.getDeskById?.state.reservations ?? []).every(
      isReservationConfirmedNo
    );

  return (
    <deskPopupContext.Provider
      value={{
        hasAllConfirmedNoReservations,
        bookingPolicyViolations,
        invitee,
        setInvitee,
        isReverseHotelReservation,
        isSeatBooked,
        shouldShowActionBar,
        onCreateReservation,
        localVisibility,
        loading,
        setLoading,
        timezone,
        canManageSeat,
        currentUserId,
        deskState,
        seatData,
        mapId,
        startTime,
        endTime,
        setSeatData,
        userCanDelegateSeat,
        hasReverseHotelAccess,
        reservation,
        assignedReservation,
        isCurrentUsersAssignedReservation,
        isCurrentUsersReservation,
        canReverseHotel,
        hasEncapsulatingExclusion,
        isSeatReservable,
        isAtCapacity,
        didRequestToAssign,
        setRequestToAssign,
        isConfirmedNoReservation,
        deskAllowsIssueReports,
        openLayout,
        onUnassignReservation,
        onCancelReservation,
        openReverseHotelModal,
        openDeskIssueReportingForm,
        isReservationVisibilityEnabled,
        setLocalVisibility,
        seatId,
        sendNotification,
        setSendNotification,
      }}
    >
      {children}
    </deskPopupContext.Provider>
  );
};
