import { MapIdContext } from '../state';
import { FC, useContext, useMemo } from 'react';
import { useMySeatReservation } from '../hooks';
import { useMap } from 'react-map-gl';
import { BasicMySeatPopup } from './BasicMySeatPopup';
import { UseMySeatReservationQuery } from 'generated';
import React from 'react';

type DataPairType = {
  seatLayerData: mapboxgl.MapboxGeoJSONFeature;
  queryData: UseMySeatReservationQuery['getDeskReservationsByUserId']['reservations'][0];
}[];

/**
 * My seat popup component.
 *
 * Component displays a popup over booked seats for the current user.
 */
export const MySeatPopup: FC<{
  startTime: string | null;
  endTime: string | null;
  userId: string | null | undefined;
}> = (props) => {
  const id = useContext(MapIdContext);

  if (id === '') {
    return null;
  }

  return <MySeatPopupChild {...props} mapId={id} />;
};

const MySeatPopupChild: FC<{
  mapId: string;
  userId: string | null | undefined;
  startTime: string | null;
  endTime: string | null;
}> = ({ mapId, userId, startTime, endTime }) => {
  const map = useMap()[mapId];
  const mySeatQuery = useMySeatReservation(userId, startTime, endTime, true);

  const seatComponents = useMemo(() => {
    if (!map || !mySeatQuery || mySeatQuery.loading || mySeatQuery.error) {
      return null;
    }

    const reservations =
      mySeatQuery.data?.getDeskReservationsByUserId.reservations ?? [];

    const seatLayerData =
      map?.querySourceFeatures('seats', {
        sourceLayer: 'seats',
      }) ?? [];

    const dataPairs: DataPairType = [];

    reservations?.forEach((res) => {
      const sld = seatLayerData.find(
        (sld) => `${sld?.properties?.ownerId ?? -1}` === res.seat.id
      );
      if (sld) {
        dataPairs.push({ seatLayerData: sld, queryData: res });
      }
    });

    if (dataPairs.length > 0) {
      return (
        <>
          {dataPairs.map((dp) => {
            return (
              <BasicMySeatPopup
                key={dp.seatLayerData.id}
                mapId={mapId}
                deskReservation={dp.queryData}
                seatLayerData={dp.seatLayerData}
              />
            );
          })}
        </>
      );
    }
    return null;
  }, [mySeatQuery, map]);

  return seatComponents;
};
