import React, { FC } from 'react';
import styled from '@emotion/styled';

import { Avatar, BrownFontStack, Colors } from '@robinpowered/design-system';
import { FONT_SIZES } from 'shared';
import { UseSeatDetailsQuery } from 'generated';

import userImage from '../../images/user-solid.svg';
import { useTranslation } from 'react-i18next';

type SeatType = UseSeatDetailsQuery['getDeskById'];
type SeatReservationType = Exclude<
  SeatType,
  null | undefined
>['state']['reservations'][0];
type ReserveeType = Exclude<
  SeatReservationType['accountReservee'],
  null | undefined
>;

type ExclusionInfoType = {
  reservation: SeatReservationType | null | undefined;
  highlighted: boolean;
};
export const ExclusionInfo: FC<ExclusionInfoType> = ({
  reservation,
  highlighted,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { t }: any = useTranslation('map');
  const reservee = reservation ? reservation.accountReservee : null;
  const reserveeName = reservee?.user?.name ?? reservee?.email ?? null;
  if (!reservee || !reserveeName) {
    return null;
  }
  return (
    <ExclusionInfoWrapper highlighted={highlighted}>
      <AvatarWrapper highlighted={highlighted}>
        <ReserveeAvatar reservee={reservee ?? null} />
      </AvatarWrapper>
      <ExclusionByline>{`${reserveeName}${t('usually_sits')}`}</ExclusionByline>
    </ExclusionInfoWrapper>
  );
};

export const ReserveeAvatar: FC<{
  reservee: ReserveeType | null | undefined;
}> = ({ reservee }) => {
  const reserveeName = reservee?.user?.name;
  if (!reserveeName) {
    return (
      <Container>
        <StyledImage src={userImage} />
      </Container>
    );
  }

  if (!reservee?.user?.avatar) {
    return null;
  }

  return <Avatar src={reservee.user.avatar} name={reserveeName} />;
};

const StyledImage = styled.img`
  color: ${Colors.Gray40};
  width: 16px;
  height: 16px;
`;

const Container = styled.div`
  align-items: center;
  background-color: ${Colors.Tan70};
  border-radius: 50%;
  color: ${Colors.White0};
  display: flex;
  flex-shrink: 0;
  height: 40px;
  justify-content: center;
  width: 40px;
`;

const ExclusionInfoWrapper = styled.div<{ highlighted: boolean }>(
  ({ highlighted }) => `
  align-items: center;
  background-color: ${highlighted ? Colors.Tan5 : Colors.White0};
  border-radius: 12px;
  display: inline-flex;
  height: ${highlighted ? '24px' : 'inherit'};
  margin-left: ${highlighted ? '-4px' : '-2px'};
  margin-top: ${highlighted ? '12px' : '8px'};
  padding: ${highlighted ? '2px 6px 2px 0' : '0'};
`
);

const AvatarWrapper = styled.div<{ highlighted: boolean }>(
  ({ highlighted }) => `
  border-radius: 50%;
  border: 2px solid ${Colors.White0};
  margin-left: ${highlighted ? '2px' : '0px'};
  margin-right: ${highlighted ? '8px' : '4px'};
`
);

const ExclusionByline = styled.p`
  color: ${Colors.Gray80};
  font-family: ${BrownFontStack};
  font-size: ${FONT_SIZES.TINY};
  line-height: ${FONT_SIZES.TINY};
  width: 100%;
  word-break: break-word;
  word-wrap: break-word;
`;
