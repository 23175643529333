import styled from '@emotion/styled';
import { Skeleton } from '@robinpowered/design-system';

export const PeopleSearchResultLoading = () => {
  return (
    <Container>
      <AvatarLoading />
      <NameLoading />
    </Container>
  );
};

const Container = styled.li`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 0;
`;

const AvatarLoading = styled(Skeleton)`
  border-radius: 50%;
  height: 28px;
  width: 28px;
  border: 2px solid white;
`;

const NameLoading = styled(Skeleton)`
  height: 24px;
  width: 60%;
`;
