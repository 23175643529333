import styled from '@emotion/styled';
import { Colors, Heading, TextLink } from '@robinpowered/design-system';
import { FC } from 'react';

export const SpaceDetailsSection: FC<{
  title?: string | null;
  linkText?: string | null;
  linkUrl?: string | null;
  children: React.ReactNode;
}> = ({ title, linkText, linkUrl, children }) => {
  return (
    <SpaceDetailsSectionWrapper>
      <Header>
        {title && <Heading.Small>{title}</Heading.Small>}
        {linkText && linkUrl && (
          <OptionalLink label={linkText} href={linkUrl} showDot={!!title} />
        )}
      </Header>

      {children}
    </SpaceDetailsSectionWrapper>
  );
};

const SpaceDetailsSectionWrapper = styled.div`
  margin-bottom: 16px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const Header = styled.div`
  display: flex;
`;

const OptionalLink = styled(TextLink)<{ showDot?: boolean }>`
  display: block;
  margin: 0 4px;
  color: ${Colors.Maroon100};
  text-decoration-color: ${Colors.Maroon100};
  text-underline-offset: 2px;
  font-size: 14px;

  &:before {
    display: inline-block;
    margin-right: 4px;
    color: black;
    ${({ showDot }) => (showDot ? "content: ' · '" : '')};
  }
`;
