import common from './common.json';
import error from './error.json';
import peopleTable from './perseusTable.json';
import cta from './cta.json';
import headerMenu from './headerMenu.json';
import userProfileMenu from './userProfileMenu.json';

export const fr = {
  common,
  error,
  peopleTable,
  cta,
  headerMenu,
  userProfileMenu,
};
