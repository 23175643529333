import { FC } from 'react';

export const BuildingSolid: FC = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.06068 1.33337C5.65902 1.33337 5.33341 1.67449 5.33341 2.09528L5.33337 9.33337H2.00004C1.63185 9.33337 1.33337 9.63185 1.33337 10V14C1.33337 14.3682 1.63185 14.6667 2.00004 14.6667H5.33337H13.9394C14.3411 14.6667 14.6667 14.3256 14.6667 13.9048V2.09528C14.6667 1.67449 14.3411 1.33337 13.9394 1.33337H6.06068ZM11.6 5.71423C12.0418 5.71423 12.4 6.05536 12.4 6.47614C12.4 6.89692 12.0418 7.23804 11.6 7.23804C11.1581 7.23804 10.8 6.89691 10.8 6.47612C10.8 6.05534 11.1582 5.71423 11.6 5.71423ZM11.6 8.76185C12.0418 8.76185 12.4 9.10297 12.4 9.52376C12.4 9.94454 12.0418 10.2857 11.6 10.2857C11.1581 10.2857 10.8 9.94453 10.8 9.52374C10.8 9.10296 11.1582 8.76185 11.6 8.76185ZM9.19995 9.52376C9.19995 9.10297 8.84179 8.76185 8.39996 8.76185C7.95815 8.76185 7.59999 9.10296 7.59998 9.52374C7.59996 9.94453 7.95813 10.2857 8.39996 10.2857C8.84179 10.2857 9.19995 9.94454 9.19995 9.52376ZM8.39996 5.71423C8.84179 5.71423 9.19995 6.05536 9.19995 6.47614C9.19995 6.89692 8.84179 7.23804 8.39996 7.23804C7.95813 7.23804 7.59996 6.89691 7.59998 6.47612C7.59999 6.05534 7.95815 5.71423 8.39996 5.71423Z"
        fill="#706E6E"
      />
    </svg>
  );
};
