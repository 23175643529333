import styled from '@emotion/styled';
import { Popup } from 'react-map-gl';

export const MapStyledPopup = styled(Popup)`
  padding: 0;
  width: 330px;
  height: auto;
  border: none;
  border-radius: 8px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15), 0px 1px 12px rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
  padding: 0px;
  font-size: 12px;
  .maplibregl-popup-content {
    padding: 0px;
    border-radius: 8px;
  }
  .maplibregl-popup-tip {
    display: none;
  }
  .maplibregl-popup-close-button {
    display: none;
  }
`;
