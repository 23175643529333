import { createContext, useContext, useMemo } from 'react';
import { ApolloError, useQuery, gql } from '@apollo/client';
import { useUserPermissions, UseUserPermissionsData } from '../hooks';
import {
  HeaderQuery,
  HeaderQuery_getCurrentUserAndOrgs_organizations,
  HeaderQuery_getCurrentUserAndOrgs_user,
} from './__generated__/HeaderQuery';
import { DashboardAppsHeaderUserPermissions_getCurrentUserAndOrgs } from '../hooks/__generated__/DashboardAppsHeaderUserPermissions';
import { AmplitudeEventHandler } from '../Header';

export const FEATURE_FLAG_QUERY = gql`
  query FeatureFlagQuery($flagType: FeatureFlagType) {
    isFeatureFlagEnabledForOrgOrMe(flagType: $flagType)
  }
`;

const GET_CURRENT_USER = gql`
  query HeaderQuery {
    getCurrentUserAndOrgs {
      userId
      user {
        id
        name
        avatar
        primaryEmail {
          email
        }
      }
      organizations {
        id
        name
        slug
        logo
        avatar
      }
    }
  }
`;

const initialContextState = {
  activeOrg: null,
  currentUser: null,
  isLoading: true,
  userOrganizations: null,
  hasTenant: true,
  userPermissions: {
    canManageAmenities: false,
    canViewAnalytics: false,
    canViewBilling: false,
    canViewCheckpoints: false,
    canManageDevices: false,
    canViewDevices: false,
    canManageOfficeAccess: false,
    canViewOfficeAccess: false,
    canManageGroups: false,
    canManagePlaces: false,
    canManageRoles: false,
    canApproveSpaceRequests: false,
    canListUsers: false,
    canManageUsers: false,
    canManageVisitors: false,
    canViewWorkweek: false,
    canManageWorkplaceServices: false,
    arePersonalCalendarsEnabled: false,
    isInsightsFeatureEnabled: false,
    totalPendingNotifications: 0,
    isMapUploadOnboardingComplete: true,
  },
  isWorkWeekHomepageEnabled: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  amplitudeEventHandler: () => {},
};

// For easier consumption downstream
export type Organization = HeaderQuery_getCurrentUserAndOrgs_organizations;
export type User = HeaderQuery_getCurrentUserAndOrgs_user;
export type Permissions =
  DashboardAppsHeaderUserPermissions_getCurrentUserAndOrgs;

type HeaderContext = {
  activeOrg?: null | Organization;
  currentUser: null | User;
  error?: ApolloError;
  isLoading: boolean;
  userOrganizations: null | Organization[];
  userPermissions: UseUserPermissionsData;
  hasTenant: boolean;
  isWorkWeekHomepageEnabled: boolean;
  amplitudeEventHandler: AmplitudeEventHandler;
};

export const currentOrgContext =
  createContext<HeaderContext>(initialContextState);

export const HeaderContextProvider = ({
  activeOrgSlug,
  children,
  hasTenant,
  amplitudeEventHandler,
}: {
  activeOrgSlug: string;
  children: JSX.Element;
  hasTenant: boolean;
  amplitudeEventHandler: AmplitudeEventHandler;
}): JSX.Element => {
  const { data, loading, error } = useQuery<HeaderQuery>(GET_CURRENT_USER, {
    skip: !hasTenant,
    nextFetchPolicy: 'cache-and-network',
  });

  const userOrganizations = useMemo(
    () =>
      data?.getCurrentUserAndOrgs?.organizations.slice().sort((a, b) => {
        if (a.name && b.name && a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        return 0;
      }) ?? [],
    [data]
  );

  const activeOrg = useMemo(
    () => userOrganizations?.find((o) => o.slug === activeOrgSlug) ?? null,
    [userOrganizations, activeOrgSlug]
  );

  const {
    error: permissionsError,
    isLoading: permissionsLoading,
    ...userPermissions
  } = useUserPermissions(activeOrg?.id || null, hasTenant);

  const { data: isWorkWeekHomepageEnabledRequest } = useQuery(
    FEATURE_FLAG_QUERY,
    {
      skip: !hasTenant,
      variables: {
        flagType: 'WORK_WEEK_HOMEPAGE',
      },
    }
  );

  const value = {
    hasTenant,
    activeOrg,
    currentUser: data?.getCurrentUserAndOrgs?.user ?? null,
    error: error || permissionsError,
    isLoading: loading || permissionsLoading,
    userOrganizations,
    userPermissions,
    isWorkWeekHomepageEnabled:
      isWorkWeekHomepageEnabledRequest?.isFeatureFlagEnabledForOrgOrMe,
    amplitudeEventHandler,
  };

  return (
    <currentOrgContext.Provider value={value}>
      {children}
    </currentOrgContext.Provider>
  );
};

export const useHeaderContext = (): HeaderContext => {
  return useContext(currentOrgContext);
};

export const useAmplitude = () => {
  const { amplitudeEventHandler: trackEvent } = useContext(currentOrgContext);

  return { trackEvent };
};
