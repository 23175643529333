import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocationLevels, useSelectedHierarchy } from 'hooks';
import { useSearchParams } from 'react-router-dom';
import { DropdownPicker } from './DropdownPicker';

export function LevelSelector({
  locationId,
  openPanel,
  setOpenPanel,
}: {
  locationId: string | null | undefined;
  campusId: string | null | undefined;
  openPanel: number;
  setOpenPanel: (panelId: number) => void;
}): JSX.Element | null {
  const locationData = useLocationLevels(locationId);
  const { t } = useTranslation('Level');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSearchParams] = useSearchParams();
  const { selectedLevelId } = useSelectedHierarchy();

  const filteredLocations = useMemo(() => {
    if (!locationData.data) {
      return [];
    }
    return (
      locationData.data?.getLocationById?.levels.filter(
        (l) => l.mapIsAvailable
      ) ?? []
    );
  }, [locationData]);

  const levelDisplayLine = useMemo(() => {
    if (
      !locationData.loading &&
      (locationData.data?.getLocationById?.levels.filter(
        (l) => l.mapIsAvailable
      ).length ?? 0) < 1
    ) {
      return t('No Maps For Locaiton');
    }

    if (!selectedLevelId) {
      return null;
    }

    return (
      locationData.data?.getLocationById?.levels.find(
        (l) => l.id === selectedLevelId
      )?.name ?? null
    );
  }, [selectedLevelId, locationData]);

  return (
    <DropdownPicker
      onToggleClick={() => {
        if (openPanel === 3) {
          setOpenPanel(0);
        } else {
          setOpenPanel(3);
        }
      }}
      items={filteredLocations}
      isOpen={openPanel === 3}
      onSelect={(item) => {
        setOpenPanel(0);
        setSearchParams((prev) => {
          prev.set('levelId', item.id);
          return prev;
        });
      }}
      displayByline={levelDisplayLine ?? 'Select Level'}
    />
  );
}
