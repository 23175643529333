import { gql, useQuery } from '@apollo/client';
import { useApolloContext } from 'contexts';
import {
  UseLocationLevelsQuery,
  UseLocationLevelsQueryVariables,
} from 'generated';

const GET_LOCATION_LEVELS_QUERY = gql`
  query useLocationLevels($locationId: ID!) {
    getLocationById(id: $locationId) {
      id
      levels {
        id
        name
        mapIsAvailable
      }
    }
  }
`;

export const useLocationLevels = (locationId: string | null | undefined) => {
  const { tenantId } = useApolloContext();
  return useQuery<UseLocationLevelsQuery, UseLocationLevelsQueryVariables>(
    GET_LOCATION_LEVELS_QUERY,
    {
      variables: {
        locationId: locationId ?? '',
      },
      skip: !tenantId || !locationId,
      fetchPolicy: 'cache-first',
      nextFetchPolicy: 'cache-first',
    }
  );
};
