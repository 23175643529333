import styled from '@emotion/styled';
import { FC, useContext, useMemo, useRef } from 'react';
import { MenuContext } from 'react-flexible-sliding-menu';
import { useRecoilState } from 'recoil';
import { useClickAway } from 'react-use';
import { useMapComponentLoadState } from 'hooks';

export const MapComponentMenu: FC = () => {
  const menuRef = useRef(null);
  const { closeMenu } = useContext(MenuContext) as { closeMenu: () => void };
  const [mapState, setMapState] = useRecoilState(
    useMapComponentLoadState('test-map')
  );
  useClickAway(menuRef, closeMenu);

  const menuItems = useMemo(() => {
    const result = [];
    let k: keyof typeof mapState;
    for (k in mapState) {
      const keyStep = k;
      const mapNugget = mapState[k];
      if (mapNugget.type === 'toggle') {
        const item = (
          <div className="menu-row" key={keyStep}>
            <label>
              {' '}
              {mapNugget.name}{' '}
              <input
                type="checkbox"
                id={keyStep}
                checked={mapNugget.toggleState}
                onChange={() => {
                  const mapStateCopy = JSON.parse(JSON.stringify(mapState));
                  mapStateCopy[keyStep].toggleState =
                    !mapStateCopy[keyStep].toggleState;
                  setMapState(mapStateCopy);
                }}
              />{' '}
            </label>
          </div>
        );
        result.push(item);
      }
    }
    return result;
  }, [mapState]);

  return (
    <MenuStyles ref={menuRef}>
      <h1>Map Component Menu</h1>
      <form>{menuItems}</form>
      <button onClick={closeMenu}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
          <path d="M0 0h24v24H0z" fill="none" />
        </svg>
      </button>
    </MenuStyles>
  );
};

const MenuStyles = styled.div`
  padding: 1rem;
  position: relative;
  color: var(--light-text-color);
  height: 100%;
  cursor: arrow;
  button {
    background: none;
    border: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
    fill: var(--light-placeholder-color);
    transition: var(--transition);
  }
  .menu-row {
    padding: 8px;
    cursor: arrow;
    label {
      cursor: pointer;
    }
    input {
      cursor: pointer;
    }
  }
`;
