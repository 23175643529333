import React, { FC, useMemo } from 'react';
import styled from '@emotion/styled/macro';
import { Dropdown } from './Dropdown';
import MoreIcon from '@robinpowered/icons/v2/MoreSolid';

import { Colors } from '@robinpowered/design-system';
import { UtilityButton } from '@robinpowered/design-system';
import { useTranslation } from 'react-i18next';
import { withTooltip } from '../common';
import { useDeskPopupContext } from './deskPopupContext';
import { DropdownItem } from './DropdownItem';
import {
  ReservationLinkButton,
  hasReservationStarted,
  isSeriesInstance,
} from '.';
import { BookingPolicyType, DeskReservationVisibility } from 'generated';

export const ActionDropdown: FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { t }: any = useTranslation<any>('map');
  const {
    userCanDelegateSeat: canDelegate,
    reservation,
    assignedReservation,
    hasReverseHotelAccess,
    isCurrentUsersAssignedReservation,
    isCurrentUsersReservation,
    canReverseHotel,
    canManageSeat,
    hasEncapsulatingExclusion,
    isSeatReservable,
    isAtCapacity,
    didRequestToAssign,
    isConfirmedNoReservation,
    deskAllowsIssueReports,
    deskState,
    openLayout,
    onCancelReservation,
    onUnassignReservation,
    openReverseHotelModal,
    openDeskIssueReportingForm,
  } = useDeskPopupContext();

  const disableReservations = false;
  const isRedirectFFEnabled = false;

  const reservationButtonText = useMemo(() => {
    const deskType = deskState?.data?.getDeskById?.type;
    const timezone = deskState?.data?.getDeskById?.level?.timezone;
    if (!deskType || !timezone || !reservation) {
      return '';
    }

    if (deskType === BookingPolicyType.Assigned) {
      return t('unassign');
    } else if (hasReservationStarted(reservation, timezone)) {
      t('end_reservation');
    } else {
      t('cancel_reservation');
    }
  }, [deskState, reservation]);

  // Button for sharing the desk (reverse hotel)
  const showShareDeskButton =
    hasReverseHotelAccess &&
    (canReverseHotel ||
      // Check if the assigned reservation belongs to the current user
      (isCurrentUsersAssignedReservation &&
        // Check if seat has an exclusion or if it is reverse hotel booked
        (hasEncapsulatingExclusion ||
          reservation?.id !== assignedReservation?.id)));

  // Determines if the reservation is a instance of a recurring reservation
  const seriesInstance = isSeriesInstance(reservation);
  // Checks if the reservation type is assigned
  const isAssignedReservation = reservation
    ? reservation.type === 'assigned'
    : null;
  // Checks if the reservation type is reverse hotel
  const isReverseHotelReservation = reservation
    ? reservation.type === 'reverse_hotel'
    : null;

  const redirectFFEnabled = isRedirectFFEnabled;

  // Button for unassigning a desk (admins)
  const showAdminUnassignButton =
    canDelegate && assignedReservation && redirectFFEnabled;

  const showAdminReverseHotelBookingButton =
    canDelegate &&
    hasEncapsulatingExclusion &&
    isSeatReservable &&
    !isAtCapacity &&
    !didRequestToAssign &&
    !disableReservations &&
    !isReverseHotelReservation;

  const showUserReverseHotelBookingButton =
    hasEncapsulatingExclusion &&
    isSeatReservable &&
    !isAtCapacity &&
    !canDelegate &&
    !isReverseHotelReservation;

  const showCopyURLButton =
    reservation &&
    canDelegate &&
    !isConfirmedNoReservation &&
    (isCurrentUsersReservation ||
      DeskReservationVisibility.Everyone === reservation.visibility) &&
    !showAdminReverseHotelBookingButton &&
    !showUserReverseHotelBookingButton &&
    redirectFFEnabled;

  // Buttons for unreserving/unassigning (admins/users)
  // exclude admin only if reservation is assigned
  const showUnbookButtons =
    (canDelegate ||
      (isCurrentUsersReservation && isSeatReservable && !isAtCapacity)) &&
    reservation &&
    !disableReservations &&
    !hasEncapsulatingExclusion &&
    (!seriesInstance || isAssignedReservation) &&
    !(canDelegate && isAssignedReservation);

  // Determines if action dropdown menu should be shown
  const shouldShowActionDropdown =
    showCopyURLButton ||
    showAdminUnassignButton ||
    showShareDeskButton ||
    canManageSeat ||
    showUnbookButtons ||
    deskAllowsIssueReports;

  if (!shouldShowActionDropdown) {
    return null;
  }

  return (
    <ActionDropdownWrapper>
      <Dropdown
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        buttonComponent={(buttonProps: any) => (
          <MoreButton
            data-cy="deskMoreActionsDropdownBtn"
            {...buttonProps}
            icon={MoreIcon}
            tooltipStyle={{ whiteSpace: 'nowrap' }}
            tooltipText={t('more_actions_tooltip')}
            aria-label={t('more_actions_tooltip')}
          />
        )}
        menuStyle={{ display: 'table', left: 'auto', right: 0 }}
      >
        <MoreDropdownList>
          {showCopyURLButton && (
            <BasicDropdownItem>
              <ReservationLinkButton
                reservationId={reservation.id}
                isInActionDropdown
              />
            </BasicDropdownItem>
          )}

          {showShareDeskButton && (
            <BasicDropdownItem onClick={openReverseHotelModal}>
              <FlexText>{t('share_desk')}</FlexText>
            </BasicDropdownItem>
          )}

          {canManageSeat && (
            <BasicDropdownItem onClick={openLayout}>
              <FlexText>{t('edit_desk')}</FlexText>
            </BasicDropdownItem>
          )}

          {deskAllowsIssueReports && (
            <BasicDropdownItem onClick={openDeskIssueReportingForm}>
              <FlexText>{t('issue_reporting_label')}</FlexText>
            </BasicDropdownItem>
          )}

          {showAdminUnassignButton && (
            <RedDropdownItem onClick={onUnassignReservation}>
              <FlexText data-cy="endReservationBtn">{t('unassign')}</FlexText>
            </RedDropdownItem>
          )}

          {showUnbookButtons && (
            <RedDropdownItem onClick={onCancelReservation}>
              <FlexText data-cy="endReservationBtn">
                {reservationButtonText}
              </FlexText>
            </RedDropdownItem>
          )}
        </MoreDropdownList>
      </Dropdown>
    </ActionDropdownWrapper>
  );
};

const BasicDropdownItem = styled(DropdownItem)`
  display: inline-block;
`;

const RedDropdownItem = styled(DropdownItem)`
  display: inline-block;
  color: ${Colors.Red110};
`;

const ActionDropdownWrapper = styled.div`
  flex: 0 1 auto;
  width: 24px;
`;

const FlexText = styled.div`
  flex: 1 1 50%;
`;

const MoreButton = withTooltip(styled(UtilityButton)`
  align-items: center;
  background-color: ${Colors.Tan30};
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: 24px;
  justify-content: center;
  outline: none;
  width: 24px;

  &:focus {
    outline: 2px solid ${Colors.Maroon100};
  }
`);

const MoreDropdownList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  white-space: nowrap;
`;
