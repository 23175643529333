import { gql, useQuery } from '@apollo/client';
import { useApolloContext } from 'contexts';
import {
  GetOrganizationLocationsQuery,
  GetOrganizationLocationsQueryVariables,
} from 'generated';
import { useMemo } from 'react';

const GET_ORGANIZATION_LOCATIONS_QUERY = gql`
  query GetOrganizationLocations($orgID: ID!) {
    getOrganizationById(id: $orgID) {
      id
      campuses {
        id
        name
      }
      locations {
        id
        name
        campus {
          id
        }
      }
    }
  }
`;

export const useOrganizationLocations = () => {
  const { tenantId } = useApolloContext();
  const result = useQuery<
    GetOrganizationLocationsQuery,
    GetOrganizationLocationsQueryVariables
  >(GET_ORGANIZATION_LOCATIONS_QUERY, {
    variables: {
      orgID: tenantId ?? '',
    },
    skip: !tenantId,
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-first',
  });

  const state = useMemo(() => {
    const campusIdGroup: {
      [key: string]: { locations: { id: string; name: string }[] };
    } = { 'no-campus': { locations: [] } };

    result.data?.getOrganizationById?.locations.forEach((l) => {
      let groupId = 'no-campus';
      if (l.campus?.id) {
        groupId = l.campus.id;
      }
      if (!campusIdGroup[groupId]?.locations) {
        campusIdGroup[groupId] = { locations: [] };
      }
      campusIdGroup[groupId].locations.push({ id: l.id, name: l.name });
    });

    return {
      hasCampuses: (result.data?.getOrganizationById?.campuses.length ?? 0) > 0,
      locations: result.data?.getOrganizationById?.locations ?? [],
      campuses: result.data?.getOrganizationById?.campuses
        ? [
            ...result.data.getOrganizationById.campuses,
            { id: '-1', name: 'No Campus' },
          ]
        : [],
      campusIdGroup,
    };
  }, [result]);

  return {
    loading: result.loading,
    error: result.error,
    ...state,
  };
};
