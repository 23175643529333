import { gql, useQuery } from '@apollo/client';
import { UsePeopleSearchQuery, UsePeopleSearchQueryVariables } from 'generated';
import { getDistinctObjectsByProperty } from 'utilities/Arrays';

export type PersonWithReservation = {
  id: string | undefined;
  name: string;
  avatarSrc: string | null | undefined;
  seatId: string;
};

const query = gql`
  query usePeopleSearch($levelId: ID!, $startTime: Date!, $endTime: Date!) {
    getDeskReservationsByLevelId(
      levelId: $levelId
      startTime: $startTime
      endTime: $endTime
    ) {
      levelId
      reservations {
        accountReservee {
          email
          visitorId
          user {
            id
            name
            avatar
          }
        }
        seat {
          id
        }
      }
    }
  }
`;

export const usePeopleWithReservations = ({
  levelId,
  startTime,
  endTime,
}: {
  levelId: string | null | undefined;
  startTime: string;
  endTime: string;
}) => {
  const { loading, data } = useQuery<
    UsePeopleSearchQuery,
    UsePeopleSearchQueryVariables
  >(query, {
    skip: !levelId,
    variables: {
      levelId: levelId || '-1',
      startTime,
      endTime,
    },
  });

  const allPeopleWithReservations: PersonWithReservation[] =
    data?.getDeskReservationsByLevelId.reservations.map((r) => ({
      id: r.accountReservee?.user?.id,
      name: r.accountReservee?.user?.name || r.accountReservee?.email || '',
      avatarSrc: r.accountReservee?.user?.avatar,
      seatId: r.seat.id,
    })) || [];

  const uniquePeople = getDistinctObjectsByProperty(
    allPeopleWithReservations,
    'name'
  ).sort((a, b) => a.name?.localeCompare(b.name));

  return {
    loading,
    peopleWithReservations: uniquePeople,
  };
};
