import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Colors } from '@robinpowered/design-system';
import { Body06 } from '@robinpowered/design-system';
import { SpaceItem } from './SpaceItem';
import { ActivityCard } from './ActivityCard';
import { useTranslation } from 'react-i18next';

export const AddSpaceCard: FC = () => {
  const { t } = useTranslation('downloadMobileAppModal');
  return (
    <Card>
      <ContentWrapper>
        <CardHeading>{t('add_space_card_title')}</CardHeading>
        <SpaceItem name={t('space_item_name_1')} />
        <SpaceItem name={t('space_item_name_2')} />
      </ContentWrapper>
      <ActivityCard />
    </Card>
  );
};

const CardHeading = styled(Body06)`
  margin-bottom: 20px;
  font-weight: 500;
`;

const Card = styled.div`
  border: 1px solid ${Colors.Gray30};
  border-radius: 8px;
  padding: 11px;
  max-width: fit-content;
  height: 190px;
  margin-left: -26px;
  background-color: white;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
`;

const ContentWrapper = styled.div`
  margin-bottom: -112px;
`;
