import moment, { Moment } from 'moment';

// Builds a date range to be an input for the GraphQL query `getWhosInDataByLocationByDay`
export const buildDatesArray = (
  weekStartDate: Moment,
  dayCount = 7,
  hideWeekends?: boolean
): Moment[] => {
  const weekArray = [...Array(dayCount)]
    .map((d, i) => {
      return weekStartDate.clone().add(i, 'day').startOf('day');
    })
    .filter(
      (day) =>
        (hideWeekends && !(day.isoWeekday() === 6 || day.isoWeekday() === 7)) ||
        !hideWeekends
    );
  return weekArray;
};

export const buildDatesArrayFromRange = (
  start: Moment,
  end: Moment
): Moment[] => {
  const dates = [];

  let current = start;
  while (current.isSameOrBefore(end, 'date')) {
    dates.push(current);
    current = current.clone().add(1, 'day');
  }
  return dates;
};

export const isToday = (date: Moment): boolean => {
  return date.isSame(moment(), 'day');
};

export const isBeforeToday = (date: Moment): boolean => {
  return date.isBefore(moment(), 'day');
};

export const toISOStringWithoutMilliseconds = (date: Moment): string => {
  return date.toISOString().slice(0, -5) + 'Z';
};

export const getStartDate = (userWeekStartSetting: number) => {
  const today = moment().startOf('day');

  return today.isoWeekday() >= userWeekStartSetting
    ? moment().isoWeekday(userWeekStartSetting).startOf('day')
    : moment().startOf('week').isoWeekday(userWeekStartSetting).startOf('day');
};

export const summarizeDate = (
  date: string,
  defaultDateFormat = 'MMM DD'
): string => {
  moment.relativeTimeThreshold('s', 0);
  moment.relativeTimeThreshold('d', 30);

  const now = moment();
  const diffFromNow = now.diff(moment(date), 'days');

  let summarizedDate = moment(date).format(defaultDateFormat);

  if (diffFromNow < 29 || diffFromNow > 29) {
    summarizedDate = moment(date).fromNow();
  }

  return summarizedDate;
};
