import { SpinnerLoader } from '@robinpowered/design-system';
import React, { FC, useMemo } from 'react';
import { useDeskPopupContext } from './deskPopupContext';

export const DeskPopupLoadingState: FC<{
  children: null | React.ReactNode;
}> = ({ children }) => {
  const { deskState } = useDeskPopupContext();

  const loading = useMemo(() => {
    return !!(
      deskState &&
      deskState.loading &&
      !deskState.data?.getDeskById?.name
    );
  }, [deskState]);

  if (loading) {
    return <SpinnerLoader />;
  }

  return <>{children}</>;
};
