export const removeMarkdownAnnotations = (text: string) => {
  const funcs = [
    removeMarkdownLinkSyntax,
    removeMarkdownBlockSyntax,
    removeMarkdownInlineSyntax,
    removeLineBreaks,
  ];

  let result = text;

  funcs.forEach((func) => {
    result = func(result);
  });

  return result;
};

const removeMarkdownLinkSyntax = (text: string): string =>
  text.replace(/\[(.+)\]\((.+)\)/, (_a, linkText) => linkText);

const removeLineBreaks = (text: string): string =>
  text.replace(/[\t\n]+/g, ' ');

const removeMarkdownInlineSyntax = (text: string): string =>
  text.replace(/[*_]+/g, '');

const removeMarkdownBlockSyntax = (text: string): string =>
  text.replace(/^\W*[*-+#=]+/g, '');
