import { UseSeatDetailsQuery } from 'generated';

// This source file contains direct ports of dashboard angular business logic.
// This should be purged in favor of centralized assertions of state contained within backend systems.

type SeatType = UseSeatDetailsQuery['getDeskById'];
type SeatReservationType = Exclude<
  SeatType,
  null | undefined
>['state']['reservations'][0];

export const isSeriesInstance = (
  reservation: SeatReservationType | null | undefined
) => !!(reservation?.seriesId ?? false);
