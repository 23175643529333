import { css } from '@emotion/react';
import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Manager, Reference, Popper } from 'react-popper';
import {
  BrownFontStack,
  Colors,
  Spacing,
  Type,
} from '@robinpowered/design-system';

// Time for the tooltip to appear, in milliseconds.
const DEFAULT_TOOLTIP_DELAY = 200;

// @TODO finalize style
const Tooltip = styled.div(() => ({
  backgroundColor: Colors.Gray100,
  color: Colors.White0,
  fontSize: Type.Size.Small,
  fontWeight: Type.Weight.Medium,
  fontFamily: BrownFontStack,
  padding: `${Spacing[1]}px ${Spacing[2]}px`,
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)', // @TODO use a global style
  borderRadius: Spacing[0], // @TODO use a global style
  pointerEvents: 'none',
  userSelect: 'none',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  width: 'auto',
  height: 'auto',
  lineHeight: '17px',
}));

export class TooltipProvider extends React.Component {
  static propTypes = {
    children: PropTypes.any.isRequired,
    defaultPlacement: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
    delayms: PropTypes.number,
    showArrow: PropTypes.bool,
    tooltipStyle: PropTypes.object,
    tooltipText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    tooltipDisabled: PropTypes.bool,
  };

  static defaultProps = {
    defaultPlacement: 'top',
    delayms: DEFAULT_TOOLTIP_DELAY,
    showArrow: true,
    tooltipDisabled: false,
  };

  state = {
    showTooltip: false,
  };

  componentWillUnmount() {
    // eslint-disable-next-line no-undef
    clearTimeout(this.hoverTimer);
  }

  beginHoverTooltip = () => {
    if (this.props.tooltipDisabled || !this.props.tooltipText) {
      return;
    }
    // Queue a setTimeout since we only want to show the tooltip after `props.delayms` milliseconds.
    // This helps avoid users triggering the tooltip unintentionally when moving around their mouse.
    // eslint-disable-next-line no-undef
    this.hoverTimer = setTimeout(
      () => this.setState({ showTooltip: true }),
      this.props.delayms
    );
  };

  removeAndCancelTooltip = () => {
    // eslint-disable-next-line no-undef
    clearTimeout(this.hoverTimer);
    if (this.state.showTooltip) {
      this.setState({ showTooltip: false });
    }
  };

  getMouseEvents = ({
    onPointerEnter,
    onPointerLeave,
    onMouseDown,
    ...props
  }) => {
    return {
      onPointerEnter: (event) => {
        this.beginHoverTooltip();
        // Call the original mouse event if there is one.
        if (onPointerEnter) {
          onPointerEnter(event);
        }
      },
      onPointerLeave: (event) => {
        this.removeAndCancelTooltip();
        // Call the original mouse event if there is one.
        if (onPointerLeave) {
          onPointerLeave(event);
        }
      },
      onMouseDown: (event) => {
        this.removeAndCancelTooltip();
        // Call the original mouse event if there is one.
        if (onMouseDown) {
          onMouseDown(event);
        }
      },
      ...props,
    };
  };

  render() {
    const { defaultPlacement, showArrow, tooltipStyle, tooltipText, ...props } =
      this.props;

    return (
      <div
        css={css({
          position: 'relative',
          display: 'inline-block',
        })}
        {...props}
      >
        <Manager>
          <Reference>
            {({ ref }) => (
              <div ref={ref}>
                {this.props.children({
                  getMouseEvents: this.getMouseEvents,
                })}
              </div>
            )}
          </Reference>
          {this.state.showTooltip && (
            <Popper placement={defaultPlacement}>
              {({ arrowProps, placement, ref, style }) => (
                <Popover
                  arrowProps={arrowProps}
                  placement={placement}
                  ref={ref}
                  style={style}
                  tooltipStyle={tooltipStyle}
                  tooltipText={tooltipText}
                  showArrow={showArrow}
                ></Popover>
              )}
            </Popper>
          )}
        </Manager>
      </div>
    );
  }
}

export const withTooltip =
  (WrappedComponent) =>
  ({
    defaultPlacement,
    delayms,
    tooltipStyle = {},
    tooltipText,
    tooltipDisabled,
    showArrow = true,
    ...props
  }) =>
    (
      <TooltipProvider
        defaultPlacement={defaultPlacement}
        delayms={delayms}
        tooltipStyle={tooltipStyle}
        tooltipText={tooltipText}
        tooltipDisabled={tooltipDisabled}
        showArrow={showArrow}
      >
        {({ getMouseEvents }) => (
          <WrappedComponent {...getMouseEvents(props)} />
        )}
      </TooltipProvider>
    );

export const Popover = React.forwardRef(
  (
    { arrowProps, placement, style, tooltipStyle, tooltipText, showArrow },
    ref
  ) => {
    return (
      <div
        css={css(
          {
            position: 'relative',
            zIndex: 999, // @TODO we should define a global zIndex hierarchy
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 'auto',
            height: 'auto',
            padding: `${Spacing[1]}px ${Spacing[2]}px`,
            fontFamily: BrownFontStack,
            fontSize: Type.Size.Small,
            fontWeight: Type.Weight.Medium,
            color: Colors.White0,
            lineHeight: '16px',
            textAlign: 'center',
            pointerEvents: 'none',
            backgroundColor: Colors.Gray100,
            borderRadius: Spacing[0], // @TODO use a global style
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)', // @TODO use a global style
            userSelect: 'none',
          },
          (placement === 'top' && {
            marginBottom: Spacing[1],
          }) ||
            (placement === 'right' && {
              marginLeft: Spacing[1],
            }) ||
            (placement === 'bottom' && {
              marginTop: Spacing[1],
            }) ||
            (placement === 'left' && {
              marginRight: Spacing[1],
            })
        )}
        data-placement={placement}
        placement={placement}
        ref={ref}
        style={{
          ...style,
          ...tooltipStyle,
        }}
      >
        {tooltipText}
        {showArrow && (
          <div
            css={css(
              {
                position: 'absolute',
                width: 0,
                height: 0,
                pointerEvents: 'none',
                border: '4px solid transparent',
                borderBottomColor: Colors.Gray100,
                content: '" "',
              },
              (placement === 'top' && {
                bottom: -Spacing[1],
                left: 'calc(50% - 2px)',
                transform: 'rotate(180deg)',
              }) ||
                (placement === 'right' && {
                  top: 'calc(50% - 2px)',
                  left: -Spacing[1],
                  transform: 'rotate(270deg)',
                }) ||
                (placement === 'bottom' && {
                  top: -Spacing[1],
                  left: 'calc(50% - 2px)',
                }) ||
                (placement === 'left' && {
                  top: 'calc(50% - 2px)',
                  right: -Spacing[1],
                  transform: 'rotate(90deg)',
                })
            )}
            placement={placement}
            ref={arrowProps.ref}
            style={arrowProps.style}
          />
        )}
      </div>
    );
  }
);

export default Tooltip;
