import { MapRef } from 'react-map-gl';

/**
 * Camera scroll and zoom to target map resource
 * @param map Reference to map resource
 * @param resourceLayer layer that contains the target resource ex "spaces" or "seats"
 * @param resourceId robin identity of the target resource
 * @param zoomTo Optional zoom level after camera scroll
 * @returns 0 if successful, -1 if error
 */
export function flyToFeature(
  map: MapRef,
  resourceLayer: string,
  resourceId: string | number,
  zoomTo?: number
): number {
  const features = map.querySourceFeatures(resourceLayer, {
    sourceLayer: resourceLayer,
  });
  if (!features || features.length < 1) {
    return -1;
  }

  const specifiedFeature = features.find(
    (f) =>
      `${f.properties?.ownerId}` === `${resourceId}` &&
      f.type === 'Feature' &&
      f.properties?.ownerType === resourceLayer
  );
  if (!specifiedFeature) {
    return -1;
  }

  const hiddenCoordinates = (
    specifiedFeature.geometry as unknown as {
      coordinates?: number[][][] | undefined;
    }
  ).coordinates;

  if (
    !hiddenCoordinates ||
    hiddenCoordinates.length < 1 ||
    hiddenCoordinates[0].length < 1
  ) {
    return -1;
  }

  const superSecretCoordinates = [...hiddenCoordinates[0]];
  const geometrySetLength = superSecretCoordinates.length;

  let lat = 0;
  let lon = 0;

  superSecretCoordinates.forEach((c) => {
    lon += c[0] ?? 0;
    lat += c[1] ?? 0;
  });

  lat = lat / geometrySetLength;
  lon = lon / geometrySetLength;

  map.flyTo({ center: { lat, lon }, zoom: zoomTo });
  return 0;
}
