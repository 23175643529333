import moment, { Moment } from 'moment';
import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

type SelectedDateType = {
  selectedDate: Moment | Moment[];
  safeSelectedDate: Moment;
  setSelectedDate: (sd: Moment | Moment[]) => void;
  setMultiDateMode: (enabled: boolean) => void;
};

export function useSelectedDate(): SelectedDateType {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchTermsDate = searchParams.get('dt');
  const searchTermsMultiDate = searchParams.get('mdt');

  const selectedDate = useMemo(() => {
    const date = searchTermsDate;
    if (date) {
      return moment(date);
    } else if (searchTermsMultiDate) {
      try {
        const data: string[] = JSON.parse(searchTermsMultiDate);
        return data.map((d) => moment(d));
      } catch {
        return [moment()];
      }
    }
    return moment();
  }, [searchTermsDate, searchTermsMultiDate]);

  const safeSelectedDate = useMemo(() => {
    if (Array.isArray(selectedDate)) {
      return selectedDate[0] ? moment(selectedDate[0]) : moment();
    }
    return moment(selectedDate);
  }, [selectedDate]);

  const setSelectedDate = useCallback(
    (sd: Moment | Moment[]) => {
      if (Array.isArray(sd)) {
        setSearchParams((prev) => {
          prev.set('mdt', JSON.stringify(sd.map((d) => d.clone().format('L'))));
          return prev;
        });
      } else {
        setSearchParams((prev) => {
          prev.set('dt', sd.clone().format('L'));
          return prev;
        });
      }
    },
    [setSearchParams, searchParams]
  );

  const setMultiDateMode = useCallback(
    (enable: boolean) => {
      if (enable) {
        setSearchParams((prev) => {
          if (prev.has('dt')) {
            prev.delete('dt');
          }
          if (Array.isArray(selectedDate)) {
            prev.set('mdt', `["${moment().format('L')}"]`);
          } else {
            prev.set('mdt', `["${selectedDate.clone().format('L')}"]`);
          }

          return prev;
        });
      } else {
        setSearchParams((prev) => {
          if (prev.has('mdt')) {
            prev.delete('mdt');
          }
          if (Array.isArray(selectedDate) && selectedDate[0]) {
            prev.set('dt', selectedDate[0].clone().format('L'));
          } else {
            prev.set('dt', moment().format('L'));
          }

          return prev;
        });
      }
    },
    [setSearchParams]
  );

  return { selectedDate, safeSelectedDate, setSelectedDate, setMultiDateMode };
}
