import styled from '@emotion/styled';
import { AvatarStack, Body } from '@robinpowered/design-system';
import SearchOutline from '@robinpowered/icons/SearchOutline';
import { PeoplePillLoading } from './PeoplePillLoading';
import { FC } from 'react';
import { PersonWithReservation } from 'hooks';

export const PeoplePillButton: FC<{
  loading: boolean;
  peopleWithReservations: PersonWithReservation[];
  label: string;
  onClick: () => void;
}> = ({ loading, peopleWithReservations, label, onClick }) => {
  return (
    <Button onClick={onClick}>
      <SearchOutline size={16} />

      <Body.Small>{label}</Body.Small>
      {loading ? (
        <PeoplePillLoading />
      ) : (
        <>
          <AvatarStack
            avatars={peopleWithReservations.slice(0, 3).map((p) => ({
              name: p.name,
              src: p.avatarSrc || undefined,
              size: 24,
            }))}
          />
          <Body.Small>{peopleWithReservations.length}</Body.Small>
        </>
      )}
    </Button>
  );
};

export const Button = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: unset;
  border: none;
  cursor: pointer;
  padding: 0;
`;
