import { atomFamily } from 'recoil';

type MenuToggleType = { name: string; type: 'toggle'; toggleState: boolean };

type ComponentStateType = {
  nav: MenuToggleType;
  avatar: MenuToggleType;
  yourDesk: MenuToggleType;
  yourDeskPopup: MenuToggleType;
  seatState: MenuToggleType;
  spaceState: MenuToggleType;
  layerBackground: MenuToggleType;
  layerFloorplan: MenuToggleType;
  layerFurnishings: MenuToggleType;
  layerDemoWatermark: MenuToggleType;
  layerSpaceFills: MenuToggleType;
  layerSpaces: MenuToggleType;
  layerSeats: MenuToggleType;
  layerSpaceLabels: MenuToggleType;
  layerSeatLabels: MenuToggleType;
  layerZoneLabels: MenuToggleType;
  layerSeatAvatars: MenuToggleType;
  layerMarkers: MenuToggleType;
  layerStatusBoards: MenuToggleType;
  layerSeatMarkers: MenuToggleType;
  clickHandlerSeats: MenuToggleType;
};

export const useMapComponentLoadState = atomFamily<ComponentStateType, string>({
  key: `mapComponentLoadMenuState`,
  default: {
    spaceState: {
      name: 'Space availbility',
      type: 'toggle',
      toggleState: true,
    },
    seatState: {
      name: 'Seat availibility',
      type: 'toggle',
      toggleState: true,
    },
    nav: {
      name: 'Navigation',
      type: 'toggle',
      toggleState: true,
    },
    avatar: {
      name: 'Desk Avatar',
      type: 'toggle',
      toggleState: true,
    },
    yourDesk: {
      name: 'Your desk icon',
      type: 'toggle',
      toggleState: true,
    },
    yourDeskPopup: {
      name: 'Your desk popup',
      type: 'toggle',
      toggleState: false,
    },
    layerBackground: {
      name: 'Background map layer',
      type: 'toggle',
      toggleState: true,
    },
    layerDemoWatermark: {
      name: 'Watermark map layer',
      type: 'toggle',
      toggleState: true,
    },
    layerFloorplan: {
      name: 'Floor plan map layer',
      type: 'toggle',
      toggleState: true,
    },
    layerFurnishings: {
      name: 'Furnishings map layer',
      type: 'toggle',
      toggleState: true,
    },
    layerMarkers: {
      name: 'Markers map layer',
      type: 'toggle',
      toggleState: true,
    },
    layerSeatAvatars: {
      name: 'Seat avatar map layer',
      type: 'toggle',
      toggleState: true,
    },
    layerSeatLabels: {
      name: 'Seat label map layer',
      type: 'toggle',
      toggleState: true,
    },
    layerSeatMarkers: {
      name: 'Seat marker map layer',
      type: 'toggle',
      toggleState: true,
    },
    layerSeats: {
      name: 'Seats map layer',
      type: 'toggle',
      toggleState: true,
    },
    layerSpaceFills: {
      name: 'Space fills map layer',
      type: 'toggle',
      toggleState: true,
    },
    layerSpaceLabels: {
      name: 'Space labels map layer',
      type: 'toggle',
      toggleState: true,
    },
    layerSpaces: {
      name: 'Spaces map layer',
      type: 'toggle',
      toggleState: true,
    },
    layerStatusBoards: {
      name: 'Statusboard map layer',
      type: 'toggle',
      toggleState: true,
    },
    layerZoneLabels: {
      name: 'Zone labels map layer',
      type: 'toggle',
      toggleState: true,
    },
    clickHandlerSeats: {
      name: 'Seat Clicks',
      type: 'toggle',
      toggleState: true,
    },
  },
});
