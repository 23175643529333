import styled from '@emotion/styled';
import { Avatar, Body, Colors } from '@robinpowered/design-system';
import { FC } from 'react';
import { removeMarkdownAnnotations } from 'utilities/Markdown';

export const AnnouncementPreview: FC<{
  name: string;
  content: string;
  authorName: string | null | undefined;
  authorAvatar: string | null | undefined;
  readMoreLabel: string;
  onClick: () => void;
}> = ({ name, content, authorName, authorAvatar, readMoreLabel, onClick }) => {
  return (
    <PreviewButton onClick={onClick}>
      <Avatar size={24} name={authorName || ''} src={authorAvatar || ''} />

      <Container>
        <AnnouncementName>{name}</AnnouncementName>
        <Content>
          <TruncatedText>{removeMarkdownAnnotations(content)}</TruncatedText>
          <ReadMoreText>{readMoreLabel}</ReadMoreText>
        </Content>
      </Container>
    </PreviewButton>
  );
};

const PreviewButton = styled.button`
  width: 100%;
  display: block;
  background-color: ${Colors.Tan5};
  border: none;
  border-radius: 8px;
  padding: 8px;
  margin-top: 8px;

  display: flex;
  cursor: pointer;
`;

const Container = styled.div`
  min-width: 0; // This is a hacky fix to prevent truncated text from breaking flexbox
  width: calc(100% - 24px);
  padding-left: 8px;
  text-align: left;
`;

const AnnouncementName = styled(Body.Small)`
  display: block;
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TruncatedText = styled(AnnouncementName)`
  width: 70%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ReadMoreText = styled(Body.Small)`
  text-decoration: underline;
`;
