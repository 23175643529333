import React, { FC, Fragment } from 'react';
import styled from '@emotion/styled';
import {
  BrownFontStack,
  Checkbox,
  Colors,
  CoreFontSize,
} from '@robinpowered/design-system';

import Checkmark from '@robinpowered/icons/v2/Checkmark';

import { ReservationButton } from './ReservationButton';
import { VisibilityDropdown } from './VisibilityDropdown';
import { useTranslation } from 'react-i18next';
import { useDeskPopupContext } from './deskPopupContext';
import { BookingPolicyType } from 'generated';
import { InviteeSearch } from './InviteeSearch';
import InlineLoader from './InlineLoader';

const notificationEmailPreferenceCheckboxId =
  'notification-email-preference-checkbox';

export const InviteeSelection: FC = () => {
  const {
    setLocalVisibility,
    invitee,
    setInvitee,
    deskState,
    isReservationVisibilityEnabled,
    onCreateReservation,
    loading,
    sendNotification,
    setSendNotification,
    localVisibility,
  } = useDeskPopupContext();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { t }: any = useTranslation('map');
  const confirmRef = React.useRef(null);

  // The type of allowed reservations on the seat (ie: hot, hoteled, assigned)
  const seatReservationType = deskState?.data?.getDeskById?.type;

  React.useEffect(() => {
    if (
      invitee &&
      confirmRef?.current &&
      (confirmRef.current as { focus?: () => void | null | undefined })?.focus
    ) {
      (confirmRef.current as { focus: () => void }).focus();
    }
  }, [invitee, confirmRef?.current]);

  const CheckboxLabelStyles = {
    color: Colors.Gray80,
    marginRight: '5px',
    fontFamily: BrownFontStack,
    fontSize: `${CoreFontSize.s12}`,
    lineHeight: `18px`,
  };

  const shouldShowVisibilityDropdown =
    isReservationVisibilityEnabled &&
    seatReservationType !== BookingPolicyType.Assigned;

  return (
    <Fragment>
      <SearchWrapperInner>
        <InviteeSearchWrapper active={invitee}>
          <InviteeSearch
            invitee={invitee}
            onClear={() => {
              setInvitee(null);
            }}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onKeywordChange={() => {}}
            onSelect={setInvitee}
          />
        </InviteeSearchWrapper>
        {invitee && (
          <CheckButtonWrapper>
            <CheckButton
              disabled={loading}
              onClick={onCreateReservation}
              aria-label={t('DESK_POPOVER.CONFIRM_LABEL')}
              ref={confirmRef}
            >
              {loading ? (
                <InlineLoader />
              ) : (
                <Checkmark color={Colors.White0} size={20} />
              )}
            </CheckButton>
          </CheckButtonWrapper>
        )}
      </SearchWrapperInner>
      {invitee && (
        <Row>
          <CheckboxWrapper>
            <Checkbox
              id={notificationEmailPreferenceCheckboxId}
              checked={sendNotification}
              label={t('SEATS.RESERVATIONS.SEND_NOTIFICATION_EMAIL')}
              labelStyles={CheckboxLabelStyles}
              onChange={() => setSendNotification(!sendNotification)}
            />
          </CheckboxWrapper>
          {shouldShowVisibilityDropdown && (
            <VisibilityDropdown
              selectedVisibility={localVisibility}
              onUpdateVisibility={setLocalVisibility}
            />
          )}
        </Row>
      )}
    </Fragment>
  );
};

const CheckboxWrapper = styled.div`
  #${notificationEmailPreferenceCheckboxId}:focus-visible {
    outline: auto ${Colors.Maroon100};
    outline-offset: 3px;
  }
`;

const CheckButton = styled(ReservationButton)<{
  ref: React.MutableRefObject<null>;
}>`
  align-items: center;
  display: flex;
  height: 32px;
  justify-content: center;
  min-width: auto;
  padding: 0;
  width: 32px;
`;

const CheckButtonWrapper = styled.div`
  display: inline-block;
  margin-left: 8px;
`;

const InviteeSearchWrapper = styled.div<{ active: boolean }>(
  ({ active }) => `
display: inline-block;
width: ${active ? 'calc(100% - 48px)' : '100%'};
`
);

const SearchWrapperInner = styled.div`
  align-items: center;
  display: flex;

  padding: 8px 12px;
`;

const Row = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 8px 12px;
`;
