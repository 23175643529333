import styled from '@emotion/styled';
import { Skeleton } from '@robinpowered/design-system';

export const AnnouncementLoading = styled(Skeleton)`
  height: 122px;
  width: 100%;
  border-radius: 16px;
  margin: 16px 0;

  &:first-of-type {
    margin-top: 0;
  }
`;
