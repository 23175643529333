import { QueryResult, gql, useQuery } from '@apollo/client';
import {
  GroupingMapSpaceStateQuery,
  GroupingMapSpaceStateQueryVariables,
} from 'generated';
import { MapInfoContext } from '../state';
import { useContext } from 'react';

const USE_SPACE_STATE_QUERY = gql`
  query GroupingMapSpaceState(
    $availableAtTime: ISODateTime
    $spaceIds: [ID!]!
  ) {
    getSpacesByIds(ids: $spaceIds) {
      id
      confirmedAvailability(time: $availableAtTime, durationInMinutes: 15)
      isDisabled
      capacity
      type
      scheduleConfiguration
    }
  }
`;

export const useSpaceStateQuery = (
  spaceIds: string[],
  availableAtTime: string
): QueryResult<
  GroupingMapSpaceStateQuery,
  GroupingMapSpaceStateQueryVariables
> => {
  const { tenantId } = useContext(MapInfoContext);
  const result = useQuery<
    GroupingMapSpaceStateQuery,
    GroupingMapSpaceStateQueryVariables
  >(USE_SPACE_STATE_QUERY, {
    skip: !tenantId,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      spaceIds,
      availableAtTime,
    },
  });

  return result;
};
