/**
 * This function returns a new array that contains the unique objects in the given array, where uniqueness is determined by the value of the given property.
 * @param {T[]} array The array of objects to filter.
 * @param {keyof T} property The property to use to determine uniqueness.
 * @returns {T[]} A new array that contains the unique objects from the given array.
 */
export const getDistinctObjectsByProperty = <T>(
  array: T[],
  property: keyof T
): T[] => {
  return [...new Map(array.map((item) => [item[property], item])).values()];
};
