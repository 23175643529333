import React from 'react';
import styled from '@emotion/styled';
import moment from 'moment-timezone';
import { ContextualHelp } from '@robinpowered/design-system';
import { OfficePolicyBanner } from './OfficePolicyBanner';
import { useTranslation } from 'react-i18next';
import { useGetMyGuideline } from 'hooks';

const OfficePolicyBannerContainer = styled.div(() => ({
  display: 'inline-flex',
  padding: '4px 8px 4px 0',
  gap: '4px',
  fontWeight: 500,
  fontSize: '14px',
  alignItems: 'center',
  alignSelf: 'end',
  marginLeft: 'auto',
}));

export const OfficeTabGuidelinesWrapper = (): JSX.Element => {
  const { t } = useTranslation();

  const { data, error } = useGetMyGuideline(moment.tz.guess());
  const guideline = data?.getMyGuidelineWithProgress;
  const guidelineMessage = guideline?.message ?? undefined;
  const guidelineProgress = guideline?.progress;
  const guidelineFrequency = guideline?.interval.frequency;
  const guidelineTooltipMessage = guideline?.tooltipMessage;

  const tooltipDefaultMessage = t(
    'The defined number of in-person office days.'
  );

  if (!guideline || error || !guidelineFrequency) return <></>;

  return (
    <OfficePolicyBannerContainer>
      <ContextualHelp
        text={guidelineTooltipMessage ?? tooltipDefaultMessage}
        variant="tooltip"
        placement="bottom"
      >
        <OfficePolicyBanner
          guidelineFrequency={guidelineFrequency}
          guidelineLabel={guidelineMessage}
          daysFulfilled={guidelineProgress}
        />
      </ContextualHelp>
    </OfficePolicyBannerContainer>
  );
};
