import React, { FC, ReactNode, useContext } from 'react';
import { useUserLocale } from 'hooks';
import { useAuthContext } from './AuthContext';

const timeFormats = {
  TWELVE_HOUR: 'h:mm a',
  TWENTY_FOUR_HOUR: 'HH:mm',
};

export type LocalizationContextType = {
  timeFormat: string;
};

const Context = React.createContext<LocalizationContextType>({
  timeFormat: timeFormats.TWELVE_HOUR,
});

export const LocalizationProvider: FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const { currentUser } = useAuthContext();
  const { use24HourTime } = useUserLocale(currentUser?.id);

  const timeFormat = use24HourTime
    ? timeFormats.TWENTY_FOUR_HOUR
    : timeFormats.TWELVE_HOUR;

  return <Context.Provider value={{ timeFormat }}>{children}</Context.Provider>;
};

export function useLocalizationContext(): LocalizationContextType {
  return useContext(Context);
}
