import React, { useState, useRef, FC, KeyboardEvent } from 'react';
import { useClickAway } from 'react-use';
import styled from '@emotion/styled';
import { Heading07, Body07, Button } from '@robinpowered/design-system';
import { Colors } from '@robinpowered/design-system';
import VisibleEye from '../../images/visible-eye.svg';
import HiddenEye from '../../images/hidden-eye.svg';
import ChevronDownArrow from '../../images/thick-chevron-down-arrow.svg';
import ChevronSideArrow from '../../images/thick-chevron-side-arrow.svg';
import { DeskReservationVisibility } from './deskPopupContext';

type VisibilityDropdownProps = {
  selectedVisibility: DeskReservationVisibility;
  onUpdateVisibility:
    | ((visibility: DeskReservationVisibility) => void)
    | undefined;
  disabled?: boolean;
};

const SizedImg = styled.img<{ size: number }>(
  ({ size }) => `
  width: ${size}px;
  height: ${size}px;
`
);

// @TODO these overrides are needed because the props passed to the component
// otherwise yield more specific CSS rules; new component instead of composing?
const buttonStylingOverrides = {
  justifyContent: 'space-between',
  borderRadius: '40px',
  backgroundColor: Colors.White0,
  padding: '8px',
};

export const VisibilityDropdown: FC<VisibilityDropdownProps> = ({
  selectedVisibility,
  onUpdateVisibility,
  disabled,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef(null);
  useClickAway(containerRef, () => setIsOpen(false));

  const matchIcon = (visibility: DeskReservationVisibility): JSX.Element => {
    return visibility === DeskReservationVisibility.EVERYONE ? (
      <SizedImg size={14} src={VisibleEye} />
    ) : (
      <SizedImg size={14} src={HiddenEye} />
    );
  };

  const handleKeyboardInteraction = (
    e: KeyboardEvent,
    value: DeskReservationVisibility
  ): void => {
    if (e.key === 'Enter') {
      setIsOpen(false);
      onUpdateVisibility && onUpdateVisibility(value);
    } else if (e.key === 'Escape') {
      setIsOpen(false);
    } else return;
  };

  // @TODO translations
  const options = [
    { name: 'Visible', value: DeskReservationVisibility.EVERYONE },
    { name: 'Hidden', value: DeskReservationVisibility.JUST_ME },
  ];

  return (
    <div ref={containerRef}>
      <RoundedButton
        variant="secondary"
        size="small"
        name="reservation visibility"
        aria-labelledby="reservation visibility"
        onClick={(): void => setIsOpen(!isOpen)}
        onKeyUp={(e: KeyboardEvent): boolean | void =>
          e.key === 'Enter' && setIsOpen(true)
        }
        onKeyDown={(e: KeyboardEvent): boolean | void =>
          e.key === 'Enter' && setIsOpen(false)
        }
        style={buttonStylingOverrides}
        disabled={disabled}
      >
        {matchIcon(selectedVisibility)}
        <Heading07 color={Colors.Gray80}>
          {/* // @TODO translations */}
          {selectedVisibility === DeskReservationVisibility.EVERYONE
            ? 'Visible'
            : 'Hidden'}
        </Heading07>
        {isOpen ? (
          <SizedImg size={8} src={ChevronDownArrow} />
        ) : (
          <SizedImg size={8} src={ChevronSideArrow} />
        )}
      </RoundedButton>
      {isOpen && (
        <DropdownContainer aria-expanded={isOpen}>
          {options.map((option, index) => (
            <DropdownRow
              key={index}
              aria-labelledby={option.name}
              tabIndex={0}
              selected={option.value === selectedVisibility}
              onClick={(): void => {
                setIsOpen(false);
                onUpdateVisibility && onUpdateVisibility(option.value);
              }}
              onKeyUp={(e: KeyboardEvent): void =>
                handleKeyboardInteraction(e, option.value)
              }
            >
              <DropdownIconWrapper>
                {matchIcon(option.value)}
              </DropdownIconWrapper>
              <Body07 color={Colors.Gray80}>{option.name}</Body07>
            </DropdownRow>
          ))}
        </DropdownContainer>
      )}
    </div>
  );
};

const DropdownIconWrapper = styled.div`
  margin: 0 8px 2px 4px;
`;

const RoundedButton = styled(Button)`
  height: 24px;
  margin-top: 8px; // @TODO margin/placement is the concern of the component's parent
  min-width: 90px;

  ${({ disabled }): string => (disabled ? 'opacity: 0.5;' : '')}
`;

const DropdownContainer = styled.div`
  align-items: center;
  background-color: ${Colors.White0};
  border: 1px solid ${Colors.Tan70};
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 60px;
  justify-content: center;
  margin-top: 4px; // @TODO margin/placement is the concern of the component's parent
  padding: 4px;
  position: absolute;
  min-width: 90px;
`;

type RowProps = {
  selected: boolean;
};

const DropdownRow = styled.div<RowProps>(
  ({ selected }) => `
  align-items: center;
  background-color: ${selected ? Colors.Tan5 : Colors.White0};
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  width: 100%;

  &:hover {
    background-color: ${Colors.Tan5};
  }

  &:focus {
    background-color: ${Colors.Tan5};
    outline: 2px solid ${Colors.Maroon100};
    z-index: 5;
  }
`
);
