import { gql, useQuery } from '@apollo/client';
import {
  UseActiveAnnouncementsQuery,
  UseActiveAnnouncementsQueryVariables,
} from 'generated';

const GET_ACTIVE_ANNOUNCEMENTS_FOR_LOCATION = gql`
  query useActiveAnnouncements($locationId: ID!) {
    getActiveAnnouncementsByLocationId(locationId: $locationId) {
      id
      name
      status
      content
      priorityStatus
      createdAt
      viewedAt
      author {
        id
        name
        avatar
      }
    }
    getCurrentUserAndOrgs {
      userId
      hasAnnouncementsManageForCurrentTenant
    }
  }
`;

export const useActiveAnnouncements = (
  locationId: string | number | null | undefined
) => {
  const { loading, data, error } = useQuery<
    UseActiveAnnouncementsQuery,
    UseActiveAnnouncementsQueryVariables
  >(GET_ACTIVE_ANNOUNCEMENTS_FOR_LOCATION, {
    skip: !locationId,
    variables: {
      locationId: locationId ?? '-1',
    },
    fetchPolicy: 'cache-and-network',
  });

  return {
    loading,
    activeAnnouncements: data?.getActiveAnnouncementsByLocationId,
    canManageAnnouncements:
      !!data?.getCurrentUserAndOrgs?.hasAnnouncementsManageForCurrentTenant,
    error,
  };
};
