import axios from 'axios';

const API_URL = process.env.REACT_APP_FRONTEND_SERVICE_API_URL ?? '';

export async function getInvitees(
  keyword: string,
  auth: string,
  orgId: string
) {
  const result = await axios.get(`${API_URL}/suggested-invitees`, {
    headers: { Authorization: auth, 'Tenant-Id': orgId },
    params: { query: keyword },
  });

  const moreResults: boolean =
    result?.data?.meta?.more_info?.more_results ?? false;

  const result1 = { invitees: result.data?.data ?? [], moreResults };
  const filteredInvitees = result1.invitees;

  /* todo: this appears to be a client side filter removing support addresses from search. I have no idea what this is.
  const filteredInvitees = stage1Result.invitees.filter(
    (invitee: any) =>
      !InviteesService.findSupportRolefromInvitee(invitee, supportAddresses)

  );*/

  /*
  // Checks if the query is an email address.
  const isEmailSearch = keyword.includes('@');
  let customInvitee, hasMatchedInvitees;

  if (isEmailSearch) {
    // Find if there are any matched invitees by the query.
    hasMatchedInvitees = !!filteredInvitees.find(
      (invitee: any) => invitee.email === keyword
    );
  }

  // If we couldn't match the search to an existing invitee and the query is probably an email (contains @),
  // or there are no current invitees, add custom invite option.
  // no idea what this is. TODO later:
  if (!hasMatchedInvitees && (isEmailSearch || filteredInvitees.length === 0)) {
    // Add currently typed invitee email as first entry
    customInvitee = InviteesService.mapToInvitee(
      {
        email: query,
        // If email is indeed email and its not current user`s email it is valid.
        email_valid:
          DashboardUtils.validateEmail(query) &&
          query !== SharedCurrentUserData.getPrimaryEmail(),
        // Let the directives know, that this is a custom-added user entry
        is_custom: true,
      },
      supportAddresses
    );
  }

  if (customInvitee) {
    filteredInvitees.unshift(customInvitee);
  }
  */

  // Add unique ID to track by.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return filteredInvitees.map((invitee: any) => {
    invitee.trackingId = `${invitee.id}-${invitee.email}`;
    return invitee;
  });
}
