import { UseSeatDetailsQuery } from 'generated';
import { isSeriesInstance } from './is-series-instance';
import axios from 'axios';

// This source file contains direct ports of dashboard angular business logic.
// This should be purged in favor of centralized assertions of state contained within backend systems.

type SeatType = UseSeatDetailsQuery['getDeskById'];
type SeatReservationType = Exclude<
  SeatType,
  null | undefined
>['state']['reservations'][0];

export const cancelReservation = (
  seat: SeatType | null | undefined,
  reservation: SeatReservationType | null | undefined
) => {
  if (!seat || !reservation) {
    return;
  }
  const isAssignedInstance =
    reservation.type === 'assigned' && !!reservation.seriesId;
  let filteredReservations: SeatReservationType[] | undefined;
  // If unassigning via an instance of an assigned reservation, clear out all instances.
  if (isAssignedInstance) {
    filteredReservations = seat?.state.reservations.filter(
      (r) => r.seriesId !== reservation.seriesId
    );
  } else {
    filteredReservations = seat?.state.reservations.filter(
      (r) => r.id !== reservation.id
    );
  }

  // Always send a notification about canceled reservation (user or admin triggered)
  return angularCancelReservation(reservation, {
    notify: true,
  }).then(() => {
    return {
      seat,
      reservations: filteredReservations,
    };
  });
};

const angularCancelReservation = (
  reservation: SeatReservationType,
  { notify = false, decline = false }
) => {
  // Check if it is a recurring reservation
  if (isSeriesInstance(reservation)) {
    if (reservation.type === 'assigned') {
      return cancelAssignedReservationFromInstance(reservation, notify);
    } else {
      return cancelRecurringReservation(reservation, notify);
    }
  }
  return cancelNonRecurringReservation(reservation, {
    notify,
    decline,
  });
};

const cancelAssignedReservationFromInstance = (
  reservation: SeatReservationType,
  notify: boolean
) => {
  return localEndReservation(reservation.seriesId ?? '', {
    send_notification: notify,
  });
};

const cancelRecurringReservation = (
  reservation: SeatReservationType,
  notify: boolean
) => {
  return localEndReservation(reservation.id ?? '', {
    send_notification: notify,
  });
};

const cancelNonRecurringReservation = (
  reservation: SeatReservationType,
  { notify = false, decline = false }
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const reqBody: any = {};
  if (decline) {
    reqBody.participation_status = 'declined';
  }
  return localEndReservation(
    reservation.id,
    {
      send_notification: notify,
    },
    reqBody
  );
};

const localEndReservation = (reservationID: string, params = {}, body = {}) => {
  return axios.post(
    `${process.env.REACT_APP_PHP_API_URL}/reservations/seats/${reservationID}/end`,
    body,
    {
      params,
    }
  );
};
