import { useMemo, useRef, useState } from 'react';
import { Colors } from '@robinpowered/design-system';
import { useClickAway } from 'react-use';
import { useOrganizationLocations, useSelectedHierarchy } from 'hooks';
import { useSearchParams } from 'react-router-dom';
import { LevelSelector } from './LevelSelector';
import styled from '@emotion/styled';
import { spacing } from 'utilities';
import { BuildingSolid } from 'images';
import { DropdownPicker } from './DropdownPicker';
import { ChevronRightOutline } from '@robinpowered/icons';

export function LocationSelector(): JSX.Element | null {
  const orgData = useOrganizationLocations();
  const dropdownRef = useRef(null);
  const { selectedLocationId, selectedCampusId } = useSelectedHierarchy();
  const [openPanel, setOpenPanel] = useState(0);
  useClickAway(dropdownRef, () => setOpenPanel(0));
  const setSearchParams = useSearchParams()[1];

  const campusDisplayLine = useMemo(() => {
    if (!orgData || !selectedCampusId || orgData.loading) {
      return null;
    }

    return (
      orgData.campuses.find((c) => c.id === selectedCampusId)?.name ?? null
    );
  }, [selectedCampusId, orgData]);

  const hideCampus = useMemo(() => {
    if (!orgData.loading && orgData.campuses.length > 0) {
      return false;
    }
    return true;
  }, [orgData]);

  const locationDisplayLine = useMemo(() => {
    if (!orgData || !selectedLocationId || orgData.loading) {
      return null;
    }

    return (
      orgData.locations.find((c) => c.id === selectedLocationId)?.name ?? null
    );
  }, [selectedLocationId, orgData]);

  const locationCampusSubset = useMemo(() => {
    if (orgData.loading || !orgData.locations) {
      return [];
    }

    if (!selectedCampusId) {
      return orgData.locations;
    }

    if (selectedCampusId === '-1') {
      return orgData.locations.filter((l) => !l.campus);
    }

    return orgData.locations.filter((l) => l.campus?.id === selectedCampusId);
  }, [orgData, selectedCampusId]);

  return (
    <LocationPickerWrapper ref={dropdownRef}>
      <BuildingIconWrapper>
        <BuildingSolid />
      </BuildingIconWrapper>
      {!hideCampus ? (
        <DropdownPicker
          onToggleClick={() => {
            if (openPanel === 1) {
              setOpenPanel(0);
            } else {
              setOpenPanel(1);
            }
          }}
          items={orgData.campuses}
          isOpen={openPanel === 1}
          onSelect={(item) => {
            setOpenPanel((openPanel + 1) % 4);
            setSearchParams((prev) => {
              prev.set('campusId', item.id);
              prev.delete('levelId');
              prev.delete('locationId');
              return prev;
            });
          }}
          displayByline={campusDisplayLine ?? 'Select Campus'}
        />
      ) : null}

      <ChevronStyleWrapper>
        <ChevronRightOutline size={10} />
      </ChevronStyleWrapper>
      <DropdownPicker
        onToggleClick={() => {
          if (openPanel === 2) {
            setOpenPanel(0);
          } else {
            setOpenPanel(2);
          }
        }}
        items={locationCampusSubset}
        isOpen={openPanel === 2}
        onSelect={(item) => {
          setOpenPanel((openPanel + 1) % 4);
          setSearchParams((prev) => {
            prev.set('locationId', item.id);
            prev.delete('levelId');
            return prev;
          });
        }}
        displayByline={locationDisplayLine ?? 'Select Location'}
      />

      {selectedLocationId ? (
        <>
          <ChevronStyleWrapper>
            <ChevronRightOutline size={10} />
          </ChevronStyleWrapper>
          <LevelSelector
            campusId={selectedCampusId}
            locationId={selectedLocationId}
            openPanel={openPanel}
            setOpenPanel={setOpenPanel}
          />
        </>
      ) : null}
    </LocationPickerWrapper>
  );
}

const LocationPickerWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  background-color: ${Colors.Tan5};
  padding: 4px 6px;
  border-radius: 8px;
  margin-right: ${spacing(3)};
`;

const BuildingIconWrapper = styled.span`
  margin-right: ${spacing(0.5)};
  svg {
    width: 16px;
    height: 16px;
  }
`;
const ChevronStyleWrapper = styled.span`
  margin: 0 ${spacing(0.5)};
  path {
    stroke-width: 4px;
    stroke: ${Colors.Gray40};
  }
`;
