/* eslint-disable no-plusplus */
/* eslint-disable operator-assignment */
/* eslint-disable no-bitwise */
import React, { FC, useState, useEffect } from 'react';
import styled from '@emotion/styled';
import Color from 'color';
import { Colors } from '@robinpowered/design-system';

export type AvatarProps = {
  /** Name of the user or alt-text for non-user avatars */
  name: string;
  /** Image source for an image icon */
  src?: string | null;
  /** Width/height override */
  size?: number;
  /** Background color override */
  color?: string;
  /** Allows external styling (e.g. styled(Avatar)`...`) */
  className?: string;
  /** Override the avatar contents */
  children?: React.ReactNode;
};

type AvatarContainerProps = {
  title: string;
  bgColor: string;
  fgColor: string;
  size?: number;
};

const initials = (name: string): string => {
  const [firstName, lastName] = name.split(' ');
  return firstName && lastName
    ? `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`
    : `${firstName.charAt(0).toUpperCase()}`;
};

const randomColorFromString = (str: string): string => {
  let hash = 0;
  if (str.length === 0) {
    return Colors.Gray40;
  }
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }
  let color = '#';
  for (let j = 0; j < 3; j++) {
    const value = (hash >> (j * 8)) & 255;
    color += `00${value.toString(16)}`.substr(-2);
  }
  return color;
};

// Get contrast color
const contrastColor = (hex: string): string => {
  const bg = Color(hex);
  if (bg.contrast(Color(Colors.White0)) > 4.5) {
    return Colors.White0;
  }
  return Colors.Gray110;
};

const AvatarContainer = styled.div<AvatarContainerProps>`
  border-radius: 100%;
  overflow: hidden;
  width: ${({ size }): string => `${size || 34}px`};
  height: ${({ size }): string => `${size || 34}px`};
  color: ${({ fgColor }: AvatarContainerProps): string => fgColor};
  background-color: ${({ bgColor }: AvatarContainerProps): string => bgColor};
`;

type AvatarInitialsProps = {
  size?: number;
};

const AvatarInitials = styled.div<AvatarInitialsProps>`
  width: 100%;
  height: 100%;
  display: flex;
  /*
    NOTE: calculation is based on default width/height of 34px,
    and font size of 12px.
  */
  font-size: ${({ size }): string =>
    size ? `${Math.round(size / 2.7)}px` : '12px'};
  line-height: ${({ size }): string =>
    size ? `${Math.round(size / 2.7)}px` : '12px'};
  font-weight: 500;
  justify-content: center;
  align-items: center;
  user-select: none;
`;

const AvatarImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 100%; // Safari Fix
  object-fit: cover;
`;

export const Avatar: FC<AvatarProps> = ({
  src,
  name,
  color,
  children,
  className,
  size,
}) => {
  const avatarImage = new Image();
  const [imageLoading, setImageLoading] = useState(false);
  const [imageError, setImageError] = useState(false);
  useEffect(() => {
    if (src) {
      avatarImage.onload = (): void => setImageLoading(false);
      avatarImage.onerror = (): void => {
        setImageError(true);
        setImageLoading(false);
      };
      avatarImage.src = src;
      setImageLoading(true);
    }
  }, [src]);

  let bgColor = randomColorFromString(name);
  const fgColor = contrastColor(bgColor);

  let avatarContents: React.ReactNode = '';
  if (imageError) {
    avatarContents = (
      <AvatarInitials size={size}>{initials(name || '')}</AvatarInitials>
    );
  } else if (src) {
    bgColor = 'white';
    if (imageLoading) {
      // TODO: proper loading
      avatarContents = null;
    } else {
      avatarContents = <AvatarImg src={src} alt={name} />;
    }
  } else if (children) {
    avatarContents = children;
    if (color) {
      bgColor = color;
    }
  } else {
    avatarContents = (
      <AvatarInitials size={size}>{initials(name || '')}</AvatarInitials>
    );
  }
  return (
    <AvatarContainer
      title={name}
      bgColor={bgColor}
      fgColor={fgColor}
      className={className}
      size={size}
    >
      {avatarContents}
    </AvatarContainer>
  );
};
