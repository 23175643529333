import { FC, useContext, useEffect, useCallback } from 'react';
import { MapIdContext } from './state';
import { useMap } from 'react-map-gl';
import { MapCustomIconButton } from './components';
import image from './images/3d.svg';
import React from 'react';

export const Map3dButton: FC = () => {
  const id = useContext(MapIdContext);

  if (id === '') {
    return null;
  }

  return <MapChildbutton mapId={id} />;
};

type ChildPropType = {
  mapId: string;
};

const MapChildbutton: FC<ChildPropType> = ({ mapId }) => {
  const map = useMap()[mapId];

  const eventHandler = useCallback(() => {
    if (!map) {
      return;
    }

    const existingLayer = map.getMap().getLayer('space-extrusion');
    if (existingLayer) {
      map.getMap().removeLayer('space-extrusion');
      return;
    }

    map.getMap().addLayer({
      id: 'space-extrusion',
      type: 'fill-extrusion',
      source: 'spaces',

      paint: {
        'fill-extrusion-height': 100000,
        'fill-extrusion-base': 0,
        'fill-extrusion-opacity': 0.5,
        'fill-extrusion-color': [
          'let',
          'colors',
          [
            'literal',
            {
              available: '#27B768',
              booked: '#F1BB42',
              in_use: '#EE3F46',
            },
          ],
          [
            'case',
            ['==', ['feature-state', 'focused'], true],
            'rgb(0, 74, 181)',
            ['==', ['feature-state', 'selected'], true],
            'rgb(0, 74, 181)',
            ['==', ['feature-state', 'highlighted'], true],
            'rgb(0, 74, 181)',
            ['==', ['feature-state', 'reduced'], true],
            'rgb(192, 200, 207)',
            ['==', ['feature-state', 'background'], true],
            'rgb(192, 200, 207)',
            ['==', ['feature-state', 'booked'], true],
            [
              'coalesce',
              ['get', 'booked', ['var', 'colors']],
              'rgb(243, 155, 27)',
            ],
            ['==', ['feature-state', 'inUse'], true],
            [
              'coalesce',
              ['get', 'in_use', ['var', 'colors']],
              'rgb(199, 30, 13)',
            ],
            ['==', ['feature-state', 'available-now'], true],
            [
              'coalesce',
              ['get', 'available', ['var', 'colors']],
              'rgb(3, 172, 78)',
            ],
            ['==', ['feature-state', 'restricted'], true],
            'rgb(192, 200, 207)',
            ['==', ['feature-state', 'warning'], true],
            'rgb(243, 155, 27)',
            ['==', ['feature-state', 'available'], true],
            [
              'coalesce',
              ['get', 'available', ['var', 'colors']],
              'rgb(3, 172, 78)',
            ],
            'rgb(192, 200, 207)',
          ],
        ],
      },
    });
  }, []);

  useEffect(() => {
    const theButton = new MapCustomIconButton({
      image,
      title: 'Three dimensional button',
      eventHandler,
    });
    map?.addControl(theButton, 'top-right');
    return () => {
      map?.removeControl(theButton);
    };
  }, []);

  return null;
};
