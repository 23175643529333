import React, { FC, useContext, useMemo } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Avatar, BrownFontStack, Colors } from '@robinpowered/design-system';
import { FONT_SIZES } from 'shared';
import InlineLoader from './InlineLoader';
import { useDebounce } from 'react-use';
import exIcon from '../../images/ex.svg';
import magnifierIcon from '../../images/magnifier.svg';
import { getInvitees } from './get-invitees';
import { MapInfoContext } from 'map/state';

const useInviteeSearch = (auth: string | null, orgid: string | null) => {
  const [keyword, setKeyword] = React.useState('');
  const [debouncedKeyword, setDebouncedKeyword] = React.useState(keyword);
  const [invitees, setInvitees] = React.useState<any[] | null>([]);
  const [selectedInvitee, setSelectedInvitee] = React.useState<any | null>(
    null
  );
  const [loading, setLoading] = React.useState(false);

  useDebounce(
    () => {
      setDebouncedKeyword(keyword);
    },
    300,
    [keyword]
  );

  React.useEffect(() => {
    if (!debouncedKeyword || !auth || !orgid) {
      setInvitees([]);
      return;
    }
    if (selectedInvitee) {
      return;
    }

    let canceled = false;
    setLoading(true);

    getInvitees(debouncedKeyword, auth, orgid)
      .then((data) => {
        if (!canceled) {
          // Filter out invitees that are:
          //  1. From an external contact list.
          //  2. Custom with an invalid email address.
          const validInvitees = data.filter((datum: any) => {
            if (datum.is_custom) {
              return datum.email_valid;
            } else if (datum.is_external) {
              // Show external contact if query matches the contact's email.
              return datum.email.toLowerCase() === keyword.toLowerCase();
            }
            return true;
          });
          setInvitees(validInvitees);
        }
      })
      .catch((error: any) =>
        console.error(`Failed to get and set invitees.`, {
          error,
          keyword,
        })
      )
      .finally(() => setLoading(false));

    return () => {
      canceled = true;
    };
  }, [debouncedKeyword, auth]);

  return {
    invitees,
    keyword,
    loading,
    setKeyword: (keyword: string) => {
      setSelectedInvitee(null);
      setKeyword(keyword);
    },
    setInvitee: (invitee: any) => {
      setSelectedInvitee(invitee);
      setKeyword(invitee.name || invitee.email);
      setInvitees([]);
    },
  };
};

export const InviteeSearch: FC<any> = ({
  style,
  onSelect,
  onKeywordChange,
  onClear,
  invitee,
}) => {
  const inputRef = React.useRef<HTMLInputElement | undefined>();
  const [isInputFocused, setIsInputFocused] = React.useState(false);
  const [shouldFocusOnNextTick, setShouldFocusOnNextTick] =
    React.useState(false);

  const { authToken, tenantId } = useContext(MapInfoContext);

  const { t }: any = useTranslation('map');

  const { invitees, keyword, loading, setKeyword, setInvitee } =
    useInviteeSearch(authToken, tenantId);

  const hasInviteeSuggestions = useMemo(
    () => invitees !== null && invitees.length > 0,
    [invitees]
  );

  React.useEffect(() => {
    inputRef.current?.focus();
  }, []);

  // We need to focus the input after a certain time, in an async way.
  // This effect allows us to do that.
  React.useEffect(() => {
    if (!invitee && shouldFocusOnNextTick && inputRef.current) {
      setShouldFocusOnNextTick(false);
      inputRef.current.focus();
    }
  }, [shouldFocusOnNextTick, invitee, inputRef.current]);

  return (
    <React.Fragment>
      <InputContainer
        isFocused={isInputFocused || hasInviteeSuggestions}
        style={style}
      >
        {invitee ? (
          <Avatar size={20} src={invitee.avatar} name={invitee.name} />
        ) : (
          <img
            src={magnifierIcon}
            width={14}
            height={14}
            color={Colors.Gray80}
          />
        )}
        <SearchInput
          type="text"
          role="combobox"
          aria-haspopup="listbox"
          aria-autocomplete="list"
          aria-expanded={hasInviteeSuggestions}
          ref={inputRef as any}
          value={keyword}
          placeholder={t(
            'WORKPLACE_MANAGE_SEATS.SEAT_ASSIGNMENT_POPOVER.SEARCH_PLACEHOLDER'
          )}
          onFocus={() => setIsInputFocused(true)}
          onBlur={() => setIsInputFocused(false)}
          onChange={(event) => {
            setKeyword(event.target.value);
            onKeywordChange();
          }}
          disabled={invitee}
        />
        <IconsWrapper>
          {invitee && (
            <ExIconWrapper
              onClick={() => {
                setKeyword('');
                setShouldFocusOnNextTick(true);
                if (onClear) onClear();
              }}
              tabIndex={0}
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  setKeyword('');
                  setShouldFocusOnNextTick(true);
                  if (onClear) onClear();
                }
              }}
              role="button"
              aria-label={t('DESK_POPOVER.REMOVE_LABEL')}
            >
              <img src={exIcon} width={10} height={10} />
            </ExIconWrapper>
          )}

          {loading && (
            <LoaderContainer>
              <InlineLoader />
            </LoaderContainer>
          )}
        </IconsWrapper>
      </InputContainer>

      {hasInviteeSuggestions && (
        <InviteeList
          invitees={invitees}
          onSelect={(invitee: any) => {
            onSelect(invitee);
            setInvitee(invitee);
          }}
        />
      )}
    </React.Fragment>
  );
};

const InviteeList: FC<{
  invitees: null | any[];
  onSelect: (invitee: any) => void;
}> = ({ invitees, onSelect }) => {
  const inviteeRef = React.useRef(null);
  if (!invitees) {
    return null;
  }
  return (
    <InviteeListContainer ref={inviteeRef}>
      {invitees.map((invitee) => (
        <InviteeItem
          key={invitee.id}
          onClick={() => onSelect(invitee)}
          tabIndex={0}
          onKeyUp={(e) => e.key === 'Enter' && onSelect(invitee)}
        >
          <Avatar
            size={32}
            src={invitee.avatar}
            name={invitee.name}
            aria-hidden="true"
          />
          <InviteeTextContainer>
            {invitee.name && (
              <>
                <InviteeText>{invitee.name}</InviteeText>
                <InviteeSubText>{invitee.email}</InviteeSubText>
              </>
            )}
            {!invitee.name && invitee.email && (
              <InviteeText>{invitee.email}</InviteeText>
            )}
          </InviteeTextContainer>
        </InviteeItem>
      ))}
    </InviteeListContainer>
  );
};

const InputContainer = styled.div<{ isFocused: boolean }>(
  (props) => `
  align-items: center;
  background: ${Colors.White0};
  border-radius: 5px;
  border: 1px solid ${Colors.Tan20};
  display: flex;
  height: 32px;
  padding: 8px;
  position: relative;
  transition: box-shadow 200ms ease;
  ${
    props.isFocused
      ? `
    box-shadow: 0px 1px 3px rgba(0,0,0,0.08);
  `
      : null
  }
  &:focus-within {
    outline: 2px solid ${Colors.Maroon100};
  }
`
);

const SearchInput = styled.input`
  background-color: ${Colors.White0};
  color: ${Colors.Gray100};
  flex: 1;
  font-family: ${BrownFontStack};
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  margin: 3px 0 0 8px;

  ::-webkit-input-placeholder {
    color: ${Colors.Gray90};
  }
  ::-moz-placeholder {
    color: ${Colors.Gray90};
  }

  /* Firefox fix for flex */
  min-width: 1px;

  /* Safari fix for displaying correct value color when disabled */
  &:disabled {
    -webkit-text-fill-color: ${Colors.Gray100};
    color: ${Colors.Gray100};
    opacity: 1;
  }

  /* MS Edge fix to remove browser default X in inputs */
  ::-ms-clear {
    display: none;
  }
`;

const LoaderContainer = styled.div`
  height: 14px;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  right: 2px;
  top: 4px;
  width: 14px;
`;

const InviteeListContainer = styled.div`
  background-color: #fff;
  border-radius: 0 0 4px 4px;
  border: 1px solid ${Colors.Tan10};
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.15);
  margin-top: -3px;
  max-height: 300px;
  overflow-y: auto;
  padding-bottom: 4px;
  padding-top: 4px;
  position: absolute;
  width: 100%;
  z-index: 1;
`;

const InviteeItem = styled.div`
  align-items: center;
  border-radius: 34px;
  display: flex;
  margin: 4px 8px;
  padding: 4px;

  > div:last-child {
    flex: 1;
    margin-left: 12px;
  }

  &:hover {
    background-color: ${Colors.Tan5};
    cursor: pointer;
  }

  &:focus {
    background-color: ${Colors.Tan5};
    outline: 2px solid ${Colors.Maroon100};
  }
`;

const InviteeTextContainer = styled.div`
  overflow: hidden;
`;

const InviteeText = styled.div`
  font-family: ${BrownFontStack};
  font-size: ${FONT_SIZES.SMALL};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${Colors.Gray100};
`;

const InviteeSubText = styled.div`
  font-family: ${BrownFontStack};
  font-size: ${FONT_SIZES.TINY};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${Colors.Gray90};
`;

const ExIconWrapper = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-shrink: 0;
  height: 29px;
  justify-content: center;
  position: absolute;
  width: 29px;
  &:focus {
    outline: 2px solid ${Colors.Maroon100};
  }
`;

const IconsWrapper = styled.div`
  flex-shrink: 0;
  height: 30px;
  margin-right: -8px;
  position: relative;
  width: 30px;
`;
