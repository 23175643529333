import cookie from 'js-cookie';
import { config } from 'config';

type RobinSession = {
  access_token: string;
};

function parseCookie<T>(cookieValue: string): T {
  return JSON.parse(decodeURIComponent(cookieValue));
}

export function useAccessToken(): string | null {
  const sessionCookieName = config.sessionCookieName;
  const value = cookie.get(sessionCookieName);
  const session = value ? parseCookie<RobinSession>(value) : null;

  return session?.access_token ?? null;
}
