import { useAuthContext } from 'contexts';
import { useAnnouncementAnalyticsData, useSelectedHierarchy } from 'hooks';
import { useViewAnnouncement } from 'hooks/useViewAnnouncement';
import moment from 'moment';
import { FC, useEffect, useMemo } from 'react';
import { summarizeDate } from 'utilities';
import { useTranslation } from 'react-i18next';
import { useAnnouncement } from 'hooks/useAnnouncement';
import {
  AnnouncementAnalytics,
  AnnouncementFullCard,
  AnnouncementLoading,
} from './shared/Announcements';

export const Announcement: FC<{
  announcementId: string;
  canManageAnnouncements: boolean;
}> = ({ announcementId }) => {
  const { t } = useTranslation('announcements');
  const { currentUser } = useAuthContext();
  const { selectedLocationId } = useSelectedHierarchy();
  const { announcement, canManageAnnouncements, loading } = useAnnouncement(
    selectedLocationId,
    announcementId
  );
  const {
    loading: analyticsLoading,
    viewCount,
    acknowledgeCount,
  } = useAnnouncementAnalyticsData(announcementId, canManageAnnouncements);

  const [viewAnnouncement] = useViewAnnouncement();

  const { id, author, createdAt, viewedAt, priorityStatus } =
    announcement || {};

  const posterName = useMemo(() => {
    return currentUser?.id === author?.id ? t('you_posted_this') : author?.name;
  }, [currentUser?.id, author?.id, author?.name]);

  useEffect(() => {
    if (announcement && !viewedAt) {
      viewAnnouncement({
        variables: {
          locationId: selectedLocationId,
          announcementId,
        },
      });
    }
  }, [announcement, viewedAt, id, selectedLocationId]);

  if (!announcement) {
    return null;
  }

  return loading ? (
    <AnnouncementLoading />
  ) : (
    <AnnouncementFullCard
      announcement={announcement}
      postedByText={posterName}
      viewedAtText={
        t('read_at', {
          date: moment(viewedAt).format('dddd, MMM DD h:mm a'),
        }) || undefined
      }
      summarizedDate={summarizeDate(createdAt)}
    >
      {canManageAnnouncements && (
        <AnnouncementAnalytics
          loading={analyticsLoading}
          priority={priorityStatus}
          viewCount={viewCount}
          acknowledgeCount={acknowledgeCount}
          tooltipText={{
            views: t('views'),
            acknowledgements: t('acknowledgements'),
          }}
        />
      )}
    </AnnouncementFullCard>
  );
};
