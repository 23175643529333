import { FC } from 'react';
import { MapStyledPopup } from 'map/components';
import { useDeskPopupContext } from './deskPopupContext';

export const DeskPopupStyledBoundary: FC<{
  children?: React.ReactNode | null | undefined;
}> = ({ children }) => {
  const { seatData, setSeatData } = useDeskPopupContext();
  if (!seatData) {
    return null;
  }

  return (
    <MapStyledPopup
      latitude={seatData.lat}
      longitude={seatData.lng}
      anchor="bottom-left"
      onClose={() => {
        if (setSeatData) {
          setSeatData(null);
        }
      }}
    >
      {' '}
      {children}{' '}
    </MapStyledPopup>
  );
};
