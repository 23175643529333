import styled from '@emotion/styled';
import { FC } from 'react';
import { Colors, Heading } from '@robinpowered/design-system';
import ChevronRightOutline from '@robinpowered/icons/ChevronRightOutline';
import AnnouncementSolid from '@robinpowered/icons/AnnouncementSolid';

export const AnnouncementPreviewHeader: FC<{
  title: string;
  unreadCount: number;
  seeAllText: string;
  onClick: () => void;
}> = ({ title, unreadCount, seeAllText, onClick }) => {
  return (
    <HeaderButton onClick={onClick}>
      <HeaderSection>
        <AnnouncementsIconContainer>
          <AnnouncementSolid size={20} />

          {unreadCount > 0 && <UnreadAnnouncementsDot />}
        </AnnouncementsIconContainer>

        <Heading.Regular>{title}</Heading.Regular>
      </HeaderSection>

      <HeaderSection>
        {unreadCount > 1 && <Heading.Regular>{seeAllText}</Heading.Regular>}
        <ChevronRightOutline size={16} />
      </HeaderSection>
    </HeaderButton>
  );
};

const HeaderButton = styled.button`
  background-color: unset;
  border: none;
  width: 100%;
  padding: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;

  cursor: pointer;
`;

const HeaderSection = styled.div`
  display: flex;
  align-items: center;
`;

const AnnouncementsIconContainer = styled.div`
  padding-right: 8px;
  position: relative;
  display: flex;
  align-items: center;
`;

const UnreadAnnouncementsDot = styled.div`
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: ${Colors.RedProduct};
  top: -3px;
  right: 7px;
  border: 2px solid ${Colors.White0};
  border-radius: 50%;
`;
