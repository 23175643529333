import styled from '@emotion/styled';
import { Popup } from 'react-map-gl';

export const QuoteBubblePopup = styled(Popup)<{
  loading?: boolean | null | undefined;
}>((props) => ({
  background: '#ffffff',
  borderRadius: '12px',
  height: 'auto',
  border: 'none',
  padding: '0px',
  display: 'flex',
  fontSize: '12px',
  gap: '8px',
  ':after': props.loading
    ? {}
    : {
        content: `''`,
        position: 'absolute',
        bottom: 0,
        left: '15%',
        width: 0,
        height: 0,
        borderWidth: '12px',
        borderStyle: 'solid',
        borderColor: 'transparent',
        borderTopColor: '#ffffff',
        borderBottom: 0,
        marginLeft: '-10px',
        marginBottom: '-10px',
      },
  '.maplibregl-popup-content': {
    borderRadius: '12px',
    padding: '12px 16px',
  },
  '.maplibregl-popup-tip': {
    display: 'none',
  },
  '.maplibregl-popup-close-button': {
    display: 'none',
  },
}));
