import { MapIdContext, useMapLoadCompleteAtom } from '../state';
import { FC, useCallback, useContext, useEffect } from 'react';
import { useMap } from 'react-map-gl';
import { useRecoilValue } from 'recoil';
import { flyToFeature } from './action';

type FlyToResourceComponentProperties = {
  /* resource layer containing the feature to center on the map */
  resourceLayer: string;
  /* resource identity, ROBIN ID */
  resourceId: string | number | undefined;
  /* zoom level after fly (optional) */
  zoomTo?: number;
  /* Optional callback when fly is complete (reached target) or was interrupted by the user */
  onFlyCompleteOrInterrupted?: () => void;
};

/**
 * Map component that scrolls the camera to the specified resource
 * @param resourceLayer layer that contains the target resource ex "spaces" or "seats"
 * @param resourceId robin identity of the target resource
 * @param zoomTo Optional zoom level after camera scroll
 * @returns React component
 */
export const FlyToResourceComponent: FC<FlyToResourceComponentProperties> = ({
  resourceLayer,
  resourceId,
  zoomTo,
  onFlyCompleteOrInterrupted,
}) => {
  const id = useContext(MapIdContext);
  const map = useMap()[id];
  const mapLoaded = useRecoilValue(useMapLoadCompleteAtom(id));

  const onMoveEnd = useCallback(() => {
    if (onFlyCompleteOrInterrupted) {
      onFlyCompleteOrInterrupted();
    }
  }, [onFlyCompleteOrInterrupted]);

  useEffect(() => {
    if (!mapLoaded || !map || !resourceId) {
      return;
    }

    map?.once('moveend', onMoveEnd);

    flyToFeature(map, resourceLayer, resourceId, zoomTo);

    return () => {
      map?.off('moveend', onMoveEnd);
    };
  }, [mapLoaded, map, resourceLayer, resourceId, zoomTo]);

  return null;
};
