import { gql, useMutation } from '@apollo/client';

const VIEW_ANNOUNCEMENT_MUTATION = gql`
  mutation useViewAnnouncement($announcementId: ID!, $locationId: ID!) {
    viewAnnouncement(announcementId: $announcementId, locationId: $locationId)
  }
`;

export const useViewAnnouncement = () => {
  return useMutation(VIEW_ANNOUNCEMENT_MUTATION, {
    refetchQueries: ['useActiveAnnouncements', 'useAnnouncement'],
  });
};
