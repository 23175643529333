import styled from '@emotion/styled';
import { Colors } from '@robinpowered/design-system';

export const OrgMenuButton = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  background: none;
  padding: 4px 8px;
  border: 2px solid transparent;
  border-radius: 28px;
  cursor: pointer;
  height: 80%;

  &:hover {
    background: ${Colors.Tan5};
  }

  &:focus-visible {
    border-color: ${Colors.Maroon100};
  }
`;
