import { Card } from './shared/Card';
import { Skeleton } from '@robinpowered/design-system';
import { FC, useCallback, useMemo } from 'react';
import { useActiveAnnouncements, useSelectedHierarchy } from 'hooks';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  AnnouncementPreview,
  AnnouncementPreviewHeader,
  AnnouncementCreateButton,
} from './shared/Announcements';
import { useSearchParams } from 'react-router-dom';

export const AnnouncementsCard: FC = () => {
  const { t } = useTranslation('announcements');
  const { selectedLocationId } = useSelectedHierarchy();
  const setSearchParams = useSearchParams()[1];

  const { activeAnnouncements, canManageAnnouncements, loading } =
    useActiveAnnouncements(selectedLocationId);

  const unreadCount = useMemo(() => {
    return activeAnnouncements?.filter((a) => !a.viewedAt).length || 0;
  }, [activeAnnouncements]);

  const title = useMemo(() => {
    return unreadCount === 0 ? t('no_unread') : t('unread');
  }, [unreadCount]);

  const firstUnreadAnnouncement = useMemo(() => {
    return (
      activeAnnouncements &&
      [...activeAnnouncements]
        .sort(
          (a, b) =>
            moment(b?.createdAt).valueOf() - moment(a?.createdAt).valueOf()
        )
        .find((a) => a && !a.viewedAt)
    );
  }, [activeAnnouncements]);

  const handleClickSeeAll = useCallback(() => {
    setSearchParams((prev) => {
      prev.set('announcements', 'list');
      return prev;
    });
  }, []);

  const handleSingleAnnouncementClick = useCallback(() => {
    if (firstUnreadAnnouncement) {
      setSearchParams((prev) => {
        prev.set('announcements', firstUnreadAnnouncement.id);
        return prev;
      });
    }
  }, [firstUnreadAnnouncement]);

  return (
    <Card>
      {loading ? (
        <Skeleton height={24} width={'100%'} />
      ) : (
        <>
          <AnnouncementPreviewHeader
            title={title}
            unreadCount={unreadCount}
            seeAllText={t('see_all', { count: unreadCount })}
            onClick={handleClickSeeAll}
          />

          {firstUnreadAnnouncement && (
            <AnnouncementPreview
              name={firstUnreadAnnouncement.name}
              content={firstUnreadAnnouncement.content}
              authorName={firstUnreadAnnouncement.author.name}
              authorAvatar={firstUnreadAnnouncement.author.avatar}
              readMoreLabel={t('read_more')}
              onClick={handleSingleAnnouncementClick}
            />
          )}

          {canManageAnnouncements && (
            <AnnouncementCreateButton
              label={t('create')}
              variant="secondary"
              size="small"
              onClick={() => alert('Open create announcement panel')}
            />
          )}
        </>
      )}
    </Card>
  );
};
