import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Colors, Row, Body07, Avatar } from '@robinpowered/design-system';
import AvatarImgBen from '../../../../images/Avatar_BenHarper.png';
import AvatarImgGibran from '../../../../images/Avatar_GibranSimmons.png';
import AvatarImgEileen from '../../../../images/Avatar_EileenZhou.png';
import CoverImageActivity from '../../../../images/CoverImage_Activity.png';
import { useTranslation } from 'react-i18next';

export const ActivityCard: FC = () => {
  const { t } = useTranslation('downloadMobileAppModal');
  return (
    <Card>
      <CoverImage src={CoverImageActivity} />
      <CardHeading>{t('activity_card_title')}</CardHeading>
      <AvatarWrapper>
        <Avatar name={'Ben Harper'} size={24} src={AvatarImgBen}></Avatar>
        <Avatar
          name={'Gibran Simmons'}
          size={24}
          src={AvatarImgGibran}
        ></Avatar>
        <Avatar name={'Eileen Zhou'} size={24} src={AvatarImgEileen}></Avatar>
      </AvatarWrapper>
      <ActivityCardButton>{t('activity_card_button')}</ActivityCardButton>
    </Card>
  );
};

const CardHeading = styled(Body07)`
  margin-bottom: 6px;
`;

const Card = styled.div`
  position: absolute;
  border: 1px solid ${Colors.Gray30};
  border-radius: 8px;
  padding: 6px;
  max-width: fit-content;
  background-color: white;
  margin-right: -100px;
  margin-left: 72px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
`;

const CoverImage = styled.img`
  aspect-ratio: 3 / 1;
  width: 165px;
  height: 56px;
  object-fit: cover;
  object-position: center top;
  margin-top: -6px;
  margin-right: -6px;
  margin-left: -6px;
  margin-bottom: 6px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

const AvatarWrapper = styled(Row)`
  flex-direction: row;
  gap: 2px;
  margin-bottom: 6px;
`;

const ActivityCardButton = styled.div`
  border: 1px solid ${Colors.Gray30};
  border-radius: 4px;
  padding: 6px;
  font-size: 10px;
  font-weight: 700;
`;
