import styled from '@emotion/styled';
import { FC } from 'react';

const MenuSvg: FC = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3 4H4.7c-.8 0-1.2 0-1.4.2-.3.2-.3.6-.3 1.4v.9h12v-.9c0-.8 0-1.2-.3-1.4-.2-.3-.6-.3-1.4-.3z"
        fill="#706E6E"
      />
      <path
        d="M3.1 14l1-.5-1 .5zM15 14l.8.5-.8-.5zM4.6 4a1 1 0 102 0h-2zm2-1.8a1 1 0 00-2 0h2zM11.4 4a1 1 0 102 0h-2zm2-1.6a1 1 0 10-2 0h2zM4.7 4.8h8.6V3H4.7v2zm9.3 8.6c-.1.2-.4.5-1.2.8-.7.2-2 .4-3.8.4v2c2 0 3.4-.2 4.5-.5 1-.4 1.8-1 2.2-1.7l-1.7-1zm-5 1.3c-1.9 0-3-.3-3.8-.5-.8-.3-1-.6-1.2-.8l-1.7 1c.4.7 1.1 1.3 2.2 1.7 1 .3 2.5.6 4.5.6v-2zm-7-1.6v.7l2-.4v-.1-.2H2zm2 .3l-2 .4.2.3v.3l1.8-1zm10-.3a2.4 2.4 0 010 .3l2 .4v-.7h-2zm1.7 1.3l.1-.3.1-.3-2-.4 1.8 1zm-2.4-9.6h.8c.1 0 0 0 0 0l1.4-1.4C15 3 14.7 3 14.4 3h-1.1v2zm2.7.7v-1c0-.4-.2-.8-.5-1.1L14 4.9c0-.1 0-.2 0 0v.7h2zM4.7 3h-1c-.4.1-.8.2-1.2.6L4 4.9h-.1.8V3zM4 5.6a20.7 20.7 0 010-.8V5L2.5 3.5c-.3.3-.4.7-.5 1v1.1h2zm0 7.6V6.5H2v6.7h2zm0-6.7v-.9H2v.9h2zm10-.9v.9h2v-.9h-2zm0 .9v6.7h2V6.5h-2zm-11 1h12v-2H3v2zm3.6-3.6V2.3h-2v1.6h2zm6.8 0V2.3h-2v1.6h2z"
        fill="#706E6E"
      />
    </svg>
  );
};

export const CalendarMenuIcon = styled(MenuSvg)<{ size?: number }>`
  margin-right: 4px;

  svg {
    width: ${(props) => props.size ?? 15}px;
    height: ${(props) => props.size ?? 15}px;
  }
`;
