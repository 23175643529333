import styled from '@emotion/styled';
import {
  BrownFontStack,
  Colors,
  CoreFontSize,
} from '@robinpowered/design-system';

export const SeatTypeByline = styled.p`
  color: ${Colors.Gray80};
  font-family: ${BrownFontStack};
  font-size: ${CoreFontSize.s16}px;
  line-height: 21.4286px;
  margin-top: 2px;
  word-break: break-word;
  word-wrap: break-word;
`;
