import { useState } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import {
  BrownFontStack,
  CoreFontSize,
  CoreFontWeight,
} from '@robinpowered/design-system';
import { Colors } from '@robinpowered/design-system';
import { spacing } from 'utilities';
import { useTranslation } from 'react-i18next';

const DropdownWrapper = styled.div`
  position: absolute;
  z-index: 100;
  border-radius: 4px;
  display: none;
  overflow: hidden;
  ${({ isOpen }: { isOpen: boolean }) =>
    isOpen &&
    css`
      display: flex;
      flex-direction: column;
      border: solid 1px ${Colors.Tan70};
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
      width: 196px;
    `}
`;

const DropdownBodyWrapper = styled.ul`
  background-color: ${Colors.White0};
  max-height: 300px;
  overflow: auto;
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ListItemProps = { highlight: boolean; selected: boolean };

const ListItem = styled.li`
  list-style: none;
  cursor: pointer;
  font-family: ${BrownFontStack};
  font-size: ${CoreFontSize.s12}px;
  padding: ${spacing(0.5)} ${spacing(2)};
  background: ${({ highlight }: ListItemProps) =>
    highlight ? Colors.Tan10 : Colors.White0};
  display: flex;
  color: ${({ selected }: { selected: boolean }) =>
    selected ? Colors.Maroon100 : Colors.Gray80};
  }
`;

const DropdownTrigger = styled.button`
  color: ${Colors.Gray80};
  background: ${Colors.Tan5};
  border: 2px solid ${Colors.Tan5};
  border-radius: 4px;
  cursor: pointer;
  padding: ${spacing(0.5)};
  font-family: ${BrownFontStack};
  font-size: ${CoreFontSize.s12}px;
  max-width: 140px;
  &:hover {
    background: ${Colors.Tan30};
  }
  &:focus-visible {
    border: 2px solid ${Colors.Maroon100};
  }
`;

const DropdownTriggerText = styled.div`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: ${BrownFontStack};
  font-weight: ${CoreFontWeight.Medium};
  font-size: ${CoreFontSize.s12};
  color: ${Colors.Gray100};
`;

type PropsType<T> = {
  items: T[];
  onSelect: (item: T) => void;
  displayByline?: string;
  isOpen: boolean;
  onToggleClick?: () => void;
  getItemByline?: (item: T) => string;
  dataCy?: string;
  dataFocusId?: string;
};

export function DropdownPicker<
  T extends { name: string; id: string | number }
>({
  items,
  onSelect,
  displayByline,
  isOpen,
  getItemByline,
  dataCy = 'data-cy',
  dataFocusId = 'data-focus-id',
  onToggleClick,
}: PropsType<T>): JSX.Element {
  const { t } = useTranslation();
  const [selectedItem, setSelectedItem] = useState<T | null>(null);

  const getItemKey = (item: { name: string }, index: number) =>
    `${item.name}${index}`;

  return (
    <div>
      <DropdownTrigger
        data-cy={dataCy}
        data-focus-id={dataFocusId}
        onClick={() => {
          onToggleClick && onToggleClick();
        }}
      >
        <DropdownTriggerText title={displayByline || ''}>
          {displayByline || t('SELECT_TITLE')}
        </DropdownTriggerText>
      </DropdownTrigger>
      {items.length > 0 ? (
        <DropdownWrapper isOpen={isOpen}>
          <DropdownBodyWrapper>
            {isOpen &&
              items.map((item, index) => (
                <ListItem
                  key={getItemKey(item, index)}
                  data-cy="dropdown-list-item"
                  highlight={false}
                  selected={
                    (selectedItem && item.id === selectedItem.id) ?? false
                  }
                  onClick={() => {
                    onSelect(item);
                    setSelectedItem(item);
                  }}
                >
                  <div>{item.name}</div>
                  {getItemByline && getItemByline(item)}
                </ListItem>
              ))}
          </DropdownBodyWrapper>
        </DropdownWrapper>
      ) : null}
    </div>
  );
}
