import {
  FC,
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useMemo,
  useState,
  ReactNode,
} from 'react';
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  from,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { useAccessToken } from 'hooks/useAccessToken';
import { Sentry } from 'lib/sentry';
import { config } from 'config';
import { RetryLink } from '@apollo/client/link/retry';

type ApolloContextValue = {
  tenantId: string | null;
  setTenantId: Dispatch<SetStateAction<string | null>>;
};

const httpLink = createHttpLink({
  uri: config.gqlUrl ?? '',
  useGETForQueries: true,
});

const retryLink = new RetryLink();

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, response }) => {
    const captureException = (
      message: string,
      level: Sentry.SeverityLevel,
      extra?: Record<string, unknown>
    ) => {
      Sentry.withScope((scope) => {
        scope.setLevel(level);
        Sentry.captureException(message, {
          tags: {
            graphql: true,
          },
          extra: {
            ...extra,
            operation,
            response,
          },
        });
      });
    };

    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        captureException(`[GraphQL error]: Message: ${message}`, 'error', {
          path,
          locations,
        });
      });
    }
    if (networkError) {
      captureException(`[Network error]: ${networkError}`, 'info');
    }
  }
);

export const apolloCache = new InMemoryCache({
  typePolicies: {
    CurrentUserAndOrgs: {
      keyFields: ['userId'],
    },

    Query: {
      fields: {
        getAllVisits: {
          merge(_, incoming) {
            // Always take the latest data instead of merging with existing cache
            return incoming;
          },
        },
        getAnnouncementById: {
          read(_, { args, toReference }) {
            // Redirect to cached value if one exists
            return toReference({
              __typename: 'Announcement',
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              id: args!.announcementId,
            });
          },
        },
      },
    },
  },
});

const createApolloClient = (
  accessToken: string | null,
  tenantId: string | null
) => {
  const authLink = setContext((_, { headers }) => ({
    headers: {
      ...headers,
      ...(accessToken && { authorization: `Access-Token ${accessToken}` }),
      ...(tenantId && { 'tenant-id': tenantId }),
    },
  }));

  return new ApolloClient({
    name: config.appName ?? 'unknown-react-dashboard-app',
    version: config.appVersion ?? '0.0',
    link: from([errorLink, authLink, retryLink, httpLink]),
    cache: apolloCache,
    credentials: 'include',
    resolvers: {},
    connectToDevTools: process.env.NODE_ENV !== 'production',
  });
};

const ApolloContext = createContext<ApolloContextValue>({
  tenantId: null,
  setTenantId: () => null,
});

export const ApolloContextProvider: FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const [tenantId, setTenantId] = useState<string | null>(null);
  const accessToken = useAccessToken();

  const apolloClient = useMemo(
    () => createApolloClient(accessToken, tenantId),
    [accessToken, tenantId]
  );

  return (
    <ApolloContext.Provider value={{ tenantId, setTenantId }}>
      <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
    </ApolloContext.Provider>
  );
};

export const useApolloContext = (): ApolloContextValue => {
  return useContext(ApolloContext);
};
