import { FC, useContext, useEffect, useState } from 'react';
import { useMap } from 'react-map-gl';
import { useRecoilValue } from 'recoil';
import {
  MapIdContext,
  useMapLoadCompleteAtom,
  useMapMetaDataAtom,
} from '../state';
import { getSeatIdentitiesFromMap } from './common';
import { SeatAvatarSubComponent } from './SeatAvatarSubComponent';
import { SeatAvatarComponentProperties } from './seat-avatar-component-properties';
import React from 'react';

/**
 * Map component that loads and displays user avatars over booked seats
 * @param seatPerLoadCycle Optional: Number of seats loaded per-iteration within the progressive loading scheme
 * @param startTime Start ISO timestamp of seat reservation avatar
 * @param endTime End ISO timestamp of seat reservation avatar
 * @param excludeUserIdentities Optional set of user IDs to exclude from avatar render
 * @returns React component
 */
export const SeatAvatarComponent: FC<SeatAvatarComponentProperties> = ({
  seatPerLoadCycle = 10,
  startTime,
  endTime,
  excludeUserIdentities,
}) => {
  const id = useContext(MapIdContext);
  // outer layer waits for map load event and gets all seat IDs from the map style spec
  const metadata = useRecoilValue(useMapMetaDataAtom(id));
  const map = useMap()[id];
  const mapLoadState = useRecoilValue(useMapLoadCompleteAtom(id));
  const [seatIdGroups, setSeatIdGroups] = useState<string[][]>([]);
  const avatarLayers: string[] = metadata['robin:avatar-renderer:seat-layers'];

  useEffect(() => {
    if (!map || !mapLoadState) {
      return;
    }

    if (avatarLayers) {
      avatarLayers.forEach((al) => {
        map.getMap().setLayerZoomRange(al, 3.8, 0);
      });
    }

    const sortedSeatIds = Array.from(getSeatIdentitiesFromMap(map)).sort(
      (a, b) => {
        return a > b ? 1 : -1;
      }
    );

    const groupedSeatIds: string[][] = [];

    while (sortedSeatIds.length > 0) {
      groupedSeatIds.push(sortedSeatIds.splice(0, seatPerLoadCycle));
    }

    setSeatIdGroups(groupedSeatIds);
  }, [mapLoadState, map, seatPerLoadCycle]);

  if (!map || !mapLoadState || seatIdGroups.length < 1) {
    return null;
  }

  return (
    <>
      {seatIdGroups.map((seatIds) => (
        <SeatAvatarSubComponent
          key={seatIds[0]}
          seatIds={seatIds}
          map={map}
          startTime={startTime}
          endTime={endTime}
          excludeUserIdentities={excludeUserIdentities ?? new Set<string>()}
        />
      ))}
    </>
  );
};
