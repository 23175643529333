import styled from '@emotion/styled';
import { Colors } from '@robinpowered/design-system';
import { useState } from 'react';

import {
  OrganizationDropdown,
  HeaderMenu,
  NotificationMenuItem,
  UserProfileMenu,
} from './components';
import { HeaderContextProvider } from './contexts';
import { DownloadMobileAppModal } from './components/DownloadMobileAppModal';

const StyledHeaderWrapper = styled.nav`
  align-items: center;
  display: flex;
  flex: 0 1 auto;
  height: 56px;
  background-color: ${Colors.White0};
  padding: 0 2rem;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
  z-index: 10;
`;

export type AmplitudeEventHandler = (
  event: string,
  metadata?: Record<string, unknown> | undefined
) => void;

type PropTypes = {
  activeOrgSlug: string;
  hasTenant?: boolean;
  amplitudeEventHandler: AmplitudeEventHandler;
};

export const Header = ({
  activeOrgSlug,
  hasTenant = true,
  amplitudeEventHandler,
}: PropTypes): JSX.Element => {
  const [showDownloadRobinAppModal, setShowDownloadRobinAppModal] =
    useState<boolean>(/Android|(iPhone|iPod)/i.test(navigator.userAgent));
  return (
    <HeaderContextProvider
      activeOrgSlug={activeOrgSlug}
      hasTenant={hasTenant}
      amplitudeEventHandler={amplitudeEventHandler}
    >
      <>
        {showDownloadRobinAppModal && (
          <DownloadMobileAppModal
            setShowDownloadRobinAppModal={setShowDownloadRobinAppModal}
          />
        )}
        <StyledHeaderWrapper aria-label="Top Navigation">
          <OrganizationDropdown />
          <HeaderMenu />
          <NotificationMenuItem />
          <UserProfileMenu />
        </StyledHeaderWrapper>
      </>
    </HeaderContextProvider>
  );
};
