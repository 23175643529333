import { QueryResult, gql, useQuery } from '@apollo/client';
import {
  UseOrganizationSeatMapFeaturesQuery,
  UseOrganizationSeatMapFeaturesQueryVariables,
} from 'generated';
import { MapInfoContext } from '../state';
import { useContext } from 'react';

const ORGANIZATION_SEAT_MAP_FEATURES = gql`
  query UseOrganizationSeatMapFeatures($organizationId: ID!) {
    getOrganizationById(id: $organizationId) {
      id
      features {
        reverseHoteling
      }
    }
  }
`;

export const useOrganizationSeatMapFeatures = (): QueryResult<
  UseOrganizationSeatMapFeaturesQuery,
  UseOrganizationSeatMapFeaturesQueryVariables
> => {
  const { tenantId } = useContext(MapInfoContext);
  const result = useQuery<
    UseOrganizationSeatMapFeaturesQuery,
    UseOrganizationSeatMapFeaturesQueryVariables
  >(ORGANIZATION_SEAT_MAP_FEATURES, {
    skip: !tenantId,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      organizationId: tenantId ?? '',
    },
  });

  return result;
};
