import styled from '@emotion/styled';
import { LeftPanel } from './LeftPanel';
import { Colors } from '@robinpowered/design-system';
import { FC, useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AnnouncementsList } from './AnnouncementsList';
import { Announcement } from './Announcement';

export const AnnouncementsPanel: FC = () => {
  const { t } = useTranslation('announcements');
  const [searchParams, setSearchParams] = useSearchParams();

  const selectedAnnouncementId = useMemo(() => {
    const linkedId = searchParams.get('announcements');

    if (linkedId && linkedId !== 'list') {
      return linkedId;
    }
  }, [searchParams]);

  const handleClose = useCallback(() => {
    if (selectedAnnouncementId) {
      // Go back to the list
      setSearchParams((prev) => {
        prev.set('announcements', 'list');
        return prev;
      });
    } else {
      // Close the entire panel
      setSearchParams((prev) => {
        prev.delete('announcements');
        return prev;
      });
    }
  }, [selectedAnnouncementId]);

  return (
    <Panel
      title={t('title')}
      mode={selectedAnnouncementId ? 'back' : 'close'}
      onClose={handleClose}
    >
      <Content>
        <>
          {selectedAnnouncementId ? (
            <Announcement
              announcementId={selectedAnnouncementId}
              canManageAnnouncements={false}
            />
          ) : (
            <AnnouncementsList />
          )}
        </>
      </Content>
    </Panel>
  );
};

const Panel = styled(LeftPanel)`
  background-color: ${Colors.Tan30};
  width: 380px;
`;

const Content = styled.div`
  padding: 0 16px;
`;
