import styled from '@emotion/styled';
import { Colors, Heading, UtilityButton } from '@robinpowered/design-system';
import { FC, useMemo } from 'react';
import Close from '@robinpowered/icons/Close';
import ChevronLeftOutline from '@robinpowered/icons/ChevronLeftOutline';

export const LeftPanel: FC<{
  title: string;
  onClose: () => void;
  children: React.ReactNode;
  mode?: 'close' | 'back';
  className?: string | undefined;
}> = ({ title, onClose, children, mode = 'close', className }) => {
  const icon = useMemo(() => {
    return mode === 'close' ? Close : ChevronLeftOutline;
  }, [mode]);

  return (
    <Container className={className}>
      <Header>
        <UtilityButton icon={icon} onClick={onClose} />
        <Title>{title}</Title>
      </Header>

      {children}
    </Container>
  );
};

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
`;

const Title = styled(Heading.Regular)`
  padding-left: 8px;
`;

const Container = styled.div`
  position: absolute;
  height: 100%;
  width: 400px;
  background-color: ${Colors.White0};
  box-shadow: #0000000f 0px 1px 12px, #00000026 0px 1px 3px;
  overflow-y: auto;
`;
