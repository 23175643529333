import { ButtonV4 } from '@robinpowered/design-system';
import { useCallback, useContext, useState } from 'react';
import {
  HideMapLayerComponent,
  Map3dButton,
  SeatStateMultiDayComponnentProgressiveLoad,
  SpaceStateHoverComponent,
} from './map';
import {
  useMapComponentLoadState,
  useMultiDates,
  useSelectedDateTime,
  useSelectedHierarchy,
} from 'hooks';
import { NavigationControl } from 'react-map-gl';
import {
  MapMousePointer,
  MouseClickComponent,
  MouseOverComponent,
  OnMapClickEvent,
} from 'map/pointer';
import { HamburgerIcon } from 'images';
import { MenuContext } from 'react-flexible-sliding-menu';
import { useRecoilState } from 'recoil';
import { MenuButtonContainer, PagePlaceholder } from 'components';
import { SpaceDetails } from 'components/SpaceDetails';
import { useLocationContext } from 'contexts/LocationContext';

// superset of source data that will trigger sub-component updates
const mouseEventsTriggerOverSources = new Set(['spaces', 'seats']);

// mouse will turn to pointer over these sources
const mousePointerChangesOverSources = new Set(['spaces', 'seats']);

export const MultiDayApp = () => {
  const [mapState] = useRecoilState(useMapComponentLoadState('test-map'));

  const { selectedLevelId } = useSelectedHierarchy();
  const { toggleMenu } = useContext(MenuContext) as { toggleMenu: () => void };
  const { selectedDates } = useMultiDates();
  const { selectedStartDateTime } = useSelectedDateTime();
  const { timezone } = useLocationContext();

  const [selectedSpaceId, setSelectedSpaceId] = useState<string | undefined>();

  const handleSpaceClick = (e: OnMapClickEvent) => {
    if (
      e.sourceTypes.includes('spaces') ||
      e.sourceTypes.includes('space_labels')
    ) {
      setSelectedSpaceId(e.targetDetails[0].targetId.toString());
    } else {
      setSelectedSpaceId(undefined);
    }
  };

  const handleMapClick = (e: OnMapClickEvent) => {
    handleSpaceClick(e);
  };

  const handleCloseSpaceDetails = useCallback(() => {
    setSelectedSpaceId(undefined);
  }, [setSelectedSpaceId]);

  return (
    <>
      {selectedLevelId ? (
        <>
          <MouseClickComponent onClick={handleMapClick} />
          {mapState.nav.toggleState ? (
            <NavigationControl position="top-right" visualizePitch={true} />
          ) : null}
          <MouseOverComponent
            mouseOverSourceIdentity={mouseEventsTriggerOverSources}
          >
            <SpaceStateHoverComponent />
            <MapMousePointer
              mouseOverSourceIdentity={mousePointerChangesOverSources}
              defaultCursorStyle="default"
            />
          </MouseOverComponent>
          {mapState.layerBackground.toggleState ? null : (
            <HideMapLayerComponent layerId="background" />
          )}
          {mapState.layerFloorplan.toggleState ? null : (
            <HideMapLayerComponent layerId="floorplan" />
          )}
          {mapState.layerFurnishings.toggleState ? null : (
            <HideMapLayerComponent layerId="furnishings" />
          )}
          {mapState.layerDemoWatermark.toggleState ? null : (
            <>
              <HideMapLayerComponent layerId="demo-watermark-1" />
              <HideMapLayerComponent layerId="demo-watermark-2" />
            </>
          )}
          {mapState.layerSpaceFills.toggleState ? null : (
            <HideMapLayerComponent layerId="space-fills" />
          )}
          {mapState.layerSpaces.toggleState ? null : (
            <HideMapLayerComponent layerId="spaces" />
          )}
          {mapState.layerSeats.toggleState ? null : (
            <HideMapLayerComponent layerId="seats" />
          )}
          {mapState.layerSpaceLabels.toggleState ? null : (
            <HideMapLayerComponent layerId="space-labels" />
          )}
          {mapState.layerSeatLabels.toggleState ? null : (
            <HideMapLayerComponent layerId="seat-labels" />
          )}
          {mapState.layerZoneLabels.toggleState ? null : (
            <HideMapLayerComponent layerId="zone-labels" />
          )}
          {mapState.layerSeatAvatars.toggleState ? null : (
            <HideMapLayerComponent layerId="seat_avatars" />
          )}
          {mapState.layerMarkers.toggleState ? null : (
            <HideMapLayerComponent layerId="markers" />
          )}
          {mapState.layerStatusBoards.toggleState ? null : (
            <HideMapLayerComponent layerId="status_boards" />
          )}
          {mapState.layerSeatMarkers.toggleState ? null : (
            <HideMapLayerComponent layerId="seat_markers" />
          )}
          <Map3dButton />
          <SeatStateMultiDayComponnentProgressiveLoad
            dates={selectedDates}
            timezone={timezone}
            startTime={'12:00 AM'}
            durationInMinutes={24 * 60 - 1}
          />
        </>
      ) : (
        <PagePlaceholder />
      )}
      <MenuButtonContainer>
        <ButtonV4
          variant="secondary"
          icon={HamburgerIcon}
          onClick={toggleMenu}
        />
      </MenuButtonContainer>

      {selectedSpaceId && (
        <SpaceDetails
          spaceId={selectedSpaceId}
          startTime={selectedStartDateTime}
          onClose={handleCloseSpaceDetails}
        />
      )}
    </>
  );
};
