import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useMap } from 'react-map-gl';
import { useRecoilValue } from 'recoil';
import { MapIdContext, useMapLoadCompleteAtom } from '../state';
import { SeatDataType } from '../types';
import { mapSeatStateToMapType } from '../dataMaps';
import { GroupingSeatStateMultiDayComponentProgressiveLoad } from './GroupingSeatStateMultiDayComponentProgressiveLoad';
import React from 'react';

type SeatStateComponentProgressiveLoadProperties = {
  seatPerLoadCycle?: number;
  startTime: string | null;
  dates: string[];
  timezone: string;
  durationInMinutes: number;
};

/**
 * Map component that queries GraphQL for a group of seats state, and updates the map with the results.
 * @param seatPerLoadCycle Optional: Number of seats loaded per-iteration within the progressive loading scheme
 * @param startTime Loal timecode start time
 * @param dates Local dates
 * @param timezone IANA timezone
 * @param durationInMinutes duration of reservation check in minutes
 * @returns React component
 */
export const SeatStateMultiDayComponnentProgressiveLoad: FC<
  SeatStateComponentProgressiveLoadProperties
> = ({
  startTime,
  durationInMinutes,
  dates,
  timezone,
  seatPerLoadCycle = 10,
}) => {
  const id = useContext(MapIdContext);
  const map = useMap()[id];
  const mapLoadState = useRecoilValue(useMapLoadCompleteAtom(id));
  const [seatSetState, setSeatSetState] = useState<SeatDataType[]>([]);
  useEffect(() => {
    if (!map || !mapLoadState) {
      return;
    }
    const seatLayerData =
      map.querySourceFeatures('seats', {
        sourceLayer: 'seats',
      }) ?? [];

    const seatMerge: SeatDataType = {};
    const seatSet: SeatDataType[] = [{}];
    let groupingIndex = 0;
    seatLayerData
      .sort((a, b) => {
        // sort by seat ID, so everything loads in the same order each time
        const AID = a.id ?? 0;
        const BID = b.id ?? 0;
        return AID < BID ? 1 : -1;
      })
      .forEach((sl) => {
        if (
          sl.properties?.ownerType === 'seats' &&
          sl.properties.ownerId &&
          sl.id
        ) {
          seatMerge[sl.properties.ownerId] = [
            sl.id,
            ...(seatMerge[sl.properties.ownerId] ?? []),
          ];
        }
      });
    Object.keys(seatMerge).forEach((sl) => {
      if (
        Object.keys(seatSet[groupingIndex] ?? {}).length >= seatPerLoadCycle
      ) {
        groupingIndex += 1;
        seatSet[groupingIndex] = {};
      }
      seatSet[groupingIndex][sl] = seatMerge[sl];
    });

    setSeatSetState(seatSet);
  }, [map, setSeatSetState, mapLoadState, seatPerLoadCycle]);

  useEffect(() => {
    return () => {
      try {
        const seats =
          map?.querySourceFeatures('seats', {
            sourceLayer: 'seats',
          }) ?? [];

        seats.forEach((st) => {
          map?.setFeatureState(
            { source: 'seats', id: st.id },
            mapSeatStateToMapType(null)
          );
        });
      } catch (e) {
        // map can async unmount and become undefined. this is typical when map is in the process of unmounting.
      }
    };
  }, []);

  return useMemo(() => {
    const components = seatSetState.map((seatGroupData) => {
      const seatIds = Object.keys(seatGroupData);
      if (seatIds.length < 1) {
        return null;
      }
      const kid = seatIds[0];
      return (
        <GroupingSeatStateMultiDayComponentProgressiveLoad
          id={id}
          key={kid}
          seatData={seatGroupData}
          startTime={startTime}
          dates={dates}
          durationInMinutes={durationInMinutes}
          timezone={timezone}
        />
      );
    });
    return <>{components}</>;
  }, [seatSetState, id, dates, startTime, durationInMinutes, timezone]);
};
