import { gql, useQuery } from '@apollo/client';
import {
  GetMyGuidelineWithProgressQuery,
  GetMyGuidelineWithProgressQueryVariables,
} from 'generated';

export const GET_MY_GUIDELINE = gql`
  query GetMyGuidelineWithProgress($timezone: String!) {
    getMyGuidelineWithProgress(myTimezone: $timezone) {
      id
      interval {
        intervalType
        frequency
      }
      message
      progress
      tooltipMessage
    }
  }
`;

export const useGetMyGuideline = (timezone: string) =>
  useQuery<
    GetMyGuidelineWithProgressQuery,
    GetMyGuidelineWithProgressQueryVariables
  >(GET_MY_GUIDELINE, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    variables: {
      timezone,
    },
  });
