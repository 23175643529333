import React from 'react';
import { MapIdContext, useMouseOverItemAtom } from '../state';
import { FC, useContext, useEffect, useMemo } from 'react';
import { MapRef, useMap } from 'react-map-gl';
import { useRecoilValue } from 'recoil';

type MapMousePointerPropType = {
  mouseOverSourceIdentity: Set<string>;
  defaultCursorStyle?: string;
};

const MapMousePointerSubComponent: FC<{
  map: MapRef;
  defaultPointer: string;
}> = ({ map, defaultPointer }) => {
  useEffect(() => {
    map.getCanvas().style.cursor = 'pointer';
    return () => {
      map.getCanvas().style.cursor = defaultPointer;
    };
    // this is intended to run once on mount, once on unmount
  }, []);
  return null;
};

/**
 * Map component which uses the mouse over state provided by the `MouseOverComponent`
 * to change the cursor type to pointer when over a targeted source layer type.
 * @param id Identity of map
 * @param mouseOverSourceIdentity List of source layer types that trigger pointer cursor
 * @param defaultCursorStyle Cursor style to return to when the mouse is not over a targeted source layer element
 * @returns React component
 */
export const MapMousePointer: FC<MapMousePointerPropType> = ({
  mouseOverSourceIdentity,
  defaultCursorStyle,
}) => {
  const id = useContext(MapIdContext);
  const map = useMap()[id];
  const mouseOverState = useRecoilValue(useMouseOverItemAtom(id));
  const renderPointer = useMemo(() => {
    if (
      !map ||
      !mouseOverState.sourceTypes.some((e) => mouseOverSourceIdentity.has(e))
    ) {
      return false;
    }
    return true;
  }, [map, mouseOverState, mouseOverSourceIdentity]);
  return renderPointer && map ? (
    <MapMousePointerSubComponent
      map={map}
      defaultPointer={defaultCursorStyle ?? ''}
    />
  ) : null;
};
