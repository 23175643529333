import React, { FC } from 'react';
import styled from '@emotion/styled';

import { BrownFontStack, Colors } from '@robinpowered/design-system';
import { InfoSolid } from '@robinpowered/icons';
import { FONT_SIZES } from 'shared';

interface PolicyViolationsType {
  permissions: string[];
  policies: string[];
}

export const PolicyViolations: FC<{ violations: PolicyViolationsType }> = ({
  violations,
}) => {
  return (
    <PolicyViolationsWrapper>
      {violations.permissions.length > 0 && (
        <PolicyViolationWrapper>
          <InfoIconWrapper color={Colors.Red110} size={16} />
          <PolicyViolationText>{violations.permissions[0]}</PolicyViolationText>
        </PolicyViolationWrapper>
      )}
      {violations.permissions.length === 0 &&
        violations.policies.map((violation) => (
          <PolicyViolationWrapper key={violation}>
            <InfoIconWrapper color={Colors.Red110} size={16} />
            <PolicyViolationText>{violation}</PolicyViolationText>
          </PolicyViolationWrapper>
        ))}
    </PolicyViolationsWrapper>
  );
};

const PolicyViolationText = styled.div`
  color: ${Colors.Red110};
  font-family: ${BrownFontStack};
  font-size: ${FONT_SIZES.SMALL};
  line-height: ${FONT_SIZES.LARGE}px;
  width: calc(100% - 24px);
  word-break: break-word;
  word-wrap: break-word;
`;

const PolicyViolationWrapper = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

const PolicyViolationsWrapper = styled.div`
  display: block;
`;

const InfoIconWrapper = styled(InfoSolid)`
  display: inline-block;
  margin: 2px 8px 0 0;
`;
