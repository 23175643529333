import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

type SingleDayModeSelected = boolean;

export function useSelectedDateType(): SingleDayModeSelected {
  const [searchParams] = useSearchParams();
  const multiDayTime = searchParams.get('mdt');

  const singleDay = useMemo(() => {
    const date = multiDayTime;
    if (date) {
      return false;
    }
    return true;
  }, [multiDayTime]);

  return singleDay;
}
