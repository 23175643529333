import styled from '@emotion/styled';
import { AnnouncementPriorityStatus } from 'generated';
import { FC } from 'react';
import OpenEye from '@robinpowered/icons/VisibilityOn';
import Checkmark from '@robinpowered/icons/Checkmark';
import {
  Body,
  Colors,
  ContextualHelp,
  Skeleton,
} from '@robinpowered/design-system';

export const AnnouncementAnalytics: FC<{
  loading: boolean;
  priority: AnnouncementPriorityStatus | undefined;
  viewCount: number | undefined;
  acknowledgeCount: number | undefined;
  tooltipText: {
    views: string;
    acknowledgements: string;
  };
}> = ({ loading, priority, viewCount, acknowledgeCount, tooltipText }) => {
  return (
    <Container>
      {loading ? (
        <DataLoader />
      ) : (
        <ContextualHelp text={tooltipText.views} placement="bottom">
          <DataWithIcon>
            <OpenEye size={20} />
            <Data>{viewCount}</Data>
          </DataWithIcon>
        </ContextualHelp>
      )}

      {priority === AnnouncementPriorityStatus.Priority &&
        (loading ? (
          <DataLoader />
        ) : (
          <ContextualHelp
            text={tooltipText.acknowledgements}
            placement="bottom"
          >
            <DataWithIcon>
              <Checkmark size={20} />
              <Data>{acknowledgeCount}</Data>
            </DataWithIcon>
          </ContextualHelp>
        ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  padding-top: 16px;
`;

const DataWithIcon = styled.div`
  display: inline-flex;
  align-items: center;
  padding-right: 8px;
`;

const Data = styled(Body.Regular)`
  color: ${Colors.Gray80};
  padding-left: 4px;
`;

const DataLoader = styled(Skeleton)`
  width: 40px;
  height: 24px;
  margin-left: 8px;

  &:first-of-type {
    margin-left: 0;
  }
`;
