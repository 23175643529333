import { BrownFontStack, Colors } from '@robinpowered/design-system';
import styled from '@emotion/styled';
import React, { useEffect, useMemo } from 'react';
import { AnimatedGuidelineIcon } from 'images';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from 'contexts';
import { GuidelineProgressBar } from './GuidelineProgressBar';
import cookie from 'js-cookie';

type OfficePolicyBannerProps = {
  guidelineLabel?: string;
  guidelineFrequency: number;
  daysFulfilled?: number;
};

const GuidelineTextPreviewContainer = styled.div(() => ({
  borderRadius: 4,
  display: 'inline-flex',
  padding: '4px 8px',
  gap: '4px',
  fontWeight: 500,
  fontFamily: BrownFontStack,
  whiteSpace: 'nowrap',
  alignItems: 'center',
}));

const BannerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

// TODO - convert to HeadingSM when new DS kicks in
const GuidelineLabel = styled.span<{ isGoalReached?: boolean }>`
  color: ${({ isGoalReached }): string =>
    isGoalReached ? Colors.GreenProduct : Colors.Magenta100};
`;

const GuidelineFrequency = styled.span`
  color: ${Colors.Gray110};
`;

export const OfficePolicyBanner = ({
  guidelineLabel,
  guidelineFrequency,
  daysFulfilled = 0,
}: OfficePolicyBannerProps): JSX.Element => {
  const { t } = useTranslation();
  const { currentOrg } = useAuthContext();
  const orgSlug = currentOrg?.slug ?? 'orgSlug';
  const cookieName = useMemo(
    () => `${process.env.hwpProgressCookieName}${orgSlug}`,
    []
  );

  const defaultGuidelineLabel = t('In-person policy:');
  const daysPerWeekFrequencyLabel = t(
    guidelineFrequency > 1
      ? `${guidelineFrequency} days/week`
      : `${guidelineFrequency} day/week`
  );

  const previousFulfilled = useMemo(() => {
    const value = cookie.get(cookieName);
    return value ? Number(value) : 0;
  }, []);
  const isGoalReached = daysFulfilled >= guidelineFrequency;
  const hasProgressChanged = previousFulfilled !== daysFulfilled;
  const shouldShowAnimation = isGoalReached && hasProgressChanged;

  useEffect(() => {
    if (daysFulfilled && daysFulfilled !== previousFulfilled) {
      cookie.set(cookieName, `${daysFulfilled}`);
    }
  }, [daysFulfilled, previousFulfilled]);
  return (
    <BannerContainer>
      <GuidelineTextPreviewContainer>
        <AnimatedGuidelineIcon
          size={18}
          variant={isGoalReached ? 'success' : 'default'}
          shouldShowAnimation={shouldShowAnimation}
        />
        <GuidelineLabel isGoalReached={isGoalReached}>
          {guidelineLabel ?? defaultGuidelineLabel}
        </GuidelineLabel>
        <GuidelineFrequency>{daysPerWeekFrequencyLabel}</GuidelineFrequency>
      </GuidelineTextPreviewContainer>
      <GuidelineProgressBar
        guidelineFrequency={guidelineFrequency}
        daysFulfilled={daysFulfilled}
      />
    </BannerContainer>
  );
};
