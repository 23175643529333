import React from 'react';
import './lib/sentry';
import './index.css';
import { AppContainer } from './AppContainer';
import reportWebVitals from './reportWebVitals';
import './i18n';
import { createRoot } from 'react-dom/client';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'react-datepicker/dist/react-datepicker.css';

const container = document.getElementById('root');

if (!container) {
  throw new Error('cant find root mount');
}
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <AppContainer />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
