import { gql, useQuery, QueryResult } from '@apollo/client';
import { useHeaderContext } from '../contexts';
import {
  HeaderActiveOrganization,
  HeaderActiveOrganizationVariables,
} from './__generated__/HeaderActiveOrganization';

const GET_ACTIVE_ORGANIZATION = gql`
  query HeaderActiveOrganization($id: ID!) {
    getOrganizationById(id: $id) {
      id
      locations {
        id
        levels {
          id
          mapUploadStatus
        }
      }
    }
  }
`;

export const useActiveOrganization = (): QueryResult<
  HeaderActiveOrganization,
  HeaderActiveOrganizationVariables
> => {
  const { activeOrg, hasTenant } = useHeaderContext();
  return useQuery<HeaderActiveOrganization, HeaderActiveOrganizationVariables>(
    GET_ACTIVE_ORGANIZATION,
    {
      skip: !activeOrg?.id || !hasTenant,
      variables: {
        id: activeOrg?.id as string,
      },
      nextFetchPolicy: 'cache-and-network',
    }
  );
};
