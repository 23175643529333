import { QueryResult, gql, useQuery } from '@apollo/client';
import {
  UseSeatReservationQuery,
  UseSeatReservationQueryVariables,
} from 'generated';
import { MapInfoContext } from '../state';
import { useContext } from 'react';

const USE_SEAT_RESERVATION_QUERY = gql`
  query UseSeatReservation(
    $startTime: Date!
    $endTime: Date!
    $deskIds: [ID!]!
  ) {
    getDesksByIds(ids: $deskIds) {
      id
      state(startTime: $startTime, endTime: $endTime) {
        id
        reservations {
          id
          accountReservee {
            participationStatus
            visitorId
            user {
              id
              avatar
              name
            }
          }
          visibility
        }
      }
    }
  }
`;

export const useSeatReservationQuery = (
  seatIds: string[],
  startTime: string | null,
  endTime: string | null
): QueryResult<UseSeatReservationQuery, UseSeatReservationQueryVariables> => {
  const { tenantId } = useContext(MapInfoContext);
  const result = useQuery<
    UseSeatReservationQuery,
    UseSeatReservationQueryVariables
  >(USE_SEAT_RESERVATION_QUERY, {
    skip: !tenantId || !startTime || !endTime,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      deskIds: seatIds,
      startTime,
      endTime,
    },
  });

  return result;
};
