import { useMemo, FC, useCallback } from 'react';
import moment from 'moment-timezone';
import styled from '@emotion/styled';
import {
  useSelectedDate,
  useSelectedLocationTimezone,
  useSelectedTime,
} from 'hooks';
import DatePicker from 'react-datepicker';
import { ClockSolid } from 'images';

const StyledClockIcon = styled(ClockSolid)``;

const StyledInput = styled.input`
  margin-left: 8px;
  padding-left: 30px;
`;

const TimePickerSpan = styled.span`
  .react-datepicker-wrapper {
    width: auto%;
    padding-right: 10px;
  }
`;

const StyledTimePicker = styled(DatePicker)`
  cursor: pointer;
`;

export const TimePicker: FC = () => {
  const tzData = useSelectedLocationTimezone();

  const timezone = tzData.data?.getLocationById?.timezone;
  const {
    selectedStartHours,
    selectedStartMinutes,
    setSelectedStartTime,
    selectedEndHours,
    selectedEndMinutes,
    setSelectedEndTime,
  } = useSelectedTime();

  if (!timezone) {
    return null;
  }

  return (
    <>
      <ChildTimePicker
        id={`start-time-picker-${timezone}`}
        timezone={timezone}
        selectedHours={selectedStartHours}
        selectedMinutes={selectedStartMinutes}
        setTime={setSelectedStartTime}
      />
      <ChildTimePicker
        id={`end-time-picker-${timezone}-${selectedStartHours}-${selectedStartMinutes}`}
        timezone={timezone}
        selectedHours={selectedEndHours}
        selectedMinutes={selectedEndMinutes}
        setTime={setSelectedEndTime}
        minimumHours={selectedStartHours}
        minimumMinutes={selectedStartMinutes}
      />
    </>
  );
};

const ChildTimePicker: FC<{
  id: string;
  timezone: string;
  selectedHours: number;
  selectedMinutes: number;
  minimumHours?: number | undefined;
  minimumMinutes?: number | undefined;
  setTime: (hours: number, minutes: number) => void;
}> = ({
  id,
  timezone,
  selectedHours,
  selectedMinutes,
  setTime,
  minimumHours,
  minimumMinutes,
}) => {
  const { safeSelectedDate } = useSelectedDate();

  const minimumDate = useMemo(() => {
    let time = moment.tz(timezone);
    if (minimumHours) {
      time = time.hours(minimumHours);
    }
    if (minimumMinutes) {
      time = time.minutes(minimumMinutes + 30);
    }
    return time.add(-15, 'minutes');
  }, [timezone, minimumHours, minimumMinutes]);

  const selectedTime = useMemo(() => {
    const dt = new Date();
    dt.setHours(selectedHours, selectedMinutes);
    return dt;
  }, [selectedHours, selectedMinutes]);

  const notToday = useMemo(() => {
    return minimumDate.clone().endOf('day').isBefore(safeSelectedDate);
  }, [minimumDate, safeSelectedDate]);

  const filterTimeCallback = useCallback(
    (d: Date) => {
      if (notToday && !(minimumHours && minimumMinutes)) {
        return true;
      }
      const dHours = d.getHours();
      const dMinutes = d.getMinutes();
      const nHours = minimumHours ?? moment.tz(timezone).hours();
      const nMinutes = minimumMinutes
        ? minimumMinutes + 15
        : moment.tz(timezone).minutes() - 15;
      return dHours > nHours || (dHours === nHours && dMinutes >= nMinutes);
    },
    [timezone, notToday, minimumHours, minimumMinutes]
  );

  const timeString = useMemo(() => {
    return moment().hours(selectedHours).minutes(selectedMinutes).format('LT');
  }, [selectedHours, selectedMinutes]);

  return (
    <TimePickerSpan>
      <StyledTimePicker
        id={id}
        customInput={
          <div>
            <StyledClockIcon />
            <StyledInput value={timeString} />
          </div>
        }
        selected={selectedTime}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        timeCaption="time"
        dateFormat="h:mm aa"
        filterTime={filterTimeCallback}
        onChange={(d: Date | null) => {
          if (!d) {
            return;
          }
          setTime(d.getHours(), d.getMinutes());
        }}
      />
    </TimePickerSpan>
  );
};
