import { QueryResult, gql, useQuery } from '@apollo/client';
import { UseSeatDetailsQuery, UseSeatDetailsQueryVariables } from 'generated';
import { MapInfoContext } from '../state';
import { useContext } from 'react';

const SEAT_STATE_QUERY = gql`
  query UseSeatDetails(
    $userId: ID!
    $startTime: Date!
    $endTime: Date!
    $deskId: ID!
  ) {
    getDeskById(id: $deskId) {
      id
      isReservable
      permissions {
        name
        value
      }
      instantAvailabilityAt(time: $startTime) {
        reservation {
          id
          startTime
          seriesId
          confirmation {
            status
          }
          exclusions {
            id
            startTime
            endTime
          }
          endTime
          timeZone
          type
          visibility
          accountReservee {
            email
            user {
              id
              name
              avatar
              isMyFavorite
            }
            visitorId
            participationStatus
          }
        }
        availability
      }
      reservationPolicies {
        id
        seatReservationVisibilityEnabled
        abandonedDeskProtectionEnabled
        abandonedDeskProtectionThreshold
        allowOverlappingReservations
        seatReservationMaxLength
        seatReservationAdvancedBookingThreshold
        allowExclusions
        seatReservationTypesAllowed
      }
      state(startTime: $startTime, endTime: $endTime) {
        id
        exclusions {
          id
          startTime
          endTime
        }
        reservations {
          id
          exclusions {
            id
            startTime
            endTime
          }
          seriesId
          confirmation {
            status
          }
          startTime
          endTime
          timeZone
          type
          visibility
          accountReservee {
            email
            user {
              id
              name
              avatar
              isMyFavorite
            }
            visitorId
            participationStatus
          }
        }
        availability {
          id
          status
          unbookableReasons {
            reason
            value
          }
        }
      }
      type
      name
      zone {
        id
        name
        type
      }
      level {
        id
        name
        maxCapacity
        timezone
        state(startTime: $startTime, endTime: $endTime) {
          freeCapacity
        }
      }
      location {
        id
        name
        state(userId: $userId, startTime: $startTime, endTime: $endTime) {
          freeCapacity
        }
      }
    }
  }
`;

export const useSeatDetailsQuery = (
  userId: string | null | undefined,
  seatId: string | null | undefined,
  startTime: string | null,
  endTime: string | null
): QueryResult<UseSeatDetailsQuery, UseSeatDetailsQueryVariables> => {
  const { tenantId } = useContext(MapInfoContext);
  const result = useQuery<UseSeatDetailsQuery, UseSeatDetailsQueryVariables>(
    SEAT_STATE_QUERY,
    {
      skip: !tenantId || !seatId || !startTime || !endTime || !userId,
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      variables: {
        deskId: seatId ?? '-1',
        startTime,
        endTime,
        userId: userId ?? '',
      },
    }
  );

  return result;
};
