/* eslint-disable @typescript-eslint/no-explicit-any */
import { DeskConfirmationStatus, UseSeatDetailsQuery } from 'generated';
import { useMemo } from 'react';

type GetDeskType = Exclude<
  UseSeatDetailsQuery['getDeskById'],
  null | undefined
>;
type ReservationType = GetDeskType['state']['reservations'][0];

export const usePriorityReservation = (
  reservations: ReservationType[] | null | undefined
) => {
  return useMemo(() => {
    if (!reservations) {
      return null;
    }

    const reverseHotelRes = reservations.find(
      (r) => r.type === 'reverse_hotel'
    );
    if (reverseHotelRes) {
      return reverseHotelRes;
    }

    const declinedRes = reservations.find(
      (r) =>
        r.confirmation?.status &&
        r.confirmation.status === DeskConfirmationStatus.Declined
    );

    if (declinedRes) {
      return declinedRes;
    }

    return reservations[0] ?? null;
  }, [reservations]);
};
