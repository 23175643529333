import { useSeatStateSingleDayQuery } from '../hooks';
import { FC, useEffect } from 'react';
import { useMap } from 'react-map-gl';
import { mapSeatStateToMapType, mapSeatTypeToMapType } from '../dataMaps';
import { SeatDataType } from '../types';

type GroupingSeatStateComponentProgressiveLoadProperties = {
  id: string;
  seatData: SeatDataType;
  startTime: string | null;
  endTime: string | null;
};

/**
 * Map component that queries GraphQL for a group of seats state, and updates the map with the results.
 * @param id Identity of map
 * @param seatData Map of robin seat IDs to map feature element IDs assoicated with that seat
 * @param startTime Start ISO timestamp of seat state
 * @param endTime End ISO timestamp of seat state
 * @returns React component
 */
export const GroupingSeatStateSingleDayComponentProgressiveLoad: FC<
  GroupingSeatStateComponentProgressiveLoadProperties
> = ({ seatData, id, startTime, endTime }) => {
  const map = useMap()[id];
  const seatIds = Object.keys(seatData);
  const seatState = useSeatStateSingleDayQuery(seatIds, startTime, endTime);

  useEffect(() => {
    if (
      !map ||
      !map.isStyleLoaded() ||
      seatState.loading ||
      seatState.error ||
      !seatState.data
    ) {
      return;
    }

    seatState.data.getDesksByIds.forEach((sd) => {
      const newState = Object.assign(
        {},
        mapSeatTypeToMapType(sd.type),
        mapSeatStateToMapType(sd.state.availability.status)
      );

      const seatFeatureIds: Array<string | number> = seatData[sd.id];

      seatFeatureIds.map((sfid) =>
        map.setFeatureState({ source: 'seats', id: sfid }, newState)
      );
    });
  }, [map, seatState, seatIds, seatData]);

  return null;
};
