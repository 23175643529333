import { atomFamily, RecoilEnv } from 'recoil';
import { SeatToAvatarType } from '../types';

RecoilEnv.RECOIL_DUPLICATE_ATOM_KEY_CHECKING_ENABLED =
  process.env.REACT_APP_RECOIL_DUPLICATE_ATOM_KEY_CHECKING_ENABLED !== 'false';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type MapMetadataType = { [key: string]: any };

export const useMapMetaDataAtom = atomFamily<MapMetadataType, string>({
  key: 'mapMetadata',
  default: {} as MapMetadataType,
});

export const useMapLoadCompleteAtom = atomFamily<boolean, string>({
  key: `mapLoadState`,
  default: false,
});

export const useMapIconAtom = atomFamily<SeatToAvatarType, [string, string]>({
  key: `mapIconImage`,
  default: {},
});

type MouseOverElementType = {
  sourceTypes: string[];
  targetDetails: {
    type: string;
    mapId: string | number;
    ownerId: string | number;
  }[];
};

export const useMouseOverItemAtom = atomFamily<MouseOverElementType, string>({
  key: `mouseOverMapElement`,
  default: { sourceTypes: [], targetDetails: [] },
});

export type ComponentElementType = 'Navigation' | 'Avatars';
