import React, { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Colors } from '@robinpowered/design-system';
import { MenuListItem } from './MenuListItem';
import { MenuItem, MenuProps } from './types';

export const StyledMenu = styled.ul<{ isSubMenu?: boolean }>`
  position: absolute;
  z-index: 100;
  top: ${(props) => (props.isSubMenu ? '-8px' : '42px')};
  right: ${(props) => (props.isSubMenu ? 'calc(100% - 4px)' : '0px')};
  min-width: 175px;
  border-radius: 8px;
  background-color: ${Colors.White0};
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15), 0px 1px 12px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  margin: 0;
  padding: 8px 0;
`;

export const MenuSelect: FC<MenuProps> = ({
  isOpen,
  isSubMenu,
  style,
  items,
  onItemClick,
}) => {
  const [selectedSubMenuItem, setSelectedSubMenuItem] =
    useState<MenuItem | null>(null);

  useEffect(() => {
    if (!isOpen) {
      setSelectedSubMenuItem(null);
    }
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return (
    <StyledMenu style={style} isSubMenu={isSubMenu}>
      {items.map((item) => {
        return (
          <MenuListItem
            item={item}
            key={item.label}
            closeSubMenu={() => {
              setSelectedSubMenuItem(null);
            }}
            toggleSubMenu={() => {
              if (selectedSubMenuItem === item) {
                setSelectedSubMenuItem(null);
              } else {
                setSelectedSubMenuItem(item);
              }
            }}
            subMenuIsOpen={item === selectedSubMenuItem}
            renderSubmenu={({ isOpen: isOpenProp, items: subItems }) => {
              return (
                <MenuSelect
                  isOpen={isOpenProp}
                  isSubMenu
                  items={subItems}
                  onItemClick={(submenuItem) => {
                    setSelectedSubMenuItem(submenuItem);
                    onItemClick?.(submenuItem);
                  }}
                />
              );
            }}
            onClick={onItemClick}
          >
            {item.label}
          </MenuListItem>
        );
      })}
    </StyledMenu>
  );
};
