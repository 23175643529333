import { useMemo, FC, useRef, useState, useCallback } from 'react';
import moment, { Moment } from 'moment-timezone';
import styled from '@emotion/styled';
import {
  useSelectedDate,
  useSelectedDateType,
  useSelectedLocationTimezone,
} from 'hooks';
import { SingleDatePicker } from 'react-dates';
import { useClickAway } from 'react-use';
import { BrownFontStack, Colors, Checkbox } from '@robinpowered/design-system';
import { CalendarMenuIcon } from 'images';
import { CalendarNavLeft } from './CalendarNavLeft';
import { CalendarNavRight } from './CalendarNavRight';
import { TimePicker } from './TimePicker';

export const OfficeDateTimePicker: FC = () => {
  const dateDropdownRef = useRef(null);
  const [focused, setFocused] = useState(false);
  useClickAway(dateDropdownRef, () => setFocused(false));

  const singleDayMode = useSelectedDateType();
  const timezoneQuery = useSelectedLocationTimezone();

  const { selectedDate, setSelectedDate, setMultiDateMode } = useSelectedDate();

  const timezone = useMemo(() => {
    return timezoneQuery.data?.getLocationById?.timezone ?? 'America/New_York';
  }, [timezoneQuery]);

  const minimumDate = useMemo(() => {
    moment.tz.setDefault(timezone);
    return moment.tz(timezone);
  }, [timezone]);

  const displayFormat = useMemo(() => {
    return focused ? undefined : 'MMM D';
  }, [focused]);

  const highlightFunc = useCallback(
    (day: Moment): boolean => {
      const startofday = day.clone().startOf('day');
      if (Array.isArray(selectedDate)) {
        return selectedDate.some((sd) =>
          sd.clone().startOf('day').isSame(startofday)
        );
      } else {
        return startofday.isSame(selectedDate.clone().startOf('day'));
      }
    },
    [selectedDate]
  );

  const id = useMemo(() => {
    return `date-id-${timezone}-${JSON.stringify(selectedDate)}`;
  }, [selectedDate, timezone]);

  return (
    <>
      <DatePickerSpan>
        <SingleDatePicker
          id={id}
          enableOutsideDays={true}
          renderWeekHeaderElement={(a) => {
            return <small>{moment().day(a).format('ddd')}</small>;
          }}
          firstDayOfWeek={1}
          onDateChange={(d) => {
            if (!d) {
              return;
            }
            const multiDayMode = Array.isArray(selectedDate);
            const startOfDay = d.format('MM/DD/YYYY');

            if (multiDayMode) {
              const selectStartDays = selectedDate.map((sd) =>
                sd.clone().format('MM/DD/YYYY')
              );

              const selectedSet = new Set(selectStartDays);

              if (selectedSet.has(startOfDay)) {
                selectedSet.delete(startOfDay);
              } else {
                selectedSet.add(startOfDay);
              }
              setSelectedDate(Array.from(selectedSet).map((s) => moment(s)));
            } else {
              setSelectedDate(d);
            }
          }}
          date={Array.isArray(selectedDate) ? null : selectedDate}
          isDayBlocked={(d) => {
            return moment(minimumDate.format('MM DD YYYY')).isAfter(
              d.format('MM DD YYYY')
            );
          }}
          isDayHighlighted={highlightFunc}
          focused={focused}
          onFocusChange={({ focused }) => setFocused(focused)}
          numberOfMonths={1}
          customInputIcon={<CalendarMenuIcon size={16} />}
          displayFormat={displayFormat}
          navPrev={<CalendarNavLeft />}
          navNext={<CalendarNavRight />}
          keepOpenOnDateSelect={singleDayMode ? false : true}
          calendarInfoPosition="bottom"
          renderCalendarInfo={() => (
            <MultiDaySelectionBox>
              <Checkbox
                checked={!singleDayMode}
                handleClick={() => {
                  setMultiDateMode(singleDayMode);
                }}
              />
              <CheckboxLabel>Book multiple days</CheckboxLabel>
            </MultiDaySelectionBox>
          )}
        />
      </DatePickerSpan>
      <TimePicker />
    </>
  );
};

const CheckboxLabel = styled.label`
  display: inline-block;
  padding-left: 8px;
  margin-bottom: 0px;
  cursor: pointer;
  font-family: ${BrownFontStack}
  font-weight: 400;
  font-feature-settings: "ss02";
  font-size: 16px;
  line-height: 18px;
  user-select: none;
`;

const MultiDaySelectionBox = styled.div`
  align-items: center;
  -webkit-box-align: center;
  display: flex;
  flex-direction: row;
  width: 100%;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: ${Colors.Gray30};
  padding: 1rem;
`;

const DatePickerSpan = styled.span`
  .CalendarDay__selected,
  .CalendarDay__highlighted_calendar {
    background: ${Colors.Maroon110};
    color: #fff;
  }
  .CalendarDay__today:not([class*='CalendarDay__selected']) {
    /* todo: styles for today */
  }
  .CalendarDay {
    border: 0px;
    border-radius: 8px;
  }
  .SingleDatePicker_picker {
    /* Important because airbnb html element is direclty styled :'( */
    top: 2.3em !important;
    border-radius: 8px;
  }
  .DayPicker_weekHeader {
    top: 42px;
    border-top: 1px;
    border-color: ${Colors.Gray30};
    border-style: solid;
    width: 100%;
    border-radius: 0px;
    /* Important because airbnb html element is direclty styled :'( */
    padding-top: 4px !important;
  }
  .CalendarMonth_caption_1 {
    padding: 10px 0px 40px 50px;
    margin: 0;
    width: auto;
    text-align: left;
    strong {
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
    }
  }
  .DateInput_fang,
  .DayPickerKeyboardShortcuts_buttonReset {
    display: none;
  }
  .SingleDatePicker,
  .SingleDatePickerInput {
    height: 36px;
  }
  .SingleDatePickerInput_calendarIcon,
  .SingleDatePickerInput_calendarIcon_1 {
    padding: 10px, 0px, 0px, 0px;
    margin: 0px;
  }
  .DateInput,
  .DateInput_input,
  .DateInput_1 {
    margin-left: 4px;
    font-size: 14px;
    font-family: ${BrownFontStack};
    background-color: transparent;
    margin: 0;
    padding: 0;
    border-bottom: 0px;
    cursor: pointer;
  }
  .DateInput_input__focused {
    cursor: auto;
  }
  .SingleDatePickerInput {
    :hover {
      background-color: ${Colors.Gray10};
    }
    background-color: ${Colors.Gray5};
    border: 0px;
    border-radius: 8px;
  }
`;
