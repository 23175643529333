import styled from '@emotion/styled';
import { Colors } from '@robinpowered/design-system';
import { AmplitudeContext, useAuthContext } from 'contexts';
import { useContext } from 'react';
import { MapComponent } from './map';
import {
  LocationSelector,
  OfficeDateTimePicker,
  OfficeTabGuidelinesWrapper,
  OfficeTopBar,
} from 'components';
import {
  useAccessToken,
  useDateCatchup,
  useSelectedDateType,
  useSelectedHierarchy,
} from 'hooks';
import { Header } from 'shared';
import { SingleDayApp } from 'SingleDayApp';
import { MultiDayApp } from 'MultiDayApp';
import { LocationTimezoneProvider } from 'contexts/LocationContext';

export const App = () => {
  const { currentOrg, orgSlug, currentUser } = useAuthContext();
  const { trackEvent } = useContext(AmplitudeContext);
  const singleDayMode = useSelectedDateType();
  const accessToken = useAccessToken();
  const { selectedLevelId } = useSelectedHierarchy();
  useDateCatchup();

  return (
    <LocationTimezoneProvider>
      <Root>
        {currentOrg?.slug && (
          <Header
            activeOrgSlug={currentOrg?.slug}
            amplitudeEventHandler={trackEvent}
          />
        )}
        <Container>
          <OfficeTopBar>
            <LocationSelector />
            <OfficeDateTimePicker />
            <OfficeTabGuidelinesWrapper />
          </OfficeTopBar>
          <div style={{ display: 'flex', width: '100%', height: '100%' }}>
            <MapComponent
              userId={currentUser?.id ?? null}
              tenantId={currentOrg?.id ?? null}
              id="test-map"
              levelId={selectedLevelId}
              defaultCursor="default"
              authorization={`Access-Token ${accessToken}`}
              orgSlug={orgSlug ?? null}
            >
              {singleDayMode ? <SingleDayApp /> : <MultiDayApp />}
            </MapComponent>
          </div>
        </Container>
      </Root>
    </LocationTimezoneProvider>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Container = styled.main`
  background-color: ${Colors.Tan30};
  border-top: 1px solid ${Colors.Tan70};
  width: 100%;
  display: flex;
  justify-content: center;
  flex-flow: column;
  flex-grow: 1;
`;
