import { useTranslation } from 'react-i18next';
import React, { FC, useMemo } from 'react';
import { Availability, BookingPolicyType } from 'generated';
import { Heading } from '@robinpowered/design-system';
import { SeatTypeByline } from 'map/components';
import styled from '@emotion/styled';
import { useDeskPopupContext } from './deskPopupContext';
import { ActionDropdown } from './ActionDropdown';
import { ExclusionInfo } from './ExclusionInfo';

export const DeskHeaderNoReservation: FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { t } = useTranslation<any>('map');
  const { seatData, deskState, hasEncapsulatingExclusion } =
    useDeskPopupContext();

  const podText = useMemo(() => {
    return deskState?.data?.getDeskById?.zone?.name
      ? `, ${deskState.data?.getDeskById?.zone?.name}`
      : null;
  }, [deskState]);

  if (
    !seatData ||
    (deskState?.data?.getDeskById?.state.availability.status &&
      deskState.data.getDeskById.state.availability.status !==
        Availability.Available)
  ) {
    return null;
  }

  return (
    <WrappingElement>
      <DisplayContainer>
        <DisplayText>
          <Heading.Regular>
            {deskState?.data?.getDeskById?.name}
            {podText}
          </Heading.Regular>
        </DisplayText>
        <DisplayActionDropdown>
          <ActionDropdown />
        </DisplayActionDropdown>
      </DisplayContainer>
      <SeatTypeByline>
        {deskState?.data?.getDeskById?.type === BookingPolicyType.Assigned
          ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (t as any)('assigned')
          : // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (t as any)('flexible')}
      </SeatTypeByline>
      <div>
        <ExclusionInfo
          reservation={
            deskState?.data?.getDeskById?.instantAvailabilityAt.reservation
          }
          highlighted={false}
        />
      </div>
    </WrappingElement>
  );
};

const DisplayText = styled.div`
  flex-basis: auto;
`;

const DisplayActionDropdown = styled.div`
  flex-basis: auto;
  margin-left: auto;
`;

const DisplayContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const WrappingElement = styled.div`
  padding: 12px;
`;
