import { QueryResult, gql, useQuery } from '@apollo/client';
import {
  GroupingMapSeatStateQuery,
  GroupingMapSeatStateQueryVariables,
} from 'generated';
import { MapInfoContext } from '../state';
import { useContext } from 'react';

const SEAT_STATE_QUERY = gql`
  query GroupingMapSeatState(
    $startTime: Date!
    $endTime: Date!
    $deskIds: [ID!]!
  ) {
    getDesksByIds(ids: $deskIds) {
      id
      state(startTime: $startTime, endTime: $endTime) {
        id
        availability {
          id
          status
          unbookableReasons {
            reason
            value
          }
        }
      }
      type
    }
  }
`;

export const useSeatStateSingleDayQuery = (
  seatIds: string[],
  startTime: string | null,
  endTime: string | null
): QueryResult<
  GroupingMapSeatStateQuery,
  GroupingMapSeatStateQueryVariables
> => {
  const { tenantId } = useContext(MapInfoContext);
  const result = useQuery<
    GroupingMapSeatStateQuery,
    GroupingMapSeatStateQueryVariables
  >(SEAT_STATE_QUERY, {
    skip: !tenantId || !startTime || !endTime,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      deskIds: seatIds,
      startTime,
      endTime,
    },
  });

  return result;
};
