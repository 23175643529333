import { useQuery, gql } from '@apollo/client';
import {
  UseAnnouncementsAnalyticsQuery,
  UseAnnouncementsAnalyticsQueryVariables,
} from 'generated';

export const ANNOUNCEMENT_ANALYTICS_DATA_QUERY = gql`
  query useAnnouncementsAnalytics($id: ID!) {
    getAnnouncementViewAcknowledgeCount(announcementId: $id) {
      acknowledgeCount
      viewCount
    }
  }
`;

export const useAnnouncementAnalyticsData = (
  id: string,
  canManageAnnouncements: boolean
) => {
  const { loading, data, error } = useQuery<
    UseAnnouncementsAnalyticsQuery,
    UseAnnouncementsAnalyticsQueryVariables
  >(ANNOUNCEMENT_ANALYTICS_DATA_QUERY, {
    skip: !canManageAnnouncements,
    variables: {
      id,
    },
  });

  return {
    loading,
    acknowledgeCount:
      data?.getAnnouncementViewAcknowledgeCount?.acknowledgeCount,
    viewCount: data?.getAnnouncementViewAcknowledgeCount?.viewCount,
    error,
  };
};
