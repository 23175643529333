const GRID_SIZE = 8;

export function spacing(num: number) {
  if ((num * GRID_SIZE) % (GRID_SIZE / 2) === 0) {
    return `${num * GRID_SIZE}px`;
  }
  throw new Error(
    `Spacing must be evenly divisible by ${
      GRID_SIZE / 2
    }. Was given ${num} * ${GRID_SIZE} = ${num * GRID_SIZE}`
  ); // eslint-disable-line max-len
}
