import {
  SpaceAvailability,
  GroupingMapSpaceStateQuery,
  SpaceScheduleConfiguration,
} from 'generated';
import { SpaceStateWithoutMouseFeaturesType } from '../types';

/**
 * Function maps space state to map state type
 * @param availability Space availability
 * @returns Map state
 */
export function mapSpaceStateToMapType(
  availability:
    | GroupingMapSpaceStateQuery['getSpacesByIds'][0]
    | null
    | undefined
): SpaceStateWithoutMouseFeaturesType {
  const initialResult: SpaceStateWithoutMouseFeaturesType = {
    restricted: false,
    reduced: false,
    available: false,
    disabled: false,
    booked: false,
    inUse: false,
    warning: false,
  };

  if (
    !availability ||
    availability.isDisabled ||
    availability.scheduleConfiguration === SpaceScheduleConfiguration.OnDemand
  ) {
    return initialResult;
  }

  switch (availability.confirmedAvailability) {
    case SpaceAvailability.Available:
      initialResult.available = true;
      break;
    case SpaceAvailability.BookedConfirmed:
      initialResult.booked = true;
      break;
    case SpaceAvailability.BookedUnconfirmed:
      initialResult.warning = true;
      break;
    case SpaceAvailability.Unavailable:
    case SpaceAvailability.Unknown:
    default:
      break;
  }
  return initialResult;
}
