import { IControl } from 'maplibre-gl';

type EventHandler = (this: GlobalEventHandlers, ev: MouseEvent) => void;

type MapCustomButtonConstructorArgumentType = {
  className?: string;
  title?: string;
  image?: string | null;
  eventHandler?: EventHandler;
};

export class MapCustomIconButton implements IControl {
  private className: string;
  private title: string;
  private image: string | null;
  private eventHandler: EventHandler | undefined;
  private btn: HTMLButtonElement | null = null;

  constructor({
    className = '',
    title = '',
    image = null,
    eventHandler,
  }: MapCustomButtonConstructorArgumentType) {
    this.className = className;
    this.title = title;
    this.image = image;
    if (eventHandler) {
      this.eventHandler = eventHandler;
    }
  }

  onAdd() {
    this.btn = document.createElement('button');
    this.btn.className = 'maplibregl-ctrl' + ' ' + this.className;
    this.btn.type = 'button';
    this.btn.style.borderRadius = '5px';
    this.btn.style.borderWidth = '1px';
    this.btn.title = this.title;
    this.btn.style.padding = '2px';
    if (this.eventHandler) {
      this.btn.onclick = this.eventHandler;
    }

    if (this.image) {
      const img = document.createElement('img');
      img.src = this.image;
      img.style.width = '1.5rem';
      img.style.padding = '0';
      img.style.margin = '0';
      img.style.cursor = 'pointer';
      this.btn.appendChild(img);
    }

    return this.btn;
  }

  onRemove() {
    this.btn?.parentNode?.removeChild(this.btn);
  }
}
