import React, { FC, ReactNode } from 'react';
import styled from '@emotion/styled';
import { Button, Colors, CoreFontSize } from '@robinpowered/design-system';

const StyledButton = styled(Button)`
  background-color: ${Colors.Maroon100};
  font-size: ${CoreFontSize.s14};
  height: 32px;
  padding: 0 12px;
  word-break: break-word;
  word-wrap: break-word;
`;

const InactiveStyledButton = styled(Button)`
  font-size: ${CoreFontSize.s14};
  height: 32px;
  padding: 0 14px;
  word-break: break-word;
  word-wrap: break-word;

  &:disabled {
    background-color: ${Colors.Tan70};
    color: ${Colors.Gray40};
    cursor: default;
  }
`;

export const ReservationButton: FC<
  { children?: ReactNode } | React.ButtonHTMLAttributes<HTMLButtonElement>
> = (props, ref) => (
  <StyledButton primary {...props} ref={ref} data-cy="reserveDeskBtn">
    {props.children}
  </StyledButton>
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const InactiveReservationButton: FC<
  React.ButtonHTMLAttributes<HTMLButtonElement>
> = (props: React.ButtonHTMLAttributes<HTMLButtonElement>) => (
  <InactiveStyledButton disabled {...props} />
);
