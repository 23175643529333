import { FC, ReactNode } from 'react';
import styled from '@emotion/styled';
import { Colors } from '@robinpowered/design-system';
import { spacing } from 'utilities';

export const OfficeTopBar: FC<{ children?: ReactNode }> = ({ children }) => {
  return <TopBarContainer>{children}</TopBarContainer>;
};

const TopBarContainer = styled.div`
  display: flex;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
  width: 100%;
  background-color: ${Colors.White0};
  align-items: center;
  padding: ${spacing(1.5)} ${spacing(3)} ${spacing(1.5)};
  z-index: 5;
`;
