import moment from 'moment-timezone';
import { useEffect } from 'react';
import { useSelectedDate } from './useSelectedDate';
import { useLocationContext } from 'contexts/LocationContext';

export function useDateCatchup(): void {
  const { selectedDate, setSelectedDate } = useSelectedDate();
  const { timezone } = useLocationContext();

  useEffect(() => {
    const currentDate = moment.tz(timezone).startOf('day');
    if (!Array.isArray(selectedDate) && currentDate.isAfter(selectedDate)) {
      setSelectedDate(currentDate);
      return;
    }
    if (
      Array.isArray(selectedDate) &&
      selectedDate.some((sd) => currentDate.isAfter(sd))
    ) {
      setSelectedDate(
        selectedDate.filter((sd) => currentDate.isSameOrBefore(sd))
      );
    }
  }, [timezone, selectedDate, setSelectedDate]);
}
