import React, { useState, useCallback, useEffect } from 'react';
import styled from '@emotion/styled';
import { Colors } from '@robinpowered/design-system';
import { Button } from '@robinpowered/design-system';
import { useTranslation } from 'react-i18next';

type CheckInButtonsProps = {
  reservationId: string;
  isInActionDropdown?: boolean;
};

export const ReservationLinkButton: React.FC<CheckInButtonsProps> = ({
  reservationId,
  isInActionDropdown,
}) => {
  const [copySuccess, setCopySuccess] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { t }: { t: any } = useTranslation('map');
  const copyButtonText = copySuccess
    ? t('SEATS.LINK_COPIED')
    : t('SEATS.COPY_LINK');
  const shareUrl = `${process.env.REACT_APP_BASE_SHARE_URL}/reservation/${reservationId}`;

  const onCopyShareLink = useCallback(() => {
    navigator.clipboard.writeText(shareUrl).then(() => {
      if (!isInActionDropdown) {
        setCopySuccess(true);
      }
    });
  }, [reservationId, shareUrl, isInActionDropdown]);

  useEffect(() => {
    let ts: ReturnType<typeof setTimeout> | null = null;
    if (copySuccess) {
      ts = setTimeout(() => setCopySuccess(false), 2000);
    }

    return (): void => {
      if (ts) {
        clearTimeout(ts);
      }
    };
  }, [copySuccess]);

  return (
    <CopyLinkButton
      data-cy="copyReservationLinkBtn"
      secondary
      isInActionDropdown={isInActionDropdown}
      onClick={onCopyShareLink}
    >
      {copyButtonText}
    </CopyLinkButton>
  );
};

const CopyLinkButton = styled(Button)`
  color: ${({ isInActionDropdown }): string =>
    isInActionDropdown ? Colors.Gray80 : Colors.Gray100};
`;
