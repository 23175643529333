import styled from '@emotion/styled';
import { Avatar, Body } from '@robinpowered/design-system';
import { FC } from 'react';

export const PeopleSearchResult: FC<{
  name: string;
  src?: string;
  onClick: () => void;
}> = ({ name, src, onClick }) => {
  return (
    <Container>
      <Button onClick={onClick}>
        <Avatar name={name} src={src} size={28} />
        <PersonName>{name}</PersonName>
      </Button>
    </Container>
  );
};

const Container = styled.li`
  padding: 4px 0;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  background-color: unset;
  border: none;
  cursor: pointer;
`;

const PersonName = styled(Body.Small)`
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
