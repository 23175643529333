import { FC, useContext, useEffect, useMemo } from 'react';
import { MapRef } from 'react-map-gl';
import { useRecoilState, useRecoilValue } from 'recoil';
import { MapIdContext, useMapIconAtom, useMapMetaDataAtom } from '../state';
import { useSeatReservationQuery } from '../hooks';
import { DeskReservationVisibility } from 'generated';
import { SeatAvatarComponentProperties } from './seat-avatar-component-properties';
import { SeatToAvatarType } from '../types';
import { getIconImageCondition } from './get-icon-image-condition';

type SeatAvatarSubComponentProperties = Omit<
  SeatAvatarComponentProperties,
  'seatPerLoadCycle'
> & {
  seatIds: string[];
  map: MapRef;
  excludeUserIdentities: Set<string>;
};

export const SeatAvatarSubComponent: FC<SeatAvatarSubComponentProperties> = ({
  seatIds,
  map,
  startTime,
  endTime,
  excludeUserIdentities,
}) => {
  const id = useContext(MapIdContext);
  const metadata = useRecoilValue(useMapMetaDataAtom(id));
  const [iconState, setIconState] = useRecoilState(
    useMapIconAtom([id, `map-icon-${startTime}-${endTime}`])
  );
  const seatReservationData = useSeatReservationQuery(
    seatIds,
    startTime,
    endTime
  );
  const prefix = metadata['robin:avatar-renderer:prefix'];
  const avatarLayers: string[] = metadata['robin:avatar-renderer:seat-layers'];
  useEffect(() => {
    if (seatReservationData.loading || seatReservationData.error) {
      return;
    }
    if (!prefix || !avatarLayers) {
      return;
    }
    const seatsToAvatars: SeatToAvatarType = {};
    seatReservationData.data?.getDesksByIds
      .filter(
        (did) =>
          did.state.reservations.filter((res) =>
            res.visibility === DeskReservationVisibility.Everyone &&
            res.accountReservee?.user?.id
              ? !excludeUserIdentities.has(res.accountReservee.user.id)
              : true
          ).length > 0
      )
      .forEach((did) => {
        const firstReservation = did.state.reservations.find(
          (res) => res.visibility === DeskReservationVisibility.Everyone
        );
        seatsToAvatars[did.id] = {
          image: firstReservation?.accountReservee?.user?.avatar ?? '',
          fallback:
            firstReservation?.accountReservee?.user?.name ||
            firstReservation?.accountReservee?.visitorId ||
            '',
        };
      });
    setIconState({ ...iconState, ...seatsToAvatars });
  }, [seatReservationData, metadata, map, setIconState, prefix, avatarLayers]);

  useMemo(() => {
    if (Object.keys(iconState).length < 1 || !map || !map.getMap()) {
      return;
    }
    const icons = getIconImageCondition(prefix, iconState);
    avatarLayers.forEach((al) => {
      map.getMap().setLayoutProperty(al, 'icon-image', icons);
    });
  }, [iconState, map, prefix, avatarLayers]);

  useEffect(() => {
    return () => {
      if (map && map.loaded())
        avatarLayers.forEach((al) => {
          map.getMap().setLayoutProperty(al, 'icon-image', '');
        });
    };
  }, []);

  return null;
};
