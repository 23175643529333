import React, { FC } from 'react';
import styled from '@emotion/styled';

import {
  BrownFontStack,
  Colors,
  CoreFontSize,
} from '@robinpowered/design-system';

export const DeskInfo: FC<{
  text: string | null;
  src: string | null;
}> = ({ text, src }) => (
  <DeskWrapper>
    {src ? <DeskIconWrapper src={src} /> : null}
    <DeskString>{text}</DeskString>
  </DeskWrapper>
);

const DeskWrapper = styled.div`
  display: flex;
`;

const DeskString = styled.span`
  color: ${Colors.Gray80};
  font-family: ${BrownFontStack};
  font-size: ${CoreFontSize.s14};
  line-height: ${CoreFontSize.s18};
  width: 100%;
  word-break: break-word;
  word-wrap: break-word;
`;

const DeskIconWrapper = styled.img`
  flex-shrink: 0;
  height: 14px;
  line-height: 18px;
  margin-right: 8px;
  margin-top: -1px;
  width: 14px;
`;
