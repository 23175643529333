import { useEffect, useState } from 'react';
import { useMap } from 'react-map-gl';

/**
 * Map hook that responds to image missing events by substituting a special image rendering URL
 * @param mapId Identity of the map
 */
export function useImageMissingHandler(mapId: string) {
  const [imagesInProgress, setImagesInProgress] = useState<{
    [key: string]: boolean;
  }>({});
  const mapRef = useMap();

  useEffect(() => {
    const map = mapRef[mapId]?.getMap();
    if (map) {
      map.on('styleimagemissing', (e) => {
        const metadata = map.getStyle().metadata;
        const prefix = metadata['robin:avatar-renderer:prefix'];
        const id = e.id;
        if (id.indexOf(prefix) !== 0) {
          return;
        }

        if (imagesInProgress[id]) {
          return;
        }

        setImagesInProgress({ ...imagesInProgress, [id]: true });

        const url = map.getStyle().metadata['robin:avatar-renderer:url'];
        const { image, fallback } = JSON.parse(id.replace(prefix, ''));
        map.loadImage(
          url.replace('{srcset}', image).replace('{fallback}', fallback),
          (error, image) => {
            imagesInProgress[id] = false;
            if (error) throw error;
            if (!map.hasImage(id) && image) {
              map.addImage(id, image);
            }
          }
        );
      });
    }
  }, [mapRef, mapId]); // don't execute on changes to images in progress changes
}
