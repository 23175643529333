import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Row } from '@robinpowered/design-system';
import { Colors } from '@robinpowered/design-system';
import OfficeSpace from '../../../../images/OfficeSpace.png';
import { Body07 } from '@robinpowered/design-system';
import { GroupSolid, PhoneSolid, VideoCallSolid } from '@robinpowered/icons';
import { useTranslation } from 'react-i18next';

interface SpaceItemProps {
  name: string;
}

export const SpaceItem: FC<SpaceItemProps> = ({ name }) => {
  const { t } = useTranslation('downloadMobileAppModal');
  return (
    <>
      <Item>
        <SpaceColumn>
          <SpaceName>{name}</SpaceName>
          <SpaceRow>
            <VideoCallSolid size={13} color={Colors.Gray60} />
            <GroupSolid size={13} color={Colors.Gray60} />
            <PhoneSolid size={13} color={Colors.Gray60} />
            <Label>{t('space_item_label')}</Label>
          </SpaceRow>
        </SpaceColumn>
        <SpaceImage src={OfficeSpace} />
      </Item>
      <BookSpaceButton>{t('space_item_button')}</BookSpaceButton>
    </>
  );
};

const SpaceName = styled(Body07)`
  margin-bottom: 2px;
  font-size: 8;
  font-weight: 400;
`;

const Item = styled(Row)`
  flex-direction: row;
  margin-bottom: 5px;
  margin-top: 6px;
`;

const SpaceRow = styled(Row)`
  flex-direction: row;
  gap: 2;
  width: 155px;
`;

const SpaceColumn = styled(Row)`
  flex-direction: column;
`;

const SpaceImage = styled.img`
  width: 45px;
`;

const Label = styled.div`
  font-size: 8px;
  color: ${Colors.Gray60};
  background-color: ${Colors.Gray10};
  border-radius: 2px;
  padding: 1px 2px 1px 4px;
  margin-left: 2px;
`;

const BookSpaceButton = styled.div`
  border: 1px solid ${Colors.Gray30};
  border-radius: 4px;
  padding: 5px 8px 5px;
  width: 40px;
  font-size: 8px;
  font-weight: 500;
`;
