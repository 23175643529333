import common from './common.json';
import error from './error.json';
import perseusTable from './perseusTable.json';
import cta from './cta.json';
import access from './access.json';
import headerMenu from './headerMenu.json';
import userProfileMenu from './userProfileMenu.json';
import downloadMobileAppModal from './downloadMobileAppModal.json';
import map from './map.json';
import people from './people.json';
import announcements from './announcements.json';
import spaces from './spaces.json';

export const en = {
  common,
  error,
  perseusTable,
  cta,
  access,
  headerMenu,
  userProfileMenu,
  downloadMobileAppModal,
  map,
  people,
  announcements,
  spaces,
};
