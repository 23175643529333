import { Availability } from 'generated';
import { SeatStateTypeLessReservationType } from '../types';

/**
 * Function converts seat state availability to map state
 * @param availability Seat availability state
 * @returns Map state translation of seat state availability
 */
export function mapSeatStateToMapType(
  availability: Availability | undefined | null
): SeatStateTypeLessReservationType {
  const initialResult = {
    busy: false,
    selected: false,
    restricted: false,
    reduced: false,
    available: false,
    disabled: false,
    forbidden: false,
    dimmed: false,
  };

  switch (availability) {
    case Availability.Available:
      initialResult.available = true;
      break;
    case Availability.Booked:
      initialResult.busy = true;
      break;
    case Availability.Unavailable:
      initialResult.disabled = true;
      break;
    case Availability.Unknown:
      initialResult.dimmed = true;
      break;
    default:
      break;
  }
  return initialResult;
}
