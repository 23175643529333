import common from './common.json';
import error from './error.json';
import peopleTable from './perseusTable.json';
import cta from './cta.json';
import headerMenu from './headerMenu.json';
import userProfile from './userProfileMenu.json';

export const de = {
  common,
  error,
  peopleTable,
  cta,
  headerMenu,
  userProfile,
};
