import { MapIdContext, useMouseOverItemAtom } from '../state';
import { SpaceStateJustHoverType } from '../types';
import { FC, useContext, useEffect, useMemo } from 'react';
import { MapRef, useMap } from 'react-map-gl';
import { useRecoilValue } from 'recoil';
import React from 'react';

const HOVER_STATE_TRUE: SpaceStateJustHoverType = {
  hover: true,
  focused: true,
};
const HOVER_STATE_FALSE: SpaceStateJustHoverType = {
  hover: false,
  focused: false,
};

const SpaceStateHoverSubComponent: FC<{
  map: MapRef;
  mapSpaceId: string | number;
}> = ({ map, mapSpaceId }) => {
  useEffect(() => {
    const existingState =
      map.getFeatureState({
        source: 'spaces',
        id: mapSpaceId,
      }) ?? {};

    map.setFeatureState(
      { source: 'spaces', id: mapSpaceId },
      Object.assign({}, { ...existingState }, { ...HOVER_STATE_TRUE })
    );

    return () => {
      const secondExistingState =
        map.getFeatureState({
          source: 'spaces',
          id: mapSpaceId,
        }) ?? {};
      map.setFeatureState(
        { source: 'spaces', id: mapSpaceId },
        Object.assign({}, { ...secondExistingState }, { ...HOVER_STATE_FALSE })
      );
    };
    // this is intended to run once on mount, once on unmount
  }, []);
  return null;
};

/**
 * Map component which uses the mouse over state provided by the `MouseOverComponent`
 * to change the space state to reflect the cursor state over the element.
 * @param id Identity of map
 * @returns React component
 */
export const SpaceStateHoverComponent: FC = () => {
  const id = useContext(MapIdContext);
  const map = useMap()[id];
  const mouseOverState = useRecoilValue(useMouseOverItemAtom(id));
  const spacesToRender = useMemo(() => {
    const filteredDetails = mouseOverState.targetDetails.filter(
      (td) => td.type === 'spaces'
    );
    if (map && filteredDetails.length > 0) {
      return Array.from(new Set(filteredDetails.map((td) => td.mapId)));
    }
    return [];
  }, [map, mouseOverState.targetDetails]);
  return spacesToRender.length > 0 && map ? (
    <>
      {spacesToRender.map((sid) => (
        <SpaceStateHoverSubComponent map={map} mapSpaceId={sid} key={sid} />
      ))}
    </>
  ) : null;
};
