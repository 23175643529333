import { gql, useQuery } from '@apollo/client';
import { useApolloContext } from 'contexts';
import {
  UseSingleSpaceDetailsQuery,
  UseSingleSpaceDetailsQueryVariables,
} from 'generated';

const GET_SPACE_BY_ID_QUERY = gql`
  query useSingleSpaceDetails(
    $spaceId: ID!
    $startTime: Date
    $intervalInMinutes: Int
    $numberOfWindows: Int
  ) {
    getSpaceById(id: $spaceId) {
      id
      name
      image
      type
      isAccessible
      calendar {
        calendarId
      }
      amenities {
        name
      }
      capacity
      reservationPolicies {
        id
        abandonedMeetingProtection
        abandonedThreshold
        advanceBookingThreshold
        allowRecurringEvents
        maxReservationLength
        meetingCheckins
        restrictBookingToWorkingHours
        strikesForRecurringEvents
      }
      scheduleConfiguration
      isManaged
      description
      windowedAvailability(
        startTime: $startTime
        intervalInMinutes: $intervalInMinutes
        numberOfWindows: $numberOfWindows
      ) {
        start
        end
        status
      }
      permissions {
        name
        value
      }
    }
  }
`;

export const useSingleSpaceDetails = (
  spaceId: string | null | undefined,
  startTime: string,
  numberOfWindows?: number
) => {
  const { tenantId } = useApolloContext();

  const { loading, data } = useQuery<
    UseSingleSpaceDetailsQuery,
    UseSingleSpaceDetailsQueryVariables
  >(GET_SPACE_BY_ID_QUERY, {
    skip: !tenantId || !spaceId,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      spaceId: spaceId ?? '-1',
      startTime,
      intervalInMinutes: 30, // TODO: Need a UI control for this
      numberOfWindows: numberOfWindows || 4,
    },
  });

  return {
    loading,
    spaceDetails: data?.getSpaceById,
  };
};
