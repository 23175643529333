/* eslint-disable @typescript-eslint/no-explicit-any */
import { useImage } from '../useImage';
import { FC, useMemo } from 'react';
import { useMap } from 'react-map-gl';
import { getGeometricCenter } from './common';
import { UseMySeatReservationQuery } from 'generated';
import { SeatPopupContainer } from './SeatPopupContainer';
import { Heading } from '@robinpowered/design-system';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import React from 'react';

type DeskReservation =
  UseMySeatReservationQuery['getDeskReservationsByUserId']['reservations'][0];

const PopupHolder = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const Avatar = styled.img`
  height: auto;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

const Body = styled(Heading.Regular)`
  display: flex;
  align-items: center;
`;

export const BasicMySeatPopup: FC<{
  mapId: string;
  deskReservation: DeskReservation | undefined | null;
  seatLayerData: mapboxgl.MapboxGeoJSONFeature | null | undefined;
}> = ({ mapId, deskReservation, seatLayerData }) => {
  const map = useMap()[mapId];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { t } = useTranslation<any>('map');
  const imageUrl = useImage(
    mapId,
    deskReservation?.accountReservee?.user?.avatar,
    deskReservation?.accountReservee?.user?.name ||
      deskReservation?.accountReservee?.visitorId
  );
  const position = useMemo(() => {
    if (!seatLayerData) {
      return null;
    }
    return getGeometricCenter(seatLayerData);
  }, [seatLayerData]);

  if (!map || !deskReservation || !seatLayerData || !position) {
    return null;
  }

  return (
    <SeatPopupContainer
      latitude={position.latitude}
      longitude={position.longitude}
    >
      <PopupHolder>
        {imageUrl ? <Avatar src={imageUrl} alt="avatar" /> : null}
        <Body>{(t as any)('your_desk')}</Body>
      </PopupHolder>
    </SeatPopupContainer>
  );
};
