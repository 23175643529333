import { gql, useQuery, QueryResult } from '@apollo/client';
import {
  DeskAllowsIssueReportsQuery,
  DeskAllowsIssueReportsQueryVariables,
} from 'generated';

const DESK_ALLOWS_ISSUE_REPORTS_QUERY = gql`
  query DeskAllowsIssueReports($deskId: ID!) {
    deskAllowsIssueReports(deskId: $deskId) {
      allowed
    }
  }
`;

export const useDeskAllowsIssueReports = (
  deskId: string | number | null | undefined,
  isEnabled?: boolean | undefined
): QueryResult<
  DeskAllowsIssueReportsQuery,
  DeskAllowsIssueReportsQueryVariables
> => {
  return useQuery<
    DeskAllowsIssueReportsQuery,
    DeskAllowsIssueReportsQueryVariables
  >(DESK_ALLOWS_ISSUE_REPORTS_QUERY, {
    variables: { deskId: `${deskId}` },
    skip: !isEnabled || !deskId,
  });
};
