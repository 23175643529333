import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { de } from './de';
import { en } from './en';
import { es } from './es';
import { fr } from './fr';
import { fr_ca } from './fr-ca';

export const defaultNS = 'common';
export const resources = { de, en, es, fr, 'fr-CA': fr_ca } as const;

export const i18n = i18next.use(initReactI18next);

i18n.init({
  defaultNS,
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  resources,
});
