import styled from '@emotion/styled';
import { FC } from 'react';

const ArrowRightImg: FC = () => (
  <svg
    width="7"
    height="9"
    viewBox="0 0 7 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.8125 7.875L5.1875 4.5L1.8125 1.125"
      stroke="#4E6275"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const ArrowRightIconWrapper = styled.span`
  svg {
    width: 7px;
    height: 9px;
  }
`;

export const ArrowRightIcon: FC = () => (
  <ArrowRightIconWrapper>
    <ArrowRightImg />
  </ArrowRightIconWrapper>
);
