import React, { forwardRef, ReactNode } from 'react';
import styled from '@emotion/styled';
import Checkmark from '@robinpowered/icons/v2/Checkmark';
import { BrownFontStack, Colors } from '@robinpowered/design-system';

type DropdownItemProps = {
  className?: string;
  children?: ReactNode;
  selected?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  handleEscape?: () => void;
};

export const DropdownItem = forwardRef<HTMLLIElement, DropdownItemProps>(
  ({ className, children, selected, disabled, onClick, handleEscape }, ref) => {
    const handleKeyUp = (e: React.KeyboardEvent<HTMLLIElement>): void => {
      if (e.key === 'Enter' && !disabled && onClick) {
        onClick();
      }

      if (e.key === 'Escape' && handleEscape) {
        handleEscape();
      }
    };

    return (
      <Container
        tabIndex={0}
        ref={ref}
        className={className}
        disabled={disabled}
        selected={selected}
        onClick={disabled ? undefined : onClick}
        onKeyUp={handleKeyUp}
        role="button"
      >
        {children}
        {selected && <Checkmark size={13} color={Colors.Maroon100} />}
      </Container>
    );
  }
);

type ContainerProps = {
  disabled?: boolean;
  selected?: boolean;
};

const Container = styled.li<ContainerProps>`
  align-items: center;
  display: flex;
  gap: 10px;
  font-family: ${BrownFontStack};
  font-weight: 500;
  padding: 4px 12px;
  list-style: none;
  cursor: pointer;
  color: ${({ selected }): string =>
    selected ? Colors.Maroon100 : Colors.Gray80};
  position: relative;
  &:hover {
    background-color: ${Colors.Tan5};
  }
  &:focus {
    background-color: ${Colors.Tan5};
    outline: 2px solid ${Colors.Maroon100};
  }
  ${(props): string | false | undefined =>
    props.disabled
      ? `
    cursor: default;
    color: ${Colors.Gray40};
    &:hover {
      background-color: transparent;
    }
  `
      : ''}
`;
