import styled from '@emotion/styled';
import { Body, ButtonV4, Colors } from '@robinpowered/design-system';
import { Availability } from 'generated';
import moment, { Moment } from 'moment';
import { FC, useMemo } from 'react';

type BookingAvailability = {
  status?: Availability | null | undefined;
  start?: string;
};

export const SpaceDetailsBooking: FC<{
  isOnDemand?: boolean;
  onDemandText: string;
  nowText: string;
  bookMeetingText: string;
  availability: BookingAvailability[] | undefined;
  unbookableText?: string | null;
}> = ({
  isOnDemand,
  onDemandText,
  nowText,
  bookMeetingText,
  availability,
  unbookableText,
}) => {
  const isBookable = useMemo(() => {
    return !isOnDemand && !unbookableText;
  }, [isOnDemand, unbookableText]);

  return (
    <Container>
      {isOnDemand && <InactivePill>{onDemandText}</InactivePill>}

      {!isOnDemand && !isBookable && <Body.Small>{unbookableText}</Body.Small>}

      {isBookable && (
        <>
          <div>
            {availability &&
              availability.map((a, i) => (
                <BookingPill
                  key={i}
                  isAvailable={a.status === Availability.Available}
                  startTime={a.start || ''}
                  nowText={nowText}
                  onClick={() => {
                    alert('Clicked booking pill');
                  }}
                />
              ))}
          </div>

          <Button
            key={'book-meeting'}
            onClick={() => {
              alert('clicked book meeting');
            }}
            label={bookMeetingText}
            size="small"
          />
        </>
      )}
    </Container>
  );
};

const BookingPill: FC<{
  isAvailable: boolean;
  startTime: string;
  nowText: string;
  onClick: () => void;
}> = ({ isAvailable, startTime, nowText, onClick }) => {
  const start = moment(startTime);
  const now = moment();

  const text = start.isSameOrBefore(now) ? nowText : getFormatedTime(start);

  return (
    <AvailabilityPill
      key={startTime}
      isAvailable={isAvailable}
      onClick={isAvailable ? onClick : undefined}
      label={text}
      size="small"
      disabled={!isAvailable}
    />
  );
};

const getFormatedTime = (start: Moment) => {
  return start.minutes() === 0 ? start.format('h a') : start.format('h:mm a');
};

const Container = styled.div`
  padding-top: 12px;
  display: flex;
  justify-content: space-between;
`;

const Button = styled(ButtonV4)`
  display: inline-block;
  padding: 4px 8px;
  font-size: 12px;
  font-weight: unset;
  background-color: unset;
  border: 1px solid ${Colors.Tan20};
  color: ${Colors.Maroon100};
`;

const AvailabilityPill = styled(Button)<{ isAvailable: boolean }>`
  margin: 0 2px;
  background-color: ${({ isAvailable }) =>
    isAvailable ? Colors.Green100 : Colors.Gray10};

  &:first-of-type {
    margin-left: 0;
  }

  > span {
    color: ${({ isAvailable }) =>
      isAvailable ? Colors.White0 : Colors.Gray10};
  }
`;

const InactivePill = styled(Body.XSmall)`
  background-color: ${Colors.Tan10};
  padding: 4px 8px;
`;
