import {
  useLocationLevels,
  useOrganizationLocations,
  useSelectedHierarchy,
  useSingleSpaceDetails,
} from 'hooks';
import moment from 'moment';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from 'contexts';
import { LeftPanel } from './LeftPanel';
import {
  SpaceDetailsAmenities,
  SpaceDetailsBasic,
  SpaceDetailsBooking,
  SpaceDetailsContent,
  SpaceDetailsImageHeader,
  SpaceDetailsLoading,
  SpaceDetailsNotes,
  SpaceDetailsPolicies,
  SpaceDetailsSection,
  SpaceDetailsTitle,
} from './shared';
import { SpaceReservationPolicies } from 'generated';
import { useUserPermissions } from 'shared/header/hooks';
import styled from '@emotion/styled';
import { ButtonV4, Colors, TextLink } from '@robinpowered/design-system';
import OrgFlagOutline from '@robinpowered/icons/OrgFlagOutline';
import { TFunction } from 'i18next';

export const SpaceDetails: FC<{
  spaceId: string | undefined;
  startTime: string;
  onClose: () => void;
}> = ({ spaceId, startTime, onClose }) => {
  const { t } = useTranslation('spaces');

  const spaceBookingStart = useMemo(() => {
    const start = moment(startTime);
    const minutes = start.minute();

    return minutes < 30
      ? start.startOf('hour').toISOString()
      : start.startOf('hour').add(30, 'minutes').toISOString();
  }, [startTime]);

  const { loading, spaceDetails } = useSingleSpaceDetails(
    spaceId,
    spaceBookingStart
  );

  const { currentOrg } = useAuthContext();
  const { selectedLocationId, selectedLevelId } = useSelectedHierarchy();
  const orgData = useOrganizationLocations();

  const locationName = useMemo(() => {
    if (!orgData || !selectedLocationId || orgData.loading) {
      return null;
    }

    return (
      orgData.locations.find((c) => c.id === selectedLocationId)?.name ?? null
    );
  }, [selectedLocationId, orgData]);

  const locationData = useLocationLevels(selectedLocationId);

  const levelName = useMemo(() => {
    return (
      locationData.data?.getLocationById?.levels.find(
        (l) => l.id === selectedLevelId
      )?.name ?? null
    );
  }, [locationData.data?.getLocationById?.levels, selectedLevelId]);

  const {
    name,
    image,
    type,
    isAccessible,
    calendar,
    isManaged,
    capacity,
    reservationPolicies,
    amenities,
    permissions,
    description: notes,
    windowedAvailability: availability,
  } = spaceDetails || {};

  const showAmenitiesSection = (amenities?.length || 0) > 0;

  const { canManagePlaces } = useUserPermissions(
    currentOrg?.id || null,
    !!currentOrg
  );

  const linkToSpaceInDashboard = useMemo(() => {
    return `${process.env.REACT_APP_DASHBOARD_URL}/${currentOrg?.slug}/locations/${selectedLocationId}/spaces/${spaceId}`;
  }, [currentOrg?.slug, selectedLocationId, spaceId]);

  const linkToManageAmenitiesInDashboard = useMemo(() => {
    // EXISTING BUG: This path seems broken in all envs
    return `${process.env.REACT_APP_DASHBOARD_URL}/${currentOrg?.slug}/manage-map`;
  }, [currentOrg?.slug]);

  const policyTranslations = useMemo(
    () => getTranslatedPolicyText(t, spaceDetails?.reservationPolicies),
    [t, spaceDetails?.reservationPolicies]
  );

  const hasPolicies = useMemo(() => {
    return (
      isManaged ||
      reservationPolicies?.abandonedMeetingProtection ||
      reservationPolicies?.maxReservationLength ||
      reservationPolicies?.advanceBookingThreshold
    );
  }, [
    isManaged,
    reservationPolicies?.abandonedMeetingProtection,
    reservationPolicies?.maxReservationLength,
    reservationPolicies?.advanceBookingThreshold,
  ]);

  const currentUserCanBook = useMemo(() => {
    // TODO: Check to see if this space only allows admin booking
    return permissions?.find((p) => p.name === 'spaces:book')?.value || false;
  }, [permissions]);

  return (
    <LeftPanel title={t('space_details')} onClose={onClose}>
      <>
        <SpaceDetailsContent>
          {loading ? (
            <SpaceDetailsLoading />
          ) : (
            <>
              <SpaceDetailsImageHeader imageUrl={image} />

              <SpaceDetailsSection key="name">
                <SpaceDetailsTitle>{name}</SpaceDetailsTitle>

                <SpaceDetailsBasic
                  location={locationName}
                  level={levelName}
                  capacity={capacity}
                  type={type ? t(`types.${type}`) : undefined}
                  accessibleText={isAccessible ? t('accessible') : undefined}
                />

                <SpaceDetailsBooking
                  isOnDemand={!calendar}
                  onDemandText={t('on_demand')}
                  nowText={t('now')}
                  bookMeetingText={t('book_meeting')}
                  availability={availability}
                  unbookableText={
                    !currentUserCanBook ? t('unable_to_book') : undefined
                  }
                />
              </SpaceDetailsSection>

              {showAmenitiesSection && (
                <SpaceDetailsSection
                  title={t('amenities.title')}
                  linkText={canManagePlaces ? t('amenities.manage') : undefined}
                  linkUrl={
                    canManagePlaces
                      ? linkToManageAmenitiesInDashboard
                      : undefined
                  }
                  key="amenities"
                >
                  <SpaceDetailsAmenities amenities={amenities} />
                </SpaceDetailsSection>
              )}

              {/* TODO: Meeting services */}

              {notes && (
                <SpaceDetailsSection title={t('notes_title')} key="notes">
                  <SpaceDetailsNotes notes={notes} />
                </SpaceDetailsSection>
              )}

              {calendar && hasPolicies && (
                <SpaceDetailsSection title={t('policies.title')} key="policies">
                  <SpaceDetailsPolicies
                    isManaged={isManaged}
                    isCheckinInEnforced={
                      reservationPolicies?.abandonedMeetingProtection || false
                    }
                    managedSpaceTitle={policyTranslations.managedSpaceTitle}
                    managedSpaceSubtitle={
                      policyTranslations.managedSpaceSubtitle
                    }
                    enforcesCheckInTitle={
                      policyTranslations.enforcesCheckInTitle
                    }
                    abandondedThresholdText={
                      policyTranslations.abandondedThresholdText
                    }
                    abandondedStrikeText={
                      policyTranslations.abandondedStrikeText
                    }
                    maxReservationLengthText={
                      policyTranslations.maxReservationLengthText
                    }
                    advancedBookingThresholdText={
                      policyTranslations.advancedBookingThresholdText
                    }
                  />
                </SpaceDetailsSection>
              )}

              <SpaceDetailsSection>
                <ManageLink
                  label={t('view_space') ?? ''}
                  href={linkToSpaceInDashboard}
                  size="Regular"
                />
              </SpaceDetailsSection>

              <SpaceDetailsSection>
                <ManageButton
                  label={t('report_issue')}
                  iconLeft={OrgFlagOutline}
                  size="small"
                  onClick={() => {
                    alert('Open report issue modal');
                  }}
                />
              </SpaceDetailsSection>
            </>
          )}
        </SpaceDetailsContent>
      </>
    </LeftPanel>
  );
};

const ManageLink = styled(TextLink)`
  display: block;
  margin: 0;
  color: ${Colors.Maroon100};
  text-decoration-color: ${Colors.Maroon100};
  padding: 12px 0;
`;

const ManageButton = styled(ButtonV4)`
  background-color: unset;
  color: ${Colors.Maroon100};
  border: 1px solid ${Colors.Tan20};
  font-weight: normal;
`;

const getTranslatedPolicyText = (
  t: TFunction<'spaces', undefined, 'spaces'>,
  policies: SpaceReservationPolicies | undefined
) => {
  return {
    managedSpaceTitle: t('policies.managed_space_title'),
    managedSpaceSubtitle: t('policies.managed_space_subtitle'),
    enforcesCheckInTitle: t('policies.enforces_check_in_title'),
    abandondedThresholdText: policies?.abandonedThreshold
      ? t('policies.abandonded_threshold', {
          length: moment.duration(policies?.abandonedThreshold).humanize(),
        }) || ''
      : undefined,
    abandondedStrikeText: policies?.strikesForRecurringEvents
      ? t('policies.abandonded_strike_count', {
          count: policies?.strikesForRecurringEvents,
        }) || ''
      : undefined,
    maxReservationLengthText: policies?.maxReservationLength
      ? t('policies.max_reservation_length', {
          length: moment.duration(policies.maxReservationLength).humanize(),
        }) || ''
      : undefined,
    advancedBookingThresholdText: policies?.advanceBookingThreshold
      ? t('policies.advanced_booking_threshold', {
          length: moment.duration(policies.advanceBookingThreshold).humanize(),
        }) || ''
      : undefined,
  };
};
