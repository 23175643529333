import styled from '@emotion/styled';
import { Colors, Toggle } from '@robinpowered/design-system';
import { useState } from 'react';
import { HideMapLayerComponent } from 'map';
import { PeopleSearchCard } from './PeopleSearchCard';
import {
  usePeopleWithReservations,
  useSelectedDateTime,
  useSelectedHierarchy,
} from 'hooks';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { PeoplePillContent } from './shared/PeoplePill/PeoplePillContent';
import { PeoplePillButton } from './shared/PeoplePill/PeoplePillButton';

export const PeoplePill = () => {
  const { t } = useTranslation('people');

  const { selectedLevelId } = useSelectedHierarchy();
  const { selectedStartDateTime } = useSelectedDateTime();

  const [isAvatarMapLayerVisible, setIsAvatarMapLayerVisible] =
    useState<boolean>(false);

  const [isSearchCardShown, setIsSearchCardShown] = useState<boolean>(false);

  const { loading, peopleWithReservations } = usePeopleWithReservations({
    levelId: selectedLevelId,
    startTime: selectedStartDateTime,
    endTime: moment(selectedStartDateTime)
      .endOf('day')
      .toISOString()
      .replace(/\.\d+/, ''),
  });

  const toggleAvatarMapLayer = () => {
    setIsAvatarMapLayerVisible((prev) => !prev);
  };

  const toggleSearchCard = () => {
    setIsSearchCardShown((prev) => !prev);
  };

  return (
    <>
      <FloatingPeoplePill>
        <PeoplePillButton
          loading={loading}
          peopleWithReservations={peopleWithReservations}
          label={t('search_for_people')}
          onClick={toggleSearchCard}
        />

        <WithDivider>
          <Toggle
            checked={isAvatarMapLayerVisible}
            onChange={toggleAvatarMapLayer}
            ariaLabelledBy={t('toggle_label')}
            small
          />
        </WithDivider>
      </FloatingPeoplePill>

      {isSearchCardShown && <PeopleSearchCard onClose={toggleSearchCard} />}

      {!isAvatarMapLayerVisible && (
        <HideMapLayerComponent layerId="seat_avatars" />
      )}
    </>
  );
};

const WithDivider = styled.div`
  border-left: 1px solid ${Colors.Gray40};
  padding-left: 8px;
`;

const FloatingPeoplePill = styled(PeoplePillContent)`
  position: absolute;
  bottom: 16px;
  left: 16px;
  width: unset;
`;
