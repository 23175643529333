import { useTranslation } from 'react-i18next';
import { FC, useMemo } from 'react';
import { Availability } from 'generated';
import {
  Colors,
  BrownFontStack,
  CoreFontSize,
  CoreFontWeight,
  Avatar,
} from '@robinpowered/design-system';
import styled from '@emotion/styled';
import moment from 'moment-timezone';
import { useDeskPopupContext } from './deskPopupContext';
import { DeskInfo } from './DeskInfo';
import deskImage from '../../images/desk.svg';
import calImage from '../../images/calendar.svg';
import { useLocationContext } from 'contexts/LocationContext';
import { ActionDropdown } from './ActionDropdown';

const hiddenReservationDisplayName = 'Reserved';

export const DeskHeaderWithReservation: FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { t }: any = useTranslation<any>('map');
  const { seatData, deskState, startTime, endTime } = useDeskPopupContext();
  const { timezone } = useLocationContext();

  const reservee = useMemo(() => {
    return deskState?.data?.getDeskById?.state.reservations[0]?.accountReservee;
  }, [deskState]);

  const displayName = useMemo(() => {
    return reservee?.user?.name ?? reservee?.email ?? '';
  }, [deskState]);

  const descriptionOfDay = useMemo(() => {
    const res = deskState?.data?.getDeskById?.state.reservations[0];
    if (!res || !res.timeZone) {
      return [];
    }

    const rootTime = moment.tz(res.startTime, res.timeZone);

    const endRoot = moment.tz(res.endTime, res.timeZone);

    return [
      rootTime.clone().calendar(null, {
        // when the date is closer, specify custom values
        lastDay: '[Yesterday]',
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        nextWeek: 'MMM Do',
        lastWeek: 'MMM Do',
        sameElse: 'MMM Do',
      }),
      rootTime.clone().format('h:mm A'),
      endRoot.clone().format('h:mm A'),
    ];
  }, [deskState]);

  const podText = useMemo(() => {
    return deskState?.data?.getDeskById?.zone?.name
      ? `, ${deskState.data?.getDeskById?.zone?.name}`
      : null;
  }, [deskState]);

  const scheduleText = useMemo(() => {
    const st = startTime;
    const et = endTime;
    if (!st) {
      return null;
    }
    const startObject = moment.tz(st, timezone);
    const endObject = moment.tz(et, timezone);
    const startOfDay = moment.tz(timezone).startOf('day');
    const isTodaySelected = startOfDay.isSame(
      startObject.clone().startOf('day')
    );

    const dateText = isTodaySelected
      ? t('today')
      : startObject.clone().format('MMM Do');

    if (!et) {
      return dateText;
    }

    const timeSum =
      startObject.clone().hours() +
      startObject.clone().minutes() +
      endObject.clone().hours() +
      endObject.clone().minutes();

    if (timeSum === 0) {
      return `${dateText}, ${t('full_day')}`;
    }

    return `${dateText}, ${startObject.clone().format('LT')} - ${endObject
      .clone()
      .format('LT')}`;
  }, [timezone, t, startTime, endTime]);

  if (
    !seatData ||
    (deskState?.data?.getDeskById?.state.availability.status &&
      deskState.data.getDeskById.state.availability.status !==
        Availability.Booked)
  ) {
    return null;
  }

  return (
    <>
      <StyledReservationHeader>
        <Avatar
          name={reservee?.user?.name ?? 'Un Known'}
          size={44}
          src={reservee?.user?.avatar ?? undefined}
        />
        <HeaderContainer>
          <Header>
            {displayName || hiddenReservationDisplayName}
            {/*
          {showFavorites && (
            <FavoriteUser
              isFavorite={isFavorite}
              handleToggle={handleToggle}
              size={31}
            />
          )}
          */}
            <ActionDropdown />
          </Header>
          <>
            {deskState?.data?.getDeskById?.state.reservations[0]?.type !==
            'assigned' ? (
              <ReservationTime>
                {descriptionOfDay.map((str, index) => (
                  <ReservationTimePart key={`${str}-${index}`}>
                    {str}
                    {index === 0 && ', '}
                    {index === 1 && t('to')}
                  </ReservationTimePart>
                ))}
              </ReservationTime>
            ) : (
              <ReservationTime>{t('assigned')}</ReservationTime>
            )}
          </>
        </HeaderContainer>
      </StyledReservationHeader>
      <StyledReservationHeader>
        <BodyContainer>
          <DeskInfo
            text={`${deskState?.data?.getDeskById?.name}${podText}`}
            src={deskImage}
          />
          <DeskInfo text={scheduleText} src={calImage} />
        </BodyContainer>
      </StyledReservationHeader>
    </>
  );
};

const HeaderContainer = styled.div`
  margin-left: 16px;
  width: 100%;
`;

const BodyContainer = styled.div`
  margin-left: 6px;
  width: 100%;
`;

const StyledReservationHeader = styled.div`
  align-items: center;
  display: flex;
  padding: 5px;
`;

const Header = styled.h4`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
  line-height: 20px;
  word-break: break-word;
  word-wrap: break-word;
  color: ${Colors.Gray100};
  font-family: ${BrownFontStack};
  font-size: ${CoreFontSize.s16};
  font-weight: ${CoreFontWeight.Medium};

  /* compensate for non-standard whitespace embedded in the svg viewBox */
  > div {
    margin: -3px;
  }
`;

const ReservationTime = styled.p`
  color: ${Colors.Gray80};
  font-family: ${BrownFontStack};
  font-size: ${CoreFontSize.s12};
  font-weight: ${CoreFontWeight.Regular};
  line-height: ${CoreFontSize.s18};
  word-break: break-word;
  word-wrap: break-word;
`;

const ReservationTimePart = styled.span`
  display: inline-block;
  margin-right: 2px;
`;
