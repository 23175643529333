import { useMemo } from 'react';
import { ApolloError, gql, useQuery } from '@apollo/client';
import { DashboardAppsHeaderUserPermissions } from './__generated__/DashboardAppsHeaderUserPermissions';

export type UseUserPermissionsData = {
  canManageAmenities: boolean;
  canViewAnalytics: boolean;
  canViewBilling: boolean;
  canViewCheckpoints: boolean;
  canManageDevices: boolean;
  canViewDevices: boolean;
  canManageOfficeAccess: boolean;
  canViewOfficeAccess: boolean;
  canManageGroups: boolean;
  canManagePlaces: boolean;
  canManageRoles: boolean;
  canApproveSpaceRequests: boolean;
  canListUsers: boolean;
  canManageUsers: boolean;
  canManageVisitors: boolean;
  canViewWorkweek: boolean;
  canManageWorkplaceServices: boolean;
  arePersonalCalendarsEnabled: boolean;
  isInsightsFeatureEnabled: boolean;
  totalPendingNotifications: number;
};

type UseUserPermissionsState = {
  error?: ApolloError;
  isLoading: boolean;
};

type UseUserPermissions = UseUserPermissionsState & UseUserPermissionsData;

type RobinOrganization = {
  id: string;
};

const GET_USER_PERMISSIONS = gql`
  query DashboardAppsHeaderUserPermissions {
    getCurrentUserAndOrgs {
      userId

      hasAmenitiesManageForCurrentTenant
      hasAnalyticsPermissionForCurrentTenant
      canSeeApprovalNotificationsForCurrentTenant
      hasBillingPermissionForCurrentTenant
      hasCheckpointsViewPermissionForCurrentTenant
      hasDevicesManageForCurrentTenant
      hasDevicesViewForCurrentTenant
      hasOfficeAccessManagePermissionForCurrentTenant
      hasOfficeAccessViewPermissionForCurrentTenant
      hasGroupsManageForCurrentTenant
      hasPlacesManageForCurrentTenant
      hasRolesManagePermissionForCurrentTenant
      hasListUserPermissionForCurrentTenant
      hasManageUserPermissionForCurrentTenant
      hasVisitsManageForCurrentTenant
      hasWorkplaceServicesManagePermissionForCurrentTenant
      hasWorkweekViewForCurrentTenant

      totalPendingApprovalNotificationsForOrganization

      organizations {
        id
        features {
          insights
          noPersonalCalendars
        }
      }
    }
  }
`;

export const useUserPermissions = (
  activeOrgId: string | null,
  hasTenant: boolean
): UseUserPermissions => {
  const { called, data, loading, error } =
    useQuery<DashboardAppsHeaderUserPermissions>(GET_USER_PERMISSIONS, {
      skip: !activeOrgId || !hasTenant,
      variables: {
        id: activeOrgId as string,
      },
      nextFetchPolicy: 'cache-and-network',
    });

  const permissions = useMemo(() => {
    const { getCurrentUserAndOrgs } = data || {};

    const isInsightsFeatureEnabled =
      getCurrentUserAndOrgs?.organizations.find(
        (org: RobinOrganization): boolean => org?.id === activeOrgId?.toString()
      )?.features?.insights ?? false;

    const arePersonalCalendarsDisabled =
      getCurrentUserAndOrgs?.organizations.find(
        (org: RobinOrganization): boolean => org?.id === activeOrgId?.toString()
      )?.features?.noPersonalCalendars;

    return {
      canApproveSpaceRequests:
        !!getCurrentUserAndOrgs?.canSeeApprovalNotificationsForCurrentTenant,
      canListUsers:
        !!getCurrentUserAndOrgs?.hasListUserPermissionForCurrentTenant,
      canManageAmenities:
        !!getCurrentUserAndOrgs?.hasAmenitiesManageForCurrentTenant,
      canViewAnalytics:
        !!getCurrentUserAndOrgs?.hasAnalyticsPermissionForCurrentTenant,
      canViewBilling:
        !!getCurrentUserAndOrgs?.hasBillingPermissionForCurrentTenant,
      canManageDevices:
        !!getCurrentUserAndOrgs?.hasDevicesManageForCurrentTenant,
      canViewDevices: !!getCurrentUserAndOrgs?.hasDevicesViewForCurrentTenant,
      canManageOfficeAccess:
        !!getCurrentUserAndOrgs?.hasOfficeAccessManagePermissionForCurrentTenant,
      canViewOfficeAccess:
        !!getCurrentUserAndOrgs?.hasOfficeAccessViewPermissionForCurrentTenant,
      canManageGroups: !!getCurrentUserAndOrgs?.hasGroupsManageForCurrentTenant,
      canManagePlaces: !!getCurrentUserAndOrgs?.hasPlacesManageForCurrentTenant,
      canManageRoles:
        !!getCurrentUserAndOrgs?.hasRolesManagePermissionForCurrentTenant,
      canManageUsers:
        !!getCurrentUserAndOrgs?.hasManageUserPermissionForCurrentTenant,
      canManageVisitors:
        !!getCurrentUserAndOrgs?.hasVisitsManageForCurrentTenant,
      canManageWorkplaceServices:
        !!getCurrentUserAndOrgs?.hasWorkplaceServicesManagePermissionForCurrentTenant,
      canViewCheckpoints:
        !!getCurrentUserAndOrgs?.hasCheckpointsViewPermissionForCurrentTenant,
      canViewWorkweek: !!getCurrentUserAndOrgs?.hasWorkweekViewForCurrentTenant,
      arePersonalCalendarsEnabled: !arePersonalCalendarsDisabled,
      isInsightsFeatureEnabled,
      totalPendingNotifications:
        getCurrentUserAndOrgs?.totalPendingApprovalNotificationsForOrganization ??
        0,
    };
  }, [data, activeOrgId]);

  return {
    ...permissions,
    error,
    isLoading: loading || !called,
  };
};
