import { FC } from 'react';
import styled from '@emotion/styled';
import { Colors } from '@robinpowered/design-system';
import { ChevronDown } from '../Icons';
import { Avatar } from './Avatar';

const Button = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  background: none;
  border: none;
  padding: 3px 8px;
  border: 2px solid transparent;
  border-radius: 32px;

  :hover {
    background: ${Colors.Tan5};
  }

  &:focus-visible {
    border-color: ${Colors.Maroon100};
  }
`;

const StyledChevronDown = styled(ChevronDown)<{ open: boolean }>`
  transform: rotate(${(props): string => (props.open ? '180deg' : '0deg')});
  margin-left: 4px;
  width: 8px;
  height: 8px;

  path {
    stroke: ${Colors.Gray80};
    stroke-width: 8px;
  }
`;

type AvatarButtonProps = {
  isOpen: boolean;
  avatar?: string | null;
  userName?: string | null;
  onClick: () => void;
};

export const AvatarButton: FC<AvatarButtonProps> = ({
  isOpen,
  avatar,
  userName,
  onClick,
}) => {
  return (
    <Button
      type="button"
      onClick={onClick}
      aria-label="User Dropdown"
      aria-haspopup="true"
      aria-expanded={isOpen}
    >
      <Avatar src={avatar} name={userName ?? ''} size={32} />
      <StyledChevronDown open={isOpen} />
    </Button>
  );
};
