import { SerializedStyles, css } from '@emotion/react';
import styled from '@emotion/styled';
import { Colors } from '@robinpowered/design-system';

// Speed multiplier of THE WHOLE animation
const animationSpeedMultiplier = 0.4;

// Green outer circle timings
const outerCircleExpandingDuration = animationSpeedMultiplier / 1.1;

// White inner circle timings
const innerCircleExpandingDuration = animationSpeedMultiplier * 1.5;
const innerCircleDelay = animationSpeedMultiplier / 1.3;

// Sparks timings
const sparksDuration = animationSpeedMultiplier * 1.3;
const sparksDelay = animationSpeedMultiplier / 1.2;

export const MainSpark = styled.rect<{
  position?: 'top' | 'bottom' | 'left' | 'right';
}>`
  opacity: 0;
  width: 4px;
  height: 4px;
  fill: ${Colors.GreenProduct};

  animation: main-spark-animation ${sparksDuration}s forwards linear;
  animation-delay: ${sparksDelay}s;
  @keyframes main-spark-animation {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

export const SecondarySpark = styled.circle<{
  position?: 'top-left' | 'bottom-left' | 'top-right' | 'bottom-right';
}>`
  opacity: 0;
  width: 4px;
  height: 4px;
  fill: ${Colors.Green50};

  animation: secondary-spark-animation ${sparksDuration}s forwards linear;
  animation-delay: ${sparksDelay}s;
  @keyframes secondary-spark-animation {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

interface GuidelineProps {
  shouldShowAnimation?: boolean;
}

export const GuidelineDefault = styled.path<GuidelineProps>`
  fill: ${Colors.Magenta100};

  ${({ shouldShowAnimation = false }): SerializedStyles | false =>
    shouldShowAnimation &&
    css`
      animation: guideline-default-animation ${animationSpeedMultiplier}s
        forwards linear;
      transform-origin: center;

      @keyframes guideline-default-animation {
        0% {
          transform: scale(1);
        }
        50% {
          opacity: 0;
          transform: scale(0);
        }
        100% {
          opacity: 0;
        }
      }
    `}
`;

export const GuidelineSuccess = styled.path<GuidelineProps>`
  fill: ${Colors.GreenProduct};

  ${({ shouldShowAnimation = false }): SerializedStyles =>
    shouldShowAnimation
      ? css`
          opacity: 0;
          animation-name: guideline-success-scaling;
          animation-timing-function: linear;
          animation-duration: ${innerCircleExpandingDuration}s;
          animation-delay: ${innerCircleDelay}s;
          animation-fill-mode: forwards;
          transform-origin: center;

          @keyframes guideline-success-scaling {
            0% {
              opacity: 1;
              transform: scale(0.2);
            }
            50% {
              opacity: 1;
              transform: scale(0.6);
            }
            100% {
              opacity: 1;
              transform: scale(1);
            }
          }
        `
      : css`
          opacity: 1;
        `}
`;

export const OuterCircle = styled.circle`
  fill: ${Colors.GreenProduct};

  animation: outer-circle-scaling ${outerCircleExpandingDuration}s forwards
    linear;
  transform-origin: center;

  @keyframes outer-circle-scaling {
    0% {
      opacity: 1;
      transform: scale(0);
    }
    90% {
      opacity: 1;
      transform: scale(1);
    }
    100% {
      opacity: 0;
    }
  }
`;

export const InnerCircle = styled.circle`
  fill: ${Colors.White0};

  opacity: 0;
  animation-name: inner-circle-scaling;
  animation-timing-function: ease-in;
  animation-duration: ${innerCircleExpandingDuration}s;
  animation-delay: ${innerCircleDelay}s;
  animation-fill-mode: forwards;
  transform-origin: center;

  @keyframes inner-circle-scaling {
    0% {
      opacity: 1;
      transform: scale(1.5);
    }
    50% {
      opacity: 1;
      transform: scale(4);
    }
    90% {
      opacity: 0.8;
      transform: scale(6);
    }
    100% {
      opacity: 0;
      transform: scale(6);
    }
  }
`;
