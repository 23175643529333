import { FC } from 'react';
import styled from '@emotion/styled';
import { FONT_SIZES } from 'shared/theme/fontSizes';
import { Colors } from '@robinpowered/design-system';
import { ChevronRight } from '../Icons';
import { MenuListItemProps, MenuItem } from './types';

const StyledListItem = styled.li<{ divider?: boolean }>`
  position: relative;
  display: block;
  border-bottom: ${(props) =>
    props.divider ? `1px solid ${Colors.Tan70}` : 'none'};
`;

const MenuItemLink = styled.a<{ subMenuIsOpen: boolean }>`
  background: none;
  border: none;
  font-size: ${FONT_SIZES.SMALL};
  padding: 8px 16px;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  background-color: ${(props) =>
    props.subMenuIsOpen ? Colors.Maroon100 : Colors.White0};
  color: ${(props) => (props.subMenuIsOpen ? Colors.White0 : Colors.Gray100)};
  line-height: normal;
  text-decoration: none;

  &:hover,
  &:focus-visible {
    background-color: ${Colors.Maroon100};
    color: ${Colors.White0};
  }

  &:focus-visible {
    box-shadow: 0 0 10px ${Colors.Maroon90};
  }

  div {
    flex: 1;
  }

  path {
    stroke: ${(props) => (props.subMenuIsOpen ? Colors.White0 : Colors.Gray40)};
    stroke-width: 6px;
  }
`;

const MenuItemButton = styled(MenuItemLink.withComponent('button'))`
  width: 100%;
`;

export const MenuListItem: FC<MenuListItemProps> = ({
  item,
  renderSubmenu,
  onClick,
  closeSubMenu,
  toggleSubMenu,
  subMenuIsOpen,
}) => {
  const { items, label, divider, href, renderElement } = item;
  const hasSubMenu = items;

  const handleClick = () => {
    if (hasSubMenu) {
      toggleSubMenu();
    } else if (onClick) {
      onClick(item);
      closeSubMenu();
    }
  };

  const handleMouseEnter = () => {
    toggleSubMenu();
  };

  return (
    <StyledListItem divider={divider}>
      {href ? (
        <MenuItemLink
          href={href}
          tabIndex={0}
          subMenuIsOpen={subMenuIsOpen}
          onClick={handleClick}
          onMouseEnter={handleMouseEnter}
        >
          {renderElement !== undefined ? renderElement : <div>{label}</div>}
          {hasSubMenu && (
            <ChevronRight width={11} height={11} stroke={Colors.Gray40} />
          )}
        </MenuItemLink>
      ) : (
        <MenuItemButton
          type="button"
          tabIndex={0}
          subMenuIsOpen={subMenuIsOpen}
          onClick={handleClick}
          onMouseEnter={handleMouseEnter}
        >
          {renderElement !== undefined ? renderElement : <div>{label}</div>}
          {hasSubMenu && (
            <ChevronRight width={11} height={11} stroke={Colors.Gray40} />
          )}
        </MenuItemButton>
      )}
      {hasSubMenu &&
        renderSubmenu?.({
          isOpen: subMenuIsOpen,
          items: items as MenuItem[],
        })}
    </StyledListItem>
  );
};
