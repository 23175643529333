import styled from '@emotion/styled';
import { Colors } from '@robinpowered/design-system';

export const Card = styled.div`
  background-color: ${Colors.White0};
  box-shadow: #00000014 0px 1px 3px;
  padding: 16px;
  border-radius: 8px;
  width: 360px;
`;
