import { MapIdContext } from '../state';
import { FC, useContext } from 'react';
import {
  DeskHeaderNoReservation,
  DeskPopupStyledBoundary,
  DeskPopupLoadingState,
  DeskHeaderWithReservation,
} from './popup';
import { DeskPopupContextProvider } from './popup/deskPopupContext';
import { PoliciesAndActions } from './popup/PoliciesAndActions';

/**
 * Seat popup component. This componenet depends upon the MouseClickComponent pub-sub handling of clicks into the map.
 * This component will not work unless the click handler pub-sub is mounted within the same map.
 *
 */
export const SeatPopup: FC<{
  startTime: string | null;
  endTime: string | null;
  currentUserId: string | null;
}> = (props) => {
  const id = useContext(MapIdContext);

  if (id === '') {
    return null;
  }

  return <SeatPoupupChild {...props} mapId={id} />;
};

const SeatPoupupChild: FC<{
  mapId: string;
  startTime: string | null;
  endTime: string | null;
  currentUserId: string | null;
}> = ({ mapId, startTime, endTime, currentUserId }) => {
  return (
    <DeskPopupContextProvider
      startTime={startTime}
      endTime={endTime}
      mapId={mapId}
      currentUserId={currentUserId}
    >
      <DeskPopupStyledBoundary>
        <DeskPopupLoadingState>
          <DeskHeaderNoReservation />
          <DeskHeaderWithReservation />
          <PoliciesAndActions />
        </DeskPopupLoadingState>
      </DeskPopupStyledBoundary>
    </DeskPopupContextProvider>
  );
};
