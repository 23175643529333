import { useMemo } from 'react';
import { useMap } from 'react-map-gl';

/**
 * Hook to build image URL for provided image url with fallback
 * @param mapId Identity of the map
 * @param imageUrl Url of image to render
 * @param fallback Fallback text to render if image not found
 */
export function useImage(
  mapId: string,
  imageUrl: string | null | undefined,
  fallback: string | null | undefined
) {
  const mapRef = useMap()[mapId];

  const [prefix, renderUrl] = useMemo<[string | null, string | null]>(() => {
    const metadata = mapRef?.getMap().getStyle().metadata;
    return [
      metadata['robin:avatar-renderer:prefix'] ?? null,
      metadata['robin:avatar-renderer:url'] ?? null,
    ];
  }, [mapRef]);

  return useMemo(() => {
    const map = mapRef?.getMap();
    if (map && renderUrl && prefix && (imageUrl || fallback)) {
      return renderUrl
        .replace('{srcset}', imageUrl ?? '')
        .replace('{fallback}', fallback ?? 'Unknown User');
    }
    return null;
  }, [mapRef, mapId, imageUrl, fallback]);
}
