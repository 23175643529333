import { gql, QueryResult, useQuery } from '@apollo/client';
import { useHeaderContext } from '../contexts';
import { FeatureFlagType } from '../__generated__/globalTypes';
import {
  FeatureFlagQuery,
  FeatureFlagQueryVariables,
} from './__generated__/FeatureFlagQuery';

export const FEATURE_FLAG_QUERY = gql`
  query FeatureFlagQuery($flagType: FeatureFlagType) {
    isFeatureFlagEnabledForOrgOrMe(flagType: $flagType)
  }
`;

export const useFeatureFlag = (
  flagType: FeatureFlagType
): QueryResult<FeatureFlagQuery, FeatureFlagQueryVariables> => {
  const { hasTenant } = useHeaderContext();
  return useQuery<FeatureFlagQuery, FeatureFlagQueryVariables>(
    FEATURE_FLAG_QUERY,
    {
      variables: { flagType },
      nextFetchPolicy: 'cache-only',
      skip: !hasTenant,
    }
  );
};
