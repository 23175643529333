import { useQuery, gql } from '@apollo/client';
import { UseAnnouncementQuery, UseAnnouncementQueryVariables } from 'generated';

export const GET_ANNOUNCEMENT_FOR_LOCATION_BY_ID = gql`
  query useAnnouncement($announcementId: ID!, $locationId: ID!) {
    getAnnouncementForLocationById(
      announcementId: $announcementId
      locationId: $locationId
    ) {
      id
      name
      status
      content
      createdAt
      priorityStatus
      viewedAt
      author {
        id
        name
        avatar
      }
    }
    getCurrentUserAndOrgs {
      userId
      hasAnnouncementsManageForCurrentTenant
    }
  }
`;

export const useAnnouncement = (
  locationId: string | null | undefined,
  announcementId: string
) => {
  const { loading, data, error } = useQuery<
    UseAnnouncementQuery,
    UseAnnouncementQueryVariables
  >(GET_ANNOUNCEMENT_FOR_LOCATION_BY_ID, {
    skip: !locationId,
    variables: {
      announcementId,
      locationId: locationId || '-1',
    },
  });

  return {
    loading,
    announcement: data?.getAnnouncementForLocationById,
    canManageAnnouncements:
      data?.getCurrentUserAndOrgs?.hasAnnouncementsManageForCurrentTenant ||
      false,
    error,
  };
};
