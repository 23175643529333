import React, { useEffect, FC, useMemo } from 'react';
import styled from '@emotion/styled';
import { Colors } from '@robinpowered/design-system';
import { ActionBar } from './ActionBar';
import { css } from '@emotion/react';
import {
  DeskReservationVisibility,
  useDeskPopupContext,
} from './deskPopupContext';
import { ReservationLinkButton } from './ReservationLinkButton';
import { InviteeSelection } from './InviteeSelection';

export const ActionSection: FC = () => {
  const {
    setLocalVisibility,
    seatId,
    reservation,
    didRequestToAssign,
    shouldShowActionBar,
    isSeatBooked,
  } = useDeskPopupContext();

  // When a new seat is selected, reset the initial state of the visibility dropdown.
  useEffect(() => {
    setLocalVisibility &&
      setLocalVisibility(DeskReservationVisibility.EVERYONE);
  }, [setLocalVisibility, seatId]);

  // For cases where a user don't have the permissions for the ActionBar component, but still can copy reservation url
  const showBackupCopyReservationButton = useMemo(
    () => !shouldShowActionBar && reservation,
    [reservation, shouldShowActionBar]
  );

  return (
    <ActionSectionWrapper
      isSeatBooked={isSeatBooked}
      isSeatOccupied={Boolean(reservation)}
      isRedirectFFEnabled={false}
    >
      {didRequestToAssign && <InviteeSelection />}

      {shouldShowActionBar && <ActionBar />}

      {showBackupCopyReservationButton && (
        <ReservationButtonWrapper>
          <ReservationLinkButton reservationId={reservation?.id ?? ''} />
        </ReservationButtonWrapper>
      )}
    </ActionSectionWrapper>
  );
};

const ActionSectionWrapper = styled.div<{
  isSeatBooked?: boolean;
  isSeatOccupied?: boolean;
  isRedirectFFEnabled?: boolean;
}>`
  ${({ isSeatBooked, isSeatOccupied, isRedirectFFEnabled }) => {
    if (isRedirectFFEnabled) {
      return css`
        border-top: ${isSeatOccupied ? `1px solid ${Colors.Tan70}` : 'none'};
        background-color: ${Colors.Gray5}};
      `;
    }

    if (!isSeatBooked) {
      return css`
        background-color: ${Colors.Tan5};
        border-top: 1px solid ${Colors.Tan70};
        padding: 8px;
      `;
    }

    return css`
      background-color: ${Colors.White0};
      border-top: none;
      padding: 0;
    `;
  }};

  border-radius: 0 0 8px 8px;
`;

const ReservationButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;

  padding: 8px 12px;

  &:empty {
    padding: 0;
  }

  > button {
    width: calc(50% - 4px);
  }
`;
