import styled from '@emotion/styled';

export const SpaceDetailsImageHeader = styled.div<{
  imageUrl: string | undefined | null;
  height?: string;
}>`
  height: ${({ height }) => height || '200px'};
  background-size: cover;
  background-position: center center;
  background-image: url(${({ imageUrl }) => imageUrl});
  border-radius: 4px;
  margin-bottom: 12px;
`;
