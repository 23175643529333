import { gql, useMutation, MutationTuple } from '@apollo/client';
import {
  ReportIssueWithDeskMutation,
  ReportIssueWithDeskMutationVariables,
} from 'generated';

const REPORT_ISSUE_WITH_DESK_MUTATION = gql`
  mutation ReportIssueWithDesk($input: ReportIssueWithDeskInput!) {
    reportIssueWithDesk(input: $input) {
      id
    }
  }
`;

export const useReportIssueWithDesk = (): MutationTuple<
  ReportIssueWithDeskMutation,
  ReportIssueWithDeskMutationVariables
> => {
  return useMutation<
    ReportIssueWithDeskMutation,
    ReportIssueWithDeskMutationVariables
  >(REPORT_ISSUE_WITH_DESK_MUTATION);
};
