import { useQueryParams } from 'hooks';

type HierarchySelections = {
  selectedLocationId: string | null | undefined;
  selectedLevelId: string | null | undefined;
  selectedCampusId: string | null | undefined;
};

export function useSelectedHierarchy(): HierarchySelections {
  const queryParams = useQueryParams();
  const selectedLocationId = queryParams.get('locationId');
  const selectedLevelId = queryParams.get('levelId');
  const selectedCampusId = queryParams.get('campusId');
  return {
    selectedLevelId,
    selectedLocationId,
    selectedCampusId,
  };
}
