import { useMemo } from 'react';
import { useSelectedDate } from './useSelectedDate';
import { useSelectedTime } from './useSelectedTime';

export function useSelectedDateTime(): {
  selectedStartDateTime: string;
  selectedEndDateTime: string;
} {
  const { safeSelectedDate } = useSelectedDate();
  const {
    selectedStartHours,
    selectedStartMinutes,
    selectedEndHours,
    selectedEndMinutes,
  } = useSelectedTime();

  const startTimestamp = useMemo(() => {
    return safeSelectedDate
      .clone()
      .hours(selectedStartHours)
      .minutes(selectedStartMinutes);
  }, [safeSelectedDate, selectedStartHours, selectedStartMinutes]);

  const endTimestamp = useMemo(() => {
    return safeSelectedDate
      .clone()
      .hours(selectedEndHours)
      .minutes(selectedEndMinutes);
  }, [safeSelectedDate, selectedEndHours, selectedEndMinutes]);

  return {
    selectedStartDateTime: startTimestamp.toISOString().replace(/\.\d+/, ''),
    selectedEndDateTime: endTimestamp.toISOString().replace(/\.\d+/, ''),
  };
}
