import styled from '@emotion/styled';
import { Body, Colors } from '@robinpowered/design-system';
import { FC } from 'react';

export const SpaceDetailsPolicies: FC<{
  isManaged: boolean | undefined;
  isCheckinInEnforced: boolean;
  managedSpaceTitle: string;
  managedSpaceSubtitle: string;
  enforcesCheckInTitle: string;
  abandondedThresholdText?: string;
  abandondedStrikeText?: string;
  maxReservationLengthText?: string;
  advancedBookingThresholdText?: string;
}> = ({
  isManaged,
  isCheckinInEnforced,
  managedSpaceTitle,
  managedSpaceSubtitle,
  enforcesCheckInTitle,
  abandondedThresholdText,
  abandondedStrikeText,
  maxReservationLengthText,
  advancedBookingThresholdText,
}) => {
  return (
    <>
      {isManaged && (
        <>
          <PolicySectionTitle>{managedSpaceTitle}</PolicySectionTitle>

          <PolicyList>
            <PolicyItem>{managedSpaceSubtitle}</PolicyItem>
          </PolicyList>
        </>
      )}

      {isCheckinInEnforced && (
        <PolicySectionTitle>{enforcesCheckInTitle}</PolicySectionTitle>
      )}

      <PolicyList>
        {isCheckinInEnforced && enforcesCheckInTitle && (
          <>
            {abandondedThresholdText && (
              <PolicyItem>{abandondedThresholdText}</PolicyItem>
            )}

            {abandondedStrikeText && (
              <PolicyItem>{abandondedStrikeText}</PolicyItem>
            )}
          </>
        )}

        {maxReservationLengthText && (
          <PolicyItem>{maxReservationLengthText}</PolicyItem>
        )}

        {advancedBookingThresholdText && (
          <PolicyItem>{advancedBookingThresholdText}</PolicyItem>
        )}
      </PolicyList>
    </>
  );
};

const PolicySectionTitle = styled(Body.Regular)`
  font-size: 14px;
  margin-bottom: 8px;
  color: ${Colors.Gray80};
`;

const PolicyList = styled.ul`
  margin: 9px 24px;
  list-style: disc;
`;

const PolicyItem = styled.li`
  font-size: 14px;
  color: ${Colors.Gray80};
  margin-bottom: 8px;

  &::marker {
    font-size: 20px;
  }
`;
