import { FC, useEffect, useState } from 'react';
import { ActionSection } from './ActionSection';
import styled from '@emotion/styled';
import { Colors, Heading } from '@robinpowered/design-system';
import { PolicyViolations } from './PolicyViolations';
import { useTranslation } from 'react-i18next';
import { useDeskPopupContext } from './deskPopupContext';

export const PoliciesAndActions: FC = () => {
  const {
    bookingPolicyViolations,
    isSeatBooked,
    didRequestToAssign,
    setRequestToAssign,
    invitee,
    setInvitee,
    canReverseHotel,
    deskState,
    hasAllConfirmedNoReservations,
  } = useDeskPopupContext();
  const { t }: any = useTranslation('map');
  // Resets request to assign to false and set currently selected invitee to null
  const resetAssignState = () => {
    setRequestToAssign(false);
    setInvitee(null);
  };

  // Determines if the current user is reservee of a selected seat reservation

  useEffect(() => {
    // When switching from a available seat with an opened invitee search
    // to a booked seat, make sure to close invitee search and clear invitee
    if (
      deskState?.data?.getDeskById?.state.reservations &&
      deskState?.data?.getDeskById?.state.reservations.length > 0 &&
      !canReverseHotel &&
      !hasAllConfirmedNoReservations &&
      (didRequestToAssign || invitee)
    ) {
      resetAssignState();
    }
  }, [
    deskState,
    canReverseHotel,
    invitee,
    didRequestToAssign,
    hasAllConfirmedNoReservations,
  ]);

  // Determines if booking policy violation messages should be shown
  const shouldShowBookingPolicyViolations =
    !isSeatBooked &&
    (bookingPolicyViolations.permissions.length > 0 ||
      bookingPolicyViolations.policies.length > 0);

  const bookingPolicies = t('SEAT_RESERVATION_POLICIES.BOOKING_POLICIES');
  const setByAdmin = t('SEAT_RESERVATION_POLICIES.POLICIES_SET_BY_ADMIN');

  return (
    <>
      {shouldShowBookingPolicyViolations && (
        <PoliciesSection
          shouldShowBookingPolicyViolations={shouldShowBookingPolicyViolations}
        >
          <PoliciesTitle>{bookingPolicies}</PoliciesTitle>
          <PoliciesSubtitle>{setByAdmin}</PoliciesSubtitle>
          <PolicyViolations violations={bookingPolicyViolations} />
        </PoliciesSection>
      )}
      {!shouldShowBookingPolicyViolations && <ActionSection />}
    </>
  );
};

const PoliciesTitle = styled(Heading.Regular)`
  color: ${Colors.Gray100};
  margin-bottom: 0;
`;

const PoliciesSection = styled.div<{
  shouldShowBookingPolicyViolations: boolean;
}>`
  background-color: ${Colors.White0};
  padding: 12px;
  border-top: 1px solid ${Colors.Gray10};
  border-radius: ${({ shouldShowBookingPolicyViolations }) =>
    shouldShowBookingPolicyViolations ? ' 0 0 8px 8px' : '0'};
`;

const PoliciesSubtitle = styled(Heading.Small)`
  color: ${Colors.Gray80};
  margin-bottom: 8px;
`;
