import { FC, useRef, useState } from 'react';
import { useClickAway, useKey } from 'react-use';
import styled from '@emotion/styled';
import { FONT_WEIGHTS, FONT_SIZES } from 'shared/theme';
import { useHeaderContext } from '../../contexts';
import { MenuSelect } from '../../components';
import { DropdownIcon } from './DropdownIcon';
import { buildUserOrgMenu } from './buildUserOrgMenu';
import { OrgMenuButton } from './OrgMenuButton';

const OrgName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  vertical-align: middle;
  font-weight: ${FONT_WEIGHTS.ZESTY};
  font-size: ${FONT_SIZES.SMALL};
`;

export const OrganizationDropdown: FC = () => {
  const { activeOrg, userOrganizations, isLoading, error } = useHeaderContext();
  const [menuOpen, setMenuOpen] = useState(false);

  const pathParts = (window?.location?.pathname ?? '').split('/');
  pathParts.splice(1, 1); // Remove the org from the path
  const pathWithoutOrg = pathParts.join('/');
  const orgAriaLabel = `Select an organization - ${activeOrg?.name} currently selected`;

  const menuRef = useRef(null);
  useClickAway(menuRef, () => {
    setMenuOpen(false);
  });

  useKey('Escape', () => setMenuOpen(false));

  if (isLoading || error || !userOrganizations?.length) {
    return null;
  }

  return (
    <OrgMenuButton
      ref={menuRef}
      onClick={() => setMenuOpen((open) => !open)}
      aria-label={orgAriaLabel}
      aria-expanded={menuOpen}
      aria-haspopup="true"
    >
      {/* eslint-disable-next-line no-nested-ternary */}
      {activeOrg?.logo ? (
        <img
          id="1"
          src={activeOrg.logo}
          alt={''}
          style={{ maxHeight: 32, maxWidth: 120 }}
        />
      ) : activeOrg?.avatar ? (
        <img
          src={activeOrg.avatar}
          alt={''}
          style={{ maxHeight: 32, borderRadius: '50%' }}
        />
      ) : (
        <OrgName>{activeOrg?.name}</OrgName>
      )}
      <DropdownIcon open={menuOpen} />
      <MenuSelect
        style={{
          top: 'calc(100% + 4px)',
          left: 0,
          width: '275px',
          maxHeight: '375px',
          overflowX: 'hidden',
          overflowY: 'auto',
        }}
        isOpen={menuOpen}
        items={buildUserOrgMenu(userOrganizations, pathWithoutOrg, activeOrg)}
        onItemClick={() => setMenuOpen(false)}
      />
    </OrgMenuButton>
  );
};
