/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import styled from '@emotion/styled';
import {
  Heading01 as Heading01Base,
  Heading02 as Heading02Base,
  Heading03 as Heading03Base,
  Heading04 as Heading04Base,
  Heading05 as Heading05Base,
  Heading06 as Heading06Base,
  Body05 as Body05Base,
  Colors,
} from '@robinpowered/design-system';

type MarkdownRendererProps = {
  markdownString: string;
};

// Disables footnotes, tables and checkboxes
const disallowedElements = [
  'sup',
  'table',
  'tbody',
  'td',
  'th',
  'tr',
  'thead',
  'input',
  'code',
];

export const MarkdownRenderer: FC<MarkdownRendererProps> = ({
  markdownString,
}) => {
  return (
    <ReactMarkdown
      components={{
        h1: ({ children, node, ...props }) => (
          <Heading01 {...props} mb={3}>
            {children}
          </Heading01>
        ),
        h2: ({ children, node, ...props }) => (
          <Heading02 {...props} mb={3}>
            {children}
          </Heading02>
        ),
        h3: ({ children, node, ...props }) => (
          <Heading03 {...props} mb={3}>
            {children}
          </Heading03>
        ),
        h4: ({ children, node, ...props }) => (
          <Heading04 {...props} mb={3}>
            {children}
          </Heading04>
        ),
        h5: ({ children, node, ...props }) => (
          <Heading05 {...props} mb={3}>
            {children}
          </Heading05>
        ),
        h6: ({ children, node, ...props }) => (
          <Heading06 {...props} mb={3}>
            {children}
          </Heading06>
        ),
        p: ({ children, node, ...props }) => (
          <Paragraph {...props}>
            <Body05 color={Colors.Gray100} mb={2}>
              {children}
            </Body05>
          </Paragraph>
        ),
        li: ({ children, node, ...props }) => (
          <ListItem {...props}>
            <Body05 color={Colors.Gray100}>{children}</Body05>
          </ListItem>
        ),
        del: ({ children, node, ...props }) => <Del>{children}</Del>,
        ul: ({ children }) => <UnorderedList>{children}</UnorderedList>,
        a: ({ children, ...props }) => (
          <Link target="_blank" {...props}>
            {children}
          </Link>
        ),
        strong: ({ children, node, ...props }) => <Strong>{children}</Strong>,
        em: ({ children, node, ...props }) => <Em>{children}</Em>,
      }}
      remarkPlugins={[remarkGfm]}
      disallowedElements={disallowedElements}
    >
      {markdownString}
    </ReactMarkdown>
  );
};

const Heading01 = styled(Heading01Base)``;
const Heading02 = styled(Heading02Base)``;
const Heading03 = styled(Heading03Base)``;
const Heading04 = styled(Heading04Base)``;
const Heading05 = styled(Heading05Base)``;
const Heading06 = styled(Heading06Base)``;
const Body05 = styled(Body05Base)`
  display: inline;
`;

const Del = styled.del`
  vertical-align: baseline;
`;

const Paragraph = styled.p`
  color: ${Colors.Gray100};
  line-height: normal;
  overflow-wrap: break-word;
`;

const ListItem = styled.li`
  ul {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const UnorderedList = styled.ul`
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: 24px;
  list-style: disc;
`;

const Link = styled.a`
  color: ${Colors.Maroon100};
`;

const Em = styled(Body05)`
  font-style: italic;
`;

const Strong = styled(Body05)`
  font-weight: 500;
`;
