import React, { FC } from 'react';
import styled from '@emotion/styled';
import { BrownFontStack, Colors } from '@robinpowered/design-system';

const Container = styled.span(() => ({
  fontFamily: BrownFontStack,
  fontSize: 14,
  color: Colors.White0,
  textAlign: 'center',
  width: 20,
  height: 20,
  display: 'inline-block',
}));

const Loader: FC<{ stroke: string; alt: string }> = ({ stroke, ...props }) => (
  <svg width={18} height={18} viewBox="-1 -1 40 40" stroke={stroke} {...props}>
    <g
      transform="translate(1 1)"
      strokeWidth={2.6}
      fill="none"
      fillRule="evenodd"
    >
      <circle strokeOpacity={0.3} cx={18} cy={18} r={18} />
      <path d="M36 18c0-9.94-8.06-18-18-18" />
    </g>
  </svg>
);

const StyledLoadingIcon = styled(Loader)`
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  path {
    animation: rotate 1s infinite linear;
    transform-origin: 18px 18px;
  }
`;

export default function InlineLoader({ color = Colors.Gray80, ...props }) {
  return (
    <Container {...props}>
      <StyledLoadingIcon alt="loader" stroke={color} />
    </Container>
  );
}
