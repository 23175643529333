import { BrowserRouter, Routes, Route, useParams } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Global } from '@emotion/react';
import { RKTheme } from '@robinpowered/design-system';
import { cssReset } from 'shared';
import {
  AmplitudeProvider,
  ApolloContextProvider,
  AuthProvider,
  LocalizationProvider,
  useAuthContext,
} from './contexts';
import MenuProvider from 'react-flexible-sliding-menu';
import { GlobalErrorBoundary } from 'components/GlobalErrorBoundary';
import RedirectToDashboardHomepage from 'components/RedirectToDashboardHomepage';
import { MapComponentMenu, PageMessage, RobinLoader } from 'components';
import { useEffect } from 'react';
import { App } from 'App';
import { config } from './config';
import Compose from 'contexts/Compose';
import 'maplibre-gl/dist/maplibre-gl.css';
import { MapProvider } from 'react-map-gl';
import { MapGlobalContext } from 'map';

const { robinEnv } = config;

export const DocumentHead = (): JSX.Element => {
  const { t } = useTranslation('common');
  return (
    <Helmet>
      <title>{t('default_page_title')}</title>
    </Helmet>
  );
};

const AuthController = (): JSX.Element => {
  const { t } = useTranslation('error');
  const { orgSlug } = useParams<'orgSlug'>();
  const { loading: isAuthLoading, setOrgSlug, isLoggedIn } = useAuthContext();

  const loading = isAuthLoading;

  useEffect(() => setOrgSlug(orgSlug), [orgSlug, setOrgSlug]);

  useEffect(() => {
    const redirectToDashboard =
      !loading && robinEnv !== 'development' && !isLoggedIn;

    if (redirectToDashboard) {
      window.location.href = config.dashboardUrl;
    }
  }, [loading, isLoggedIn]);

  switch (true) {
    case loading:
      return <RobinLoader />;

    case !isLoggedIn && robinEnv === 'development':
      return <PageMessage title={t('unauthenticated')} />;

    default:
      return <App />;
  }
};

const RootRouteTree = (): JSX.Element => {
  const { t } = useTranslation('error');
  return (
    <Routes>
      <Route path="/" element={<PageMessage title={t('missing_slug')} />} />
      <Route path="*" element={<RedirectToDashboardHomepage />} />
      <Route path=":orgSlug/perseus" element={<AuthController />} />
    </Routes>
  );
};

export const AppContainer = (): JSX.Element => {
  return (
    <GlobalErrorBoundary>
      <MapGlobalContext>
        <MenuProvider MenuComponent={MapComponentMenu}>
          <Global styles={cssReset} />
          <Compose
            components={[
              RKTheme,
              BrowserRouter,
              ApolloContextProvider,
              AuthProvider,
              LocalizationProvider,
              HelmetProvider,
              AmplitudeProvider,
              MapProvider,
            ]}
          >
            <DocumentHead />
            <RootRouteTree />
          </Compose>
        </MenuProvider>
      </MapGlobalContext>
    </GlobalErrorBoundary>
  );
};
