import { MapRef } from 'react-map-gl';

type SeatIdentity = string;

export function getSeatIdentitiesFromMap(map: MapRef): Set<SeatIdentity> {
  const seatLayerData =
    map?.querySourceFeatures('seats', {
      sourceLayer: 'seats',
    }) ?? [];
  const cleanSet = new Set<string>();
  seatLayerData.forEach((sld) => {
    if (sld.properties?.ownerId) {
      cleanSet.add(sld.properties.ownerId);
    }
  });
  return new Set<SeatIdentity>(cleanSet);
}
