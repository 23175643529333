import { QueryResult, gql, useQuery } from '@apollo/client';
import {
  GroupingMapSeatStateMultiDayQuery,
  GroupingMapSeatStateMultiDayQueryVariables,
} from 'generated';
import { MapInfoContext } from '../state';
import { useContext } from 'react';

``;

const SEAT_STATE_QUERY_MULTI_DAY = gql`
  query GroupingMapSeatStateMultiDay(
    $deskIds: [ID!]!
    $dates: [LocalDate!]!
    $startTime: LocalTime!
    $durationInMinutes: Int!
    $timezone: IANATimezone!
  ) {
    reservationsMultiDayGroupedByDate(
      deskIds: $deskIds
      dates: $dates
      startTime: $startTime
      durationInMinutes: $durationInMinutes
      timezone: $timezone
    ) {
      deskId
      dates
      timezone
      type
      availability
    }
  }
`;

export const useSeatStateMultiDayQuery = (
  seatIds: string[],
  startTime: string | null,
  dates: string[],
  timezone: string,
  durationInMinutes: number
): QueryResult<
  GroupingMapSeatStateMultiDayQuery,
  GroupingMapSeatStateMultiDayQueryVariables
> => {
  const { tenantId } = useContext(MapInfoContext);
  const result = useQuery<
    GroupingMapSeatStateMultiDayQuery,
    GroupingMapSeatStateMultiDayQueryVariables
  >(SEAT_STATE_QUERY_MULTI_DAY, {
    skip:
      !tenantId || dates.length < 1 || seatIds.length < 1 || startTime === null,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      deskIds: seatIds,
      startTime,
      dates,
      timezone,
      durationInMinutes,
    },
  });

  return result;
};
