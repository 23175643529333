import { FC } from 'react';

export const ClockSolid: FC = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.00004 1.33337C4.31814 1.33337 1.33337 4.31814 1.33337 8.00004C1.33337 11.6819 4.31814 14.6667 8.00004 14.6667C11.6819 14.6667 14.6667 11.6819 14.6667 8.00004C14.6667 4.31814 11.6819 1.33337 8.00004 1.33337ZM8.83338 4.66671C8.83338 4.20647 8.46028 3.83337 8.00004 3.83337C7.53981 3.83337 7.16671 4.20647 7.16671 4.66671L7.16671 8.00004C7.16671 8.26234 7.2902 8.50933 7.50004 8.66671L9.91671 10.5834C10.2849 10.8595 10.8072 10.7849 11.0834 10.4167C11.3595 10.0485 11.2849 9.52618 10.9167 9.25004L8.83337 7.58337L8.83338 4.66671Z"
        fill="#706E6E"
      />
    </svg>
  );
};
