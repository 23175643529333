import { FC, useRef, useState } from 'react';
import { useClickAway, useKey } from 'react-use';
import { MenuSelect, MenuItem } from '../../components';
import { useHeaderContext } from '../../contexts';
import { AvatarButton } from './AvatarButton';
import { useTranslation } from 'react-i18next';

const useMenuItems = (): MenuItem[] => {
  const { t } = useTranslation('userProfileMenu');
  const { activeOrg, currentUser } = useHeaderContext();
  const orgSlug = activeOrg?.slug ?? '';

  return [
    {
      label: t('profile'),
      href: `/${orgSlug}/people/${currentUser?.id}`,
    },
    {
      label: t('userSettings'),
      href: '/account',
    },
    {
      label: t('help'),
      items: [
        {
          label: t('helpMenu.helpCenter'),
          href: 'https://support.robinpowered.com',
        },
        {
          label: t('helpMenu.contactUs'),
          href: `/${orgSlug}/premier-support`,
        },
        {
          label: t('helpMenu.submitRequest'),
          divider: true,
          href: 'mailto:support@robinpowered.com',
        },
        {
          label: t('helpMenu.productUpdates'),
          href: 'https://updates.robinpowered.com',
        },
        {
          label: t('helpMenu.status'),
          href: 'https://status.robinpowered.com',
        },
      ],
    },
    {
      label: t('getApps'),
      divider: true,
      href: 'https://robinpowered.com/downloads',
    },
    {
      label: t('signOut'),
      href: '/logout',
    },
  ];
};

export const UserProfileMenu: FC = () => {
  const { isLoading, currentUser } = useHeaderContext();
  const [menuOpen, setMenuOpen] = useState(false);
  const menuItems = useMenuItems();

  const menuRef = useRef(null);
  useClickAway(menuRef, () => {
    setMenuOpen(false);
  });

  useKey('Escape', () => setMenuOpen(false));

  if (isLoading) {
    return null;
  }

  return (
    <div style={{ position: 'relative' }} ref={menuRef}>
      <AvatarButton
        isOpen={menuOpen}
        avatar={currentUser?.avatar}
        userName={currentUser?.name}
        onClick={() => setMenuOpen((open) => !open)}
      />
      <MenuSelect
        isOpen={menuOpen}
        items={menuItems}
        onItemClick={() => setMenuOpen(false)}
      />
    </div>
  );
};
