export function getGeometricCenter(
  msld: mapboxgl.MapboxGeoJSONFeature | undefined
) {
  if (!msld) {
    return null;
  }
  const coordinates = (
    msld.geometry as unknown as { coordinates: [number, number][][] }
  )?.coordinates[0];
  if (!coordinates || coordinates.length < 1) {
    return null;
  }
  const length = coordinates.length;
  const [x, y] = coordinates.reduce((pv, cv) => {
    return [pv[0] + cv[0], pv[1] + cv[1]];
  });
  const longitude = x / length;
  const latitude = y / length;
  return { longitude, latitude };
}
