import { Heading } from '@robinpowered/design-system';
import { useActiveAnnouncements, useSelectedHierarchy } from 'hooks';
import moment from 'moment';
import { AnnouncementListCard } from './shared/Announcements';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

export const AnnouncementsList = () => {
  const { t } = useTranslation('announcements');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const setSearchParams = useSearchParams()[1];

  const { selectedLocationId } = useSelectedHierarchy();
  const { activeAnnouncements } = useActiveAnnouncements(selectedLocationId);

  return (
    <>
      {activeAnnouncements?.length === 0 && (
        <Heading.Medium>{t('no_active')}</Heading.Medium>
      )}

      {activeAnnouncements &&
        [...activeAnnouncements]
          .sort((a, b) => {
            return (
              moment(b?.createdAt).valueOf() - moment(a?.createdAt).valueOf()
            );
          })
          .map((a) => (
            <AnnouncementListCard
              key={a.id}
              announcement={a}
              unreadBadgeText={t('unread_badge')}
              onClick={() => {
                setSearchParams((prev) => {
                  prev.set('announcements', a.id);
                  return prev;
                });
              }}
            />
          ))}
    </>
  );
};
