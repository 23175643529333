import { useSeatStateMultiDayQuery } from '../hooks';
import { FC, useEffect } from 'react';
import { useMap } from 'react-map-gl';
import { mapSeatStateToMapType, mapSeatTypeToMapType } from '../dataMaps';
import { SeatDataType } from '../types';

type GroupingSeatStateComponentProgressiveLoadProperties = {
  id: string;
  seatData: SeatDataType;
  startTime: string | null;
  dates: string[];
  timezone: string;
  durationInMinutes: number;
};

/**
 * Map component that queries GraphQL for a group of seats state, and updates the map with the results.
 * @param id Identity of map
 * @param seatData Map of robin seat IDs to map feature element IDs assoicated with that seat
 * @param startTime Loal timecode start time
 * @param dates Local dates
 * @param timezone IANA timezone
 * @param durationInMinutes duration of reservation check in minutes
 * @returns React component
 */
export const GroupingSeatStateMultiDayComponentProgressiveLoad: FC<
  GroupingSeatStateComponentProgressiveLoadProperties
> = ({ seatData, id, startTime, dates, timezone, durationInMinutes }) => {
  const map = useMap()[id];
  const seatIds = Object.keys(seatData);
  const seatState = useSeatStateMultiDayQuery(
    seatIds,
    startTime,
    dates,
    timezone,
    durationInMinutes
  );

  useEffect(() => {
    if (
      !map ||
      !map.isStyleLoaded() ||
      seatState.loading ||
      seatState.error ||
      !seatState.data
    ) {
      return;
    }

    seatState.data.reservationsMultiDayGroupedByDate.forEach((sd) => {
      const newState = Object.assign(
        {},
        mapSeatTypeToMapType(sd.type),
        mapSeatStateToMapType(sd.availability)
      );

      const seatFeatureIds: Array<string | number> = seatData[sd.deskId];

      seatFeatureIds.map((sfid) =>
        map.setFeatureState({ source: 'seats', id: sfid }, newState)
      );
    });
  }, [map, seatState, seatIds, seatData]);

  return null;
};
