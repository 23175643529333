import { gql, useQuery } from '@apollo/client';
import {
  UseMySeatReservationQueryVariables,
  UseMySeatReservationQuery,
} from 'generated';
import { useContext } from 'react';
import { MapInfoContext } from '../state';

const GET_MY_SEAT_RESERVATION_ON_LEVEL = gql`
  query UseMySeatReservation(
    $userId: ID!
    $startTime: Date!
    $endTime: Date!
    $includeUserData: Boolean!
  ) {
    getDeskReservationsByUserId(
      id: $userId
      startTime: $startTime
      endTime: $endTime
    ) {
      userId
      reservations {
        accountReservee @include(if: $includeUserData) {
          visitorId
          user {
            id
            avatar
            name
          }
        }
        id
        confirmation {
          status
        }
        seat {
          id
        }
      }
    }
  }
`;

export const useMySeatReservation = (
  userId: string | null | undefined,
  startTime: string | null,
  endTime: string | null,
  includeUserData: boolean
) => {
  const { tenantId } = useContext(MapInfoContext);
  return useQuery<
    UseMySeatReservationQuery,
    UseMySeatReservationQueryVariables
  >(GET_MY_SEAT_RESERVATION_ON_LEVEL, {
    variables: {
      userId: userId ?? '',
      startTime,
      endTime,
      includeUserData: includeUserData,
    },
    skip: !tenantId || !userId || !startTime || !endTime,
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-first',
  });
};
