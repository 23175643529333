import styled from '@emotion/styled';
import { Colors } from '@robinpowered/design-system';
import { FC } from 'react';
import { naturalSort } from 'utilities';

export const SpaceDetailsAmenities: FC<{
  amenities: { name: string }[] | undefined;
}> = ({ amenities }) => {
  return (
    <Container>
      {amenities &&
        amenities
          .map((a) => a.name)
          .sort(naturalSort)
          .map((a) => <AmenityBadge>{a}</AmenityBadge>)}
    </Container>
  );
};

const Container = styled.div`
  padding-top: 8px;
`;

const AmenityBadge = styled.span`
  display: inline-block;
  border-radius: 4px;
  background-color: ${Colors.Tan10};
  padding: 2px 4px;
  font-size: 12px;
  margin: 2px;
  color: ${Colors.Gray80};
`;
