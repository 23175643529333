import { FC, useContext, useEffect } from 'react';
import { useMap } from 'react-map-gl';
import { useRecoilValue } from 'recoil';
import { MapIdContext, useMapLoadCompleteAtom } from '../state';

/**
 * Map component that hids a source layer from view
 * @param layerId Identity of the source layer to hide ex 'spaces', 'markers' or 'seats'
 * @returns React component
 */
export const HideMapLayerComponent: FC<{
  layerId: string;
}> = ({ layerId }) => {
  const id = useContext(MapIdContext);
  const map = useMap()[id];
  const mapLoadState = useRecoilValue(useMapLoadCompleteAtom(id));

  useEffect(() => {
    if (mapLoadState && map) {
      map.getMap().setLayoutProperty(layerId, 'visibility', 'none');
    }
  }, [mapLoadState, map, layerId]);

  useEffect(() => {
    return () => {
      try {
        map?.getMap().setLayoutProperty(layerId, 'visibility', 'visible');
      } catch (e) {
        // async inner map loss. This is okay on map unmounts. Move along.
      }
    };
  }, []);

  return null;
};
