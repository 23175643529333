import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled/macro';
import { ButtonV4 as Button, Colors } from '@robinpowered/design-system';
import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { useDeskPopupContext } from './deskPopupContext';
import { isSeriesInstance } from './is-series-instance';
import { ReservationLinkButton } from './ReservationLinkButton';
import { BookingPolicyType, DeskReservationVisibility } from 'generated';
import { hasReservationStarted } from './has-reservation-started';
import {
  InactiveReservationButton,
  ReservationButton,
} from './ReservationButton';
import { VisibilityDropdown } from './VisibilityDropdown';

export const ActionBar = () => {
  const { t }: any = useTranslation('map');

  const [loadingDeskSharing, setLoadingDeskSharing] = useState(false);
  const {
    deskState,
    userCanDelegateSeat,
    reservation,
    isCurrentUsersReservation,
    isSeatReservable,
    isAtCapacity,
    isConfirmedNoReservation,
    hasEncapsulatingExclusion,
    canReverseHotel,
    hasReverseHotelAccess,
    loading,
    didRequestToAssign,
    isReservationVisibilityEnabled,
    timezone,
    setRequestToAssign,
    onCreateReservation,
    localVisibility,
    setLocalVisibility,
  } = useDeskPopupContext();

  useEffect(() => {
    setRequestToAssign(false);
    return () => setRequestToAssign(false);
  }, []);

  const isRedirectFFEnabled = true;

  // Checks if the reservation type is assigned
  const isAssignedReservation = useMemo(
    () => (reservation ? reservation.type === 'assigned' : null),
    [reservation]
  );

  // Checks if the reservation type is reverse hotel
  const isReverseHotelReservation = reservation
    ? reservation.type === 'reverse_hotel'
    : null;

  // Determines if the reservation is a instance of a recurring reservation
  const seriesInstance = useMemo(
    () => isSeriesInstance(reservation),
    [reservation]
  );
  // The type of allowed reservations on the seat (ie: hot, hoteled, assigned)
  const seatReservationType = deskState?.data?.getDeskById?.type;
  const isAssignedSeat = seatReservationType === BookingPolicyType.Assigned;

  // Opens the edit hotel modal.
  const openEditHotelModal = () => {
    /*
    setLoading(true);
    EditHotelUtils.openEditHotelModalWithSeat(
      seat,
      reservation.series_id,
      reservation.start.time_zone
    )
      .then(() => onEditHotelModalSaved())
      .catch(ModalActionError, () => {})
      .finally(() => setLoading(false));
      */
  };

  // Opens the reverse hotel modal.
  const openReverseHotelModal = () => {
    /*
    setLoadingDeskSharing(true);
    ReverseHotelUtils.openReverseHotelModalWithSeat(
      seat,
      reservation.series_id || reservation.id,
      reservation.start.time_zone,
      true
    )
      .then(() => onReverseHotelModalSaved())
      .catch(ModalActionError, () => {})
      .finally(() => setLoadingDeskSharing(false));
      */
  };

  // ------------------------- Button visibility logic - Admins -------------------------

  // Buttons for reserving/assigning (admins)
  const showAdminBookingButtons =
    userCanDelegateSeat &&
    isSeatReservable &&
    // Assigned seats do not count toward capacity and shouldn't be affected by capacity
    (!isAtCapacity || (isAtCapacity && isAssignedSeat)) &&
    !didRequestToAssign &&
    (!reservation || isConfirmedNoReservation);

  // Button for reserving a reverse hoteled desk (admins)
  const showAdminReverseHotelBookingButton =
    userCanDelegateSeat &&
    hasEncapsulatingExclusion &&
    isSeatReservable &&
    !isAtCapacity &&
    !didRequestToAssign &&
    !isReverseHotelReservation;

  // ------------------------- Button visibility logic - Users --------------------------

  // Buttons for reserving/assigning (user)
  const showUserBookingButtons =
    isSeatReservable &&
    !isAtCapacity &&
    !userCanDelegateSeat &&
    (!reservation || isConfirmedNoReservation);

  // Button for reserving a reverse hoteled desk (user)
  const showUserReverseHotelBookingButton =
    hasEncapsulatingExclusion &&
    isSeatReservable &&
    !isAtCapacity &&
    !userCanDelegateSeat &&
    !isReverseHotelReservation;

  // Button for opening reverse hotel modal (users)
  const showUserReverseHotelModalButton =
    reservation &&
    reservation.type === 'assigned' &&
    hasReverseHotelAccess &&
    canReverseHotel &&
    isCurrentUsersReservation &&
    !userCanDelegateSeat &&
    !hasEncapsulatingExclusion;

  // -------------------- Button visibility logic - Admins and Users --------------------

  // Button for opening edit hotel modal (admins/users)
  const showEditHotelModalButton =
    (userCanDelegateSeat || isCurrentUsersReservation) &&
    reservation &&
    !isConfirmedNoReservation &&
    seriesInstance &&
    !isAssignedReservation;

  // Inactive buttons for unbookable seats (admins/users)
  const showInactiveButtons =
    !(isSeatReservable && !isAtCapacity) &&
    !isAtCapacity &&
    (!reservation || hasEncapsulatingExclusion) &&
    !isReverseHotelReservation;

  // Button for unassigning (admins)
  const showUnassignAssignedButton =
    userCanDelegateSeat &&
    reservation &&
    !hasEncapsulatingExclusion &&
    isAssignedReservation &&
    !isRedirectFFEnabled;

  const showEditAssignmentsButton =
    userCanDelegateSeat &&
    !hasEncapsulatingExclusion &&
    isAssignedReservation &&
    isRedirectFFEnabled;

  // Buttons for changing a reservation (users)
  const showUpdateButtons =
    reservation &&
    isCurrentUsersReservation &&
    isSeatReservable &&
    ((isReverseHotelReservation &&
      hasEncapsulatingExclusion &&
      !didRequestToAssign) ||
      !isAssignedReservation) &&
    !seriesInstance;

  // Button for unreserving a reverse hoteled desk (admins/users)
  const showUnbookReverseHotelButton =
    (userCanDelegateSeat ||
      (isCurrentUsersReservation && isSeatReservable && !isAtCapacity)) &&
    hasEncapsulatingExclusion &&
    reservation &&
    isReverseHotelReservation &&
    !didRequestToAssign &&
    !seriesInstance;

  // Get text for the end/cancel buttons
  const getEndReservationButtonText = useCallback(() => {
    let defaultTranslation;
    let loadingTranslation;

    if (reservation && reservation?.type === 'assigned') {
      defaultTranslation = 'SEATS.RESERVATIONS.ASSIGNMENT.UNASSIGN';
      loadingTranslation = 'SEATS.RESERVATIONS.ASSIGNMENT.UNASSIGNING';
    } else if (
      reservation &&
      hasReservationStarted(reservation, timezone ?? 'America/New_York')
    ) {
      defaultTranslation = 'SEATS.RESERVATIONS.END_RESERVATION';
      loadingTranslation = 'SEATS.RESERVATIONS.ENDING_RESERVATION';
    } else {
      defaultTranslation = 'SEATS.RESERVATIONS.CANCEL_RESERVATION';
      loadingTranslation = 'SEATS.RESERVATIONS.CANCELING_RESERVATION';
    }

    return t(loading ? loadingTranslation : defaultTranslation);
  }, [reservation, timezone, loading]);

  // Dropdown for setting reservation visibility.
  // Show this if the user is able to book the desk.
  // We also exclude showing this button for assigned seats.
  const showUserVisibilityDropdown =
    showUserBookingButtons && !isAssignedSeat && isReservationVisibilityEnabled;

  // Dropdown for changing existing reservation visibility.
  // Show this if its either the user's reservation, or if this is an admin.
  // We also exclude showing this button for assigned seats.
  const showUpdateExistingVisibilityDropdown =
    (isCurrentUsersReservation || userCanDelegateSeat) &&
    reservation &&
    !isAssignedSeat &&
    isReservationVisibilityEnabled;

  // Button for copying the reservation url (users)
  // Show if reservation visibility not set different (or own reservation)
  // (FF on) Copy url button for admins is in the ActionDropdown
  const showCopyURLButton = !isRedirectFFEnabled
    ? reservation &&
      !isConfirmedNoReservation &&
      (isCurrentUsersReservation ||
        DeskReservationVisibility.Everyone === reservation.visibility ||
        userCanDelegateSeat) &&
      !showAdminReverseHotelBookingButton &&
      !showUserReverseHotelBookingButton
    : reservation && !userCanDelegateSeat;

  const handleEditAssignmentsClick = () => {
    console.log('handle edit assignment');
    /*
    $state.go('main.organization.manage-workplace', {
      levelId: seat.space.level_id,
      preSelectedSeatId: seat.id,
      active: 'assign',
    });
    */
  };

  const onEditReservation = () => {
    //TBD
    console.log('on edit reservation');
  };
  const onCancelReservation = () => {
    //TBD
    console.log('on cancel reservation');
  };

  const onUpdateVisibility = () => {
    //TBD
    console.log('on update visibility');
  };

  return (
    <ReservationButtonWrapper
      isRedirectFFEnabled={isRedirectFFEnabled}
      isSeatBooked={Boolean(reservation)}
    >
      {/* Buttons for unbookable seats (admins/users) */}
      {showInactiveButtons && (
        <FullWidthInactiveButton>
          {isAssignedSeat && !hasEncapsulatingExclusion
            ? t('SEATS.RESERVATIONS.ASSIGNMENT.ASSIGN')
            : t('SEATS.RESERVATIONS.HOTELED.ACTIONS.RESERVE')}
        </FullWidthInactiveButton>
      )}

      {/* Button for opening edit hotel modal (admins/users) */}
      {showEditHotelModalButton && (
        <SecondaryActionButton
          disabled={loading}
          value={''}
          onClick={openEditHotelModal}
        >
          {t('SEATS.RESERVATIONS.CHANGE_DATES')}
        </SecondaryActionButton>
      )}

      {/* Buttons for reserving (admins) */}
      {showAdminBookingButtons && (
        <FullWidthReservationButton
          disabled={loading}
          onClick={() => {
            setRequestToAssign(true);
          }}
        >
          {isAssignedSeat
            ? t('SEATS.RESERVATIONS.ASSIGNMENT.ASSIGN_FOR')
            : seatReservationType === BookingPolicyType.Hot
            ? t('SEATS.RESERVATIONS.HOT.ACTIONS.RESERVE_FOR')
            : seatReservationType === BookingPolicyType.Hoteled
            ? t('SEATS.RESERVATIONS.HOTELED.ACTIONS.RESERVE_FOR')
            : null}
        </FullWidthReservationButton>
      )}

      {/* Button for reserving a reverse hoteled desk (admins) */}
      {showAdminReverseHotelBookingButton && (
        <FullWidthReservationButton
          onClick={() => {
            setRequestToAssign(true);
          }}
        >
          {t('SEATS.RESERVATIONS.HOTELED.ACTIONS.RESERVE_FOR')}
        </FullWidthReservationButton>
      )}

      {/* Buttons for reserving/assigning (user) */}
      {showUserBookingButtons && (
        <FullWidthReservationButton
          disabled={loading}
          onClick={onCreateReservation}
        >
          {isAssignedSeat
            ? t(
                loading
                  ? 'SEATS.RESERVATIONS.ASSIGNMENT.ASSIGNING'
                  : 'SEATS.RESERVATIONS.ASSIGNMENT.ASSIGN'
              )
            : seatReservationType === BookingPolicyType.Hot
            ? t(
                loading
                  ? 'SEATS.RESERVATIONS.HOT.RESERVING'
                  : 'SEATS.RESERVATIONS.HOT.ACTIONS.RESERVE'
              )
            : seatReservationType === BookingPolicyType.Hoteled
            ? t(
                loading
                  ? 'SEATS.RESERVATIONS.HOTELED.RESERVING'
                  : 'SEATS.RESERVATIONS.HOTELED.ACTIONS.RESERVE'
              )
            : null}
        </FullWidthReservationButton>
      )}

      {/* Button for reserving a reverse hoteled desk (user) */}
      {showUserReverseHotelBookingButton && (
        <FullWidthReservationButton
          disabled={loading}
          onClick={onCreateReservation}
        >
          {t(
            loading
              ? 'SEATS.RESERVATIONS.HOT.RESERVING'
              : 'SEATS.RESERVATIONS.HOT.ACTIONS.RESERVE'
          )}
        </FullWidthReservationButton>
      )}

      {/* Button for opening reverse hotel modal (users) */}
      {showUserReverseHotelModalButton && (
        <SecondaryActionButton
          disabled={loadingDeskSharing}
          onClick={openReverseHotelModal}
        >
          {t('REVERSE_HOTEL.POPOVER.SHARE_DESK')}
        </SecondaryActionButton>
      )}

      {/* Buttons for changing a reservation (users) */}
      {showUpdateButtons && (
        <EditReservationButton
          disabled={loading}
          variant="secondary"
          label={t('SEAT_RESERVATION_UPDATE.START_RESERVATION_EDIT_BUTTON')}
          onClick={onEditReservation}
        />
      )}

      {/* Button for unassigning (admins) */}
      {showUnassignAssignedButton && (
        <EndReservationButton
          disabled={loading}
          variant="secondary"
          label={getEndReservationButtonText()}
          onClick={onCancelReservation}
        >
          {getEndReservationButtonText()}
        </EndReservationButton>
      )}

      {/* Button for redirecting admins to Assignments Tool */}
      {showEditAssignmentsButton && (
        <EditAssignmentsButton
          isLoading={loading}
          variant="secondary"
          label={t('SEATS.RESERVATIONS.ASSIGNMENT.EDIT_ASSIGNMENTS')}
          onClick={handleEditAssignmentsClick}
        />
      )}

      {/* Button for unreserving a reverse hoteled desk (admins/users) */}
      {showUnbookReverseHotelButton && (
        <EndReservationButton
          data-cy="endReservationBtn"
          disabled={loading}
          variant="secondary"
          label={getEndReservationButtonText()}
          onClick={onCancelReservation}
        />
      )}

      {/* Button for copying reservation url (admins/users) */}
      {showCopyURLButton && reservation && (
        <ReservationLinkButton reservationId={reservation.id} />
      )}

      {/* Dropdown for setting a new reservation's visibility (users) */}
      {showUserVisibilityDropdown && (
        <DropdownWrapper>
          <VisibilityDropdown
            selectedVisibility={localVisibility}
            onUpdateVisibility={(vis) => {
              setLocalVisibility && setLocalVisibility(vis);
            }}
          />
        </DropdownWrapper>
      )}

      {/* Dropdown for changing existing reservation's visibility (users) */}
      {showUpdateExistingVisibilityDropdown && (
        <DropdownWrapper>
          <VisibilityDropdown
            selectedVisibility={localVisibility}
            onUpdateVisibility={onUpdateVisibility}
            disabled={loading}
          />
        </DropdownWrapper>
      )}
    </ReservationButtonWrapper>
  );
};

const EditAssignmentsButton = styled(Button)`
  width: 100% !important;
  height: 32px;
`;

const DropdownWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const SecondaryActionButton = styled(ReservationButton)`
  background-color: ${Colors.White0};
  border: 1px solid ${Colors.Tan70};
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05);
  color: ${Colors.Gray100};
  padding: 0 12px;
  transition: all 0.3s ease-in-out;
  height: unset; // workaround for text overflow not growing the button boundary
  min-height: 32px;

  &:hover:not([disabled]) {
    background-color: ${Colors.Tan5};
  }
`;

const EditReservationButton = styled(Button)`
  height: 32px;
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const EndReservationButton: any = styled(Button)`
  color: ${Colors.Red110};
  height: 32px;
`;

const FullWidthReservationButton = styled(ReservationButton)`
  width: 100% !important;
`;

const FullWidthInactiveButton = styled(InactiveReservationButton)`
  width: 100% !important;
  margin-top: 0;
`;

const ReservationButtonWrapper = styled.div<{
  isRedirectFFEnabled: boolean;
  isSeatBooked: boolean;
}>`
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;

  ${({ isRedirectFFEnabled, isSeatBooked }) =>
    isRedirectFFEnabled
      ? css`
          padding: 8px 12px;
        `
      : isSeatBooked
      ? css`
          padding: 0 12px 12px 12px;
        `
      : 0}

  &:empty {
    padding: 0;
  }

  > button {
    width: calc(50% - 4px);
  }

  > ${DropdownWrapper} {
    &:not(:last-of-type) {
      margin-right: 12px;
    }
  }
`;
