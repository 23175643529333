import styled from '@emotion/styled';
import { Body, Colors, UtilityButton } from '@robinpowered/design-system';
import Close from '@robinpowered/icons/Close';
import { FC } from 'react';

export const PeopleSearchHeader: FC<{ title: string; onClose: () => void }> = ({
  title,
  onClose,
}) => {
  return (
    <Container>
      <Body.Regular>{title}</Body.Regular>
      <UtilityButton icon={Close} onClick={onClose} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${Colors.Gray20};
  padding: 16px;
`;
