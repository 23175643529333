import { useTranslation } from 'react-i18next';
import { useDeskPopupContext } from './deskPopupContext';
import { useMemo } from 'react';
import { BookingPolicyType } from 'generated';
import moment from 'moment-timezone';

export type BookingPolicyViolationsType = {
  permissions: string[];
  policies: string[];
};

// Get messages if the desk reservation violates any policies.
export const usePolicyViolationMessages = (): BookingPolicyViolationsType => {
  const { t } = useTranslation('map');
  const {
    deskState,
    hasEncapsulatingExclusion,
    isCurrentUsersReservation,
    startTime,
    endTime,
    timezone,
  } = useDeskPopupContext();

  return useMemo(() => {
    const violations = {
      permissions: [],
      policies: [],
    };

    if (!deskState?.data?.getDeskById) {
      return violations;
    }

    const seatType = deskState.data.getDeskById?.type;
    const now = timezone ? moment.tz(timezone) : moment();

    const freeFloorCapacity =
      deskState?.data?.getDeskById.level?.state.freeCapacity;

    const freeBuildingCapacity =
      deskState?.data?.getDeskById.location.state?.freeCapacity;

    // When viewing "all floors" across multiple floors, floor capacity might be null/undefined
    const freeCapacity =
      freeFloorCapacity || freeFloorCapacity === 0
        ? freeFloorCapacity
        : freeBuildingCapacity;

    const isAssignedSeat = seatType === BookingPolicyType.Assigned;

    const canAssign = deskState?.data?.getDeskById?.permissions.some(
      (p) => p.name === 'seats:assign' && p.value
    );

    const canBypass = deskState?.data?.getDeskById?.permissions.some(
      (p) => p.name === 'seats:bypass_booking_policies' && p.value
    );

    const canDelegate = deskState?.data?.getDeskById?.permissions.some(
      (p) => p.name === 'seats:delegate' && p.value
    );

    const canReserve = deskState?.data?.getDeskById?.permissions.some(
      (p) => p.name === 'seats:reserve' && p.value
    );

    const shouldEnforcePolicies =
      seatType === BookingPolicyType.Hoteled ||
      (seatType === BookingPolicyType.Assigned && hasEncapsulatingExclusion);

    // ------------------------------ Permission violations -------------------------------
    // Violations are sorted in a prioritized order.

    // No one can use it (can’t be assigned or reserved)
    if (!deskState.data.getDeskById?.isReservable) {
      violations.permissions.push(
        t('SEAT_RESERVATION_POLICIES.UNAVAILABLE_SEAT')
      );
    }

    // Only Admin can reserve assigned seats
    if (
      (deskState.data.getDeskById?.state.reservations.length ?? 0) > 0 &&
      !hasEncapsulatingExclusion &&
      !canAssign &&
      !canDelegate
    ) {
      violations.permissions.push(
        t('SEAT_RESERVATION_POLICIES.ASSIGNED_SEAT_ADMIN_ONLY')
      );
    }

    // Don't have permission to book or assign
    if (
      !isCurrentUsersReservation &&
      ((isAssignedSeat && !canAssign && !hasEncapsulatingExclusion) ||
        (!isAssignedSeat && !canReserve))
    ) {
      violations.permissions.push(
        t('SEAT_RESERVATION_POLICIES.MISSING_PERMISSIONS')
      );
    }

    // Desks cannot be booked once the office is fully at capacity, unless the user is moving a reservation
    if (freeCapacity === 0 && !isAssignedSeat) {
      violations.permissions.push(t('SEAT_RESERVATION_POLICIES.AT_CAPACITY'));
    }

    // --------------------------------- Policy violations --------------------------------
    // Multiple violation in this section can be applied.
    // Violations are sorted in a prioritized order.

    // Hot desks cannot be reserved in advance
    if (
      seatType === BookingPolicyType.Hot &&
      (!canReserve ||
        (endTime &&
          timezone &&
          moment(endTime).isAfter(now.clone().add(1, 'day').startOf('day'))))
    ) {
      violations.policies.push(
        t('SEAT_RESERVATION_POLICIES.HOT_DESK_IN_ADVANCE')
      );
    }

    const maxDuration =
      deskState.data.getDeskById.reservationPolicies?.seatReservationMaxLength;

    try {
      // Max Reservation Length
      if (
        !canBypass &&
        shouldEnforcePolicies &&
        maxDuration &&
        startTime &&
        endTime &&
        moment.duration(moment(endTime).diff(moment(startTime))).as('minutes') <
          parseInt(maxDuration)
      ) {
        violations.policies.push(
          t('SEAT_RESERVATION_POLICIES.MAX_RESERVATION_LENGTH', {
            timeDuration: maxDuration,
          })
        );
      }
    } catch (e) {
      console.warn(e);
    }

    const advanceBookingThreshold =
      deskState.data.getDeskById.reservationPolicies
        ?.seatReservationAdvancedBookingThreshold;

    try {
      // Advance Booking Threshold
      if (
        startTime &&
        advanceBookingThreshold &&
        !canBypass &&
        shouldEnforcePolicies &&
        moment.duration(moment().diff(moment(startTime))).as('minutes') <
          parseInt(advanceBookingThreshold)
      ) {
        const maxBookingThreshold = parseInt(advanceBookingThreshold);
        violations.policies.push(
          t('SEAT_RESERVATION_POLICIES.ADVANCE_BOOKING_THRESHOLD', {
            timeDuration: maxBookingThreshold,
          })
        );
      }
    } catch (e) {
      console.warn(e);
    }

    return violations;
  }, [
    deskState,
    t,
    hasEncapsulatingExclusion,
    isCurrentUsersReservation,
    endTime,
    timezone,
  ]);
};
