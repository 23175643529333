import styled from '@emotion/styled';
import {
  Avatar,
  Body,
  Colors,
  ContextualHelp,
} from '@robinpowered/design-system';
import OpenEye from '@robinpowered/icons/VisibilityOn';
import { MarkdownRenderer } from '../MarkdownRenderer';
import { FC } from 'react';
import moment from 'moment';
import { AnnouncementPriorityStatus } from 'generated';

type AnnouncementProp = {
  id: string;
  name: string;
  priorityStatus: AnnouncementPriorityStatus;
  content: string;
  createdAt: string;
  viewedAt?: string | null | undefined;
  author: {
    id: string | null | undefined;
    name?: string | null | undefined;
    avatar?: string | null | undefined;
  };
};

export const AnnouncementFullCard: FC<{
  announcement: AnnouncementProp;
  postedByText: string | null | undefined;
  viewedAtText: string | undefined;
  summarizedDate: string;
  children: React.ReactNode;
}> = ({
  announcement,
  postedByText,
  viewedAtText,
  summarizedDate,
  children,
}) => {
  const { author, name, content, createdAt, viewedAt } = announcement;

  return (
    <>
      <Container>
        <Header>
          <Avatar
            name={author?.name || ''}
            src={author?.avatar || ''}
            size={32}
          />
          <Info>
            <AuthorName>{postedByText}</AuthorName>
            <ContextualHelp
              text={moment(createdAt).format('LLLL')}
              placement="bottom"
            >
              <PostedTimestamp>{summarizedDate}</PostedTimestamp>
            </ContextualHelp>
          </Info>
        </Header>

        <Title>{name}</Title>
        <Content>
          <MarkdownRenderer markdownString={content || ''} />
        </Content>

        {children}
      </Container>

      {viewedAt && viewedAtText && (
        <ViewedAt>
          <OpenEye size={20} />
          <Body.Small>{viewedAtText}</Body.Small>
        </ViewedAt>
      )}
    </>
  );
};

const Container = styled.div`
  color: ${Colors.Gray80};
  border: 1px solid ${Colors.Tan30};
  border-radius: 16px;
  box-shadow: #00000014 0px 1px 3px;
  padding: 16px;
  background-color: ${Colors.White0};
  margin-bottom: 16px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 8px;
`;

const Info = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 8px;
`;

const AuthorName = styled(Body.Small)`
  line-height: 1.2;
  font-weight: 500;
`;

const PostedTimestamp = styled(Body.XSmall)`
  line-height: 1.2;
`;

const Title = styled(Body.Regular)`
  display: block;
  font-size: 18px;
  font-weight: 500;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 8px;
`;

const ViewedAt = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: ${Colors.Gray80};

  > span {
    padding-left: 8px;
  }
`;
